import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#EC7BA1",
      light: "#FF8BBA",
      dark: "#B24D76",
    },
    secondary: {
      main: "#1AA1B9",
      light: "#47B3C7",
      darck: "#127081",
      contrastText: "#fff",
    },
    action: {
      active: "#1AA1B9",
      selected: "#1AA1B9",
    },
    background: {
      default: "#f7f7f7",
    },
    black: {
      one: "#00000080",
    },
    blue: {
      one: "#D2ECF1",
    },
    gray: {
      default: "#AFA8A8",
      one: "#DEDEDE",
      two: "#DBDBDB",
    },
    orange: {
      default: "#FFC165",
    },
    pink: {
      default: "#FF6FA9",
    },
    red: {
      default: "#F53D61",
    },
    purple: {
      one: "#6F34D5",
    },
  },
  components: {
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          color: "#1AA1B9",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: "#FADFE9",
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#4D4D4D",
          color: "white",
          width: 240,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: "#1AA1B9",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "#EC7BA1",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInput-icon": {
            color: "#ffffff",
          },
          width: "100%",
          "& fieldset": { borderColor: "#EC7BA1" },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          height: "28px",
          padding: 0,
          fontSize: "16px",
          marginLeft: "8px",
          marginTop: "3px",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          borderRadius: 4,
          position: "relative",
          border: "0px solid",
          borderColor: "#EC7BA1",
          fontSize: 16,
          height: 15,
          padding: "10px 12px",
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            wordWrap: "break-word",
            overflowWrap: "break-word",
            textAlign: "center",
          },
          labelWrapped: {
            fontSize: "0.875rem",
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            color: "red",
          },
        },
      },
    },
  },
});

export default theme;
