import React from "react";
import { Grid } from "@mui/material";
import StatisticsByResults from "./StatisticsByResults";
import StatisticsByAge from "./StatisticsByAge";
import StatisticsByLevelAndAge from "./StatisticsByLevelAndAge";
import MapToBarsChart from "./MapToBarsChart";

const BottomInfoDashboard = () => {
  return (
    <Grid container>
      <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
        <StatisticsByResults />
      </Grid>
      <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
        <StatisticsByAge />
      </Grid>
      <Grid item xl={4} lg={6} md={12} sm={12} xs={12}>
        <StatisticsByLevelAndAge />
      </Grid>

      <Grid item xl={12} lg={6} md={12} sm={12} xs={12}>
        <MapToBarsChart />
      </Grid>
    </Grid>
  );
};

export default BottomInfoDashboard;
