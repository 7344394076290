import React from "react";
import { NavLink } from "react-router-dom";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

const NavbarSectionSimple = ({ item, key }) => {
  return (
    <ListItem disablePadding key={key}>
      <ListItemButton component={NavLink} to={item.link}>
        <ListItemIcon sx={{ color: "white" }}>{item.icon}</ListItemIcon>
        <ListItemText primary={item.menu} />
      </ListItemButton>
    </ListItem>
  );
};

export default NavbarSectionSimple;
