import Navbar from "../components/navbar/ResponsiveNavbar";
import NavbarData from "../components/navbar/NavbarData";

const headerRoutes = [
  { path: "/", element: null },
  { path: "/dashboard", element: <Navbar navArrayLinks={NavbarData} /> },
  { path: "/sheet", element: <Navbar navArrayLinks={NavbarData} /> },
  { path: "/reception", element: <Navbar navArrayLinks={NavbarData} /> },
  { path: "/results", element: <Navbar navArrayLinks={NavbarData} /> },
  { path: "/diagnoses", element: <Navbar navArrayLinks={NavbarData} /> },
  { path: "/users", element: <Navbar navArrayLinks={NavbarData} /> },
  { path: "/permissions", element: <Navbar navArrayLinks={NavbarData} /> },
  { path: "/compare", element: null },
  { path: "/error401", element: <Navbar navArrayLinks={NavbarData} /> },
];

export default headerRoutes;
