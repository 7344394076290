import React from "react";
import { List } from "@mui/material";
import NavbarSection from "./NavbarSection";
import NavbarSectionSimple from "./NavbarSectionSimple";

const NavListDrawer = ({ navArrayLinks }) => {
  return (
    <nav>
      <List>
        {navArrayLinks.map((item, key) => (
          <React.Fragment key={key}>
            {item.submenu === true ? (
              <NavbarSection item={item} key={key} />
            ) : (
              <NavbarSectionSimple item={item} key={key} />
            )}
          </React.Fragment>
        ))}
      </List>
    </nav>
  );
};

export default NavListDrawer;
