import React from "react";
import Logo from "../../assets/imagesDefault";

const RowReferredPattient = ({ logo, fundationtName, patientAmount }) => {
  return (
    <div id="div-style-39">
      <div id="div-style-45">
        <img height="15px" src={Logo.LogoDefault} alt="logo" />
        <div id="div-style-40">{fundationtName}</div>
      </div>
      <div id="div-style-41">{patientAmount}</div>
    </div>
  );
};

export default RowReferredPattient;
