import { Container } from "@mui/material";
import CommonResultsEvents from "./common/CommonResultsEvents";
const ResultsPage = () => {
  return (
    <div style={{ paddingTop: "4rem" }}>
      <Container maxWidth="xxl">
        <CommonResultsEvents />
      </Container>
    </div>
  );
};

export default ResultsPage;
