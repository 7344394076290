import React from "react";
import { Grid } from "@mui/material";
import AnnualComparison from "./AnnualComparison";
import DeliveryOfResults from "./DeliveryOfResults";
import ReferredPatients from "./ReferredPatients";

const MiddleInfoDashboard = () => {
  return (
    <Grid container id="grid-style-12">
      <Grid xl={8} lg={12} md={12} sm={12} xs={12}>
        <AnnualComparison />
      </Grid>

      <Grid xl={4} lg={12} md={12} sm={12} xs={12}>
        <Grid container style={{ width: "100%", height: "100%" }}>
          <Grid xl={12} lg={6} md={12} sm={12} xs={12}>
            <DeliveryOfResults />
          </Grid>
          <Grid xl={12} lg={6} md={12} sm={12} xs={12}>
            <ReferredPatients />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MiddleInfoDashboard;
