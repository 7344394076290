const dummyStatisticsOfResult = [
  {
    id: 1,
    title: "Grado 1",
    percent: "15%",
  },
  {
    id: 2,
    title: "Grado 2",
    percent: "33%",
  },
  {
    id: 3,
    title: "Grado 3",
    percent: "20%",
  },
  {
    id: 4,
    title: "Grado 4",
    percent: "12%",
  },
  {
    id: 5,
    title: "Grado 5",
    percent: "20%",
  },
];

export default dummyStatisticsOfResult;
