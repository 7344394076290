import { getHeaderSimple } from "./utils";

export const getDiagnoses = async () => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}diagnose/`, {
    method: "GET",
    headers: getHeaderSimple(),
  });
  const json = await response.json();

  if (json.message === "Ok") {
    return {
      status: "Ok",
      message: "Ok",
      content: json.data,
    };
  } else {
    return {
      status: "Error",
      message: json.content ?? "Error de conexión",
    };
  }
};

export const createDiagnose = async (resultItem, findings, diagnosticImpression) => {
  const body = {
    resultId: resultItem._id,
    findings: findings,
    diagnosticImpression: diagnosticImpression,
  };

  const response = await fetch(`${process.env.REACT_APP_API_URL}diagnose`, {
    method: "POST",
    headers: getHeaderSimple(),
    body: JSON.stringify(body),
  });
  const json = await response.json();

  if (json.message === "Ok") {
    return {
      status: "Ok",
      message: "Ok",
      content: json.data,
    };
  } else {
    return {
      status: "Error",
      message: json.content ?? "Error de conexión",
    };
  }
};

export const updateDiagnose = async (findings, diagnosticImpression, diagnoseDataId) => {
  const body = {
    findings: findings,
    diagnosticImpression: diagnosticImpression,
  };

  const response = await fetch(`${process.env.REACT_APP_API_URL}diagnose/${diagnoseDataId}`, {
    method: "PUT",
    headers: getHeaderSimple(),
    body: JSON.stringify(body),
  });
  const json = await response.json();

  if (json.message === "Ok") {
    return {
      status: "Ok",
      message: "Ok",
      content: json.data,
    };
  } else {
    return {
      status: "Error",
      message: json.content ?? "Error de conexión",
    };
  }
};
