import { Container } from "@mui/material";
import CommonReceptionEvents from "./common/CommonReceptionEvents";
const ReceptionPage = () => {
  return (
    <div style={{ paddingTop: "4rem" }}>
      <Container maxWidth="xxl">
        <CommonReceptionEvents />
      </Container>
    </div>
  );
};

export default ReceptionPage;
