import React, { useState, useEffect } from "react";
import { Alert, Slide, Snackbar } from "@mui/material";

const SnackBarMessage = ({ severity, message, open, onClose }) => {
  const [snackOpen, setSnackOpen] = useState(false);

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      onClose();
      return;
    }

    setSnackOpen(false);
    onClose();
  };

  function TransitionLeft(props) {
    return <Slide {...props} direction="left" />;
  }

  useEffect(() => {
    setSnackOpen(open);
  }, [open]);

  return (
    <Snackbar
      open={snackOpen}
      autoHideDuration={3000}
      onClose={handleSnackClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      TransitionComponent={TransitionLeft}
    >
      <Alert onClose={handleSnackClose} severity={severity} id="alert-style-1">
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackBarMessage;
