import React, { useEffect, useState } from "react";
import { Box, Card, CircularProgress, Grid, Typography } from "@mui/material";
import StudyImagesCompare from "../components/StudyImagesCompare";
import store from "../../store";
import { Provider } from "react-redux";

const CommonCompareStudiesEvents = () => {
  const [resultItem, setResultItem] = useState(null);
  const [updateKey, setUpdateKey] = useState(0);
  const [allResults, setAllResults] = useState([]);
  const [loading, setLoading] = useState(true);

  const getResultId = async () => {
    var newResultList = [];
    const resultItemFromLocalStorage = JSON.parse(
      localStorage.getItem("resultItem")
    );

    setResultItem(resultItemFromLocalStorage);

    newResultList.push({
      createdAt: resultItemFromLocalStorage.receptionData.studyDate,
      images: resultItemFromLocalStorage.images,
      studyType: resultItemFromLocalStorage.receptionData.studyType,
    });

    for (
      var q = 0;
      q < resultItemFromLocalStorage.previousResults.length;
      q++
    ) {
      var itemQ = resultItemFromLocalStorage.previousResults[q];

      newResultList.push({
        createdAt: itemQ.createdAt,
        images: itemQ.images,
        studyType: itemQ.studyType,
      });
    }

    setAllResults(newResultList);
    setLoading(false);
    setUpdateKey(updateKey + 1);
  };

  useEffect(() => {
    getResultId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <Provider store={store}>
          <Card id="card-style-5" style={{ margin: "12px" }}>
            <Box id="box-style-2">
              <Grid
                container
                spacing={1}
                style={{
                  marginTop: "16px",
                  paddingLeft: "18px",
                  paddingRight: "12px",
                }}
              >
                <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    style={{
                      color: "#EC7BA1",
                      fontSize: "20px",
                      fontWeight: "600",
                      marginLeft: "38px",
                    }}
                  >
                    {`${resultItem?.patientData?.name} ${resultItem?.patientData?.lastname}`}
                  </Typography>
                </Grid>

                <Grid item md={6}>
                  <StudyImagesCompare results={allResults} />
                </Grid>

                <Grid item md={6}>
                  <StudyImagesCompare results={allResults} />
                </Grid>
              </Grid>
            </Box>
          </Card>
        </Provider>
      )}
    </>
  );
};

export default CommonCompareStudiesEvents;
