import { useRef, useContext, useEffect } from "react";
import CanvasDraw from "react-canvas-draw";
import WomanCanvas from "../../assets/imagesDefault";
import Fab from "@mui/material/Fab";
import UndoIcon from "@mui/icons-material/Undo";
import { DataContext } from "../../contexts/DataContext";

const Canvas = (showOrEdit) => {
  let contextData = useContext(DataContext);
  const canvasRef = useRef(null);
  const pattern =
    contextData.clinicalSheet.studyBreastHistory.anomalyPattern.ImageMap;

  const cleanCanvas = () => {
    canvasRef.current.undo();
  };

  useEffect(() => {
    if (pattern !== "") {
      canvasRef.current.loadSaveData(pattern);
    }
  }, [pattern]);

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <div
        style={{
          borderRadius: 4,
          border: "2px solid pink",
        }}
      >
        <img
          width={"100%"}
          src={WomanCanvas.BodyCanvas}
          alt="Imagen de torzo"
        />
      </div>
      <div
        style={{ position: "absolute", left: 0, top: 0, right: 0, bottom: 0 }}
      >
        <CanvasDraw
          ref={canvasRef}
          lazyRadius={0}
          hideInterface={true}
          brushRadius={5}
          brushColor="red"
          hideGrid={true}
          disabled={showOrEdit.showOrEdit}
          style={{
            backgroundColor: "transparent",
            width: "100%",
            height: "100%",
          }}
          onChange={() => {
            if (pattern === "") {
              contextData.clinicalSheet.studyBreastHistory.anomalyPattern.ImageMap =
                canvasRef.current.getSaveData();
            }
          }}
        />
      </div>
      <div
        style={{
          position: "absolute",
          top: "5px",
          right: "5px",
        }}
      >
        <Fab
          color="primary"
          size="small"
          aria-label="add"
          onClick={cleanCanvas}
        >
          <UndoIcon sx={{ color: "white" }} />
        </Fab>
      </div>
    </div>
  );
};

export default Canvas;
