import { getHeaderSimple } from "./utils";

export const getReceptionData = async (month, year) => {
  const body = {
    month: month,
    year: year,
  };

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}reception/byMonth`,
    {
      method: "POST",
      headers: getHeaderSimple(),
      body: JSON.stringify(body),
    }
  );
  const json = await response.json();

  if (json.message === "Ok") {
    return {
      status: "Ok",
      message: "Ok",
      content: json.data,
    };
  } else {
    return {
      status: "Error",
      message: json.content ?? "Error de conexión",
    };
  }
};

export const createDate = async (
  studyDate,
  name,
  lastname,
  email,
  phone,
  patientId,
  reason,
  studyType,
  referredId,
  referred
) => {
  let body = {
    studyDate: studyDate,
    name: name,
    lastname: lastname,
    email: email,
    phone: phone,
    reason: reason,
    studyType: studyType,
    foundationId: referredId?._id ?? null,
    foundationName: referred,
  };

  if (patientId !== null && patientId !== "") {
    body.patientId = patientId;
  }

  const response = await fetch(`${process.env.REACT_APP_API_URL}reception/`, {
    method: "POST",
    headers: getHeaderSimple(),
    body: JSON.stringify(body),
  });
  const json = await response.json();

  if (json.content === "Ok") {
    return {
      status: "Ok",
      message: "Ok",
      content: json.data,
    };
  } else {
    return {
      status: "Error",
      message: json.content ?? "Error de conexión",
    };
  }
};

export const updateEvent = async (
  studyDate,
  name,
  lastname,
  email,
  phone,
  recptioinId,
  patientId,
  reason,
  studyType,
  referredId,
  referred
) => {
  let body = {
    studyDate: studyDate,
    name: name,
    lastname: lastname,
    email: email,
    phone: phone,
    reason: reason,
    studyType: studyType,
    foundationId: referredId?._id ?? null,
    foundationName: referred,
  };

  if (patientId !== null && patientId !== "") {
    body.patientId = patientId;
  }

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}reception/${recptioinId}`,
    {
      method: "PUT",
      headers: getHeaderSimple(),
      body: JSON.stringify(body),
    }
  );
  const json = await response.json();

  if (json.content === "Ok") {
    return {
      status: "Ok",
      message: "Ok",
      content: json.data,
    };
  } else {
    return {
      status: "Error",
      message: json.content ?? "Error de conexión",
    };
  }
};

export const searchReception = async (search) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}reception/search/${search}`,
    {
      method: "GET",
      headers: getHeaderSimple(),
    }
  );
  const json = await response.json();

  if (json.message === "Ok") {
    return {
      status: "Ok",
      message: "Ok",
      content: json.data,
    };
  } else {
    return {
      status: "Error",
      message: json.content ?? "Error de conexión",
    };
  }
};

export const updateStatus = async (status, id) => {
  const body = {
    status: status,
  };

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}reception/update/${id}`,
    {
      method: "PUT",
      headers: getHeaderSimple(),
      body: JSON.stringify(body),
    }
  );
  const json = await response.json();

  if (json.message === "Ok") {
    return {
      status: "Ok",
      message: "Ok",
      content: json.content,
    };
  } else {
    return {
      status: "Error",
      message: json.content ?? "Error de conexión",
    };
  }
};

export const searchPatient = async (search) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}reception/searchPatient/${search}`,
    {
      method: "GET",
      headers: getHeaderSimple(),
    }
  );
  const json = await response.json();

  if (json.message === "Ok") {
    return {
      status: "Ok",
      message: "Ok",
      content: json.data,
    };
  } else {
    return {
      status: "Error",
      message: json.content ?? "Error de conexión",
    };
  }
};

export const searchDoctor = async (search) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}doctor/search/${search}`,
    {
      method: "GET",
      headers: getHeaderSimple(),
    }
  );
  const json = await response.json();

  if (json.message === "Ok") {
    return {
      status: "Ok",
      message: "Ok",
      content: json.data,
    };
  } else {
    return {
      status: "Error",
      message: json.content ?? "Error de conexión",
    };
  }
};

export const createGeneralData = async (
  name,
  lastname,
  birthday,
  age,
  gender,
  phone,
  email,
  patientId,
  receptionId,
  state,
  city,
  zipCode,
  colony,
  street,
  exteriorNumber,
  interiorNumber,
  doctorId,
  doctorName
) => {
  let body = {
    name: name,
    lastname: lastname,
    birthday: birthday,
    age: age,
    gender: gender,
    phone: phone,
    email: email,
    receptionId: receptionId,
    state: state,
    city: city,
    zipCode: zipCode,
    colony: colony,
    street: street,
    exteriorNumber: exteriorNumber,
    interiorNumber: interiorNumber,
  };

  if (doctorId) {
    body.doctorId = doctorId;
  }

  if (doctorName) {
    body.doctorName = doctorName;
  }

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}reception/generalData/${patientId}`,
    {
      method: "POST",
      headers: getHeaderSimple(),
      body: JSON.stringify(body),
    }
  );
  const json = await response.json();

  if (json.content === "Ok") {
    return {
      status: "Ok",
      message: "Ok",
      content: json.data,
    };
  } else {
    return {
      status: "Error",
      message: json.content ?? "Error de conexión",
    };
  }
};

export const getFoundationsData = async () => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}foundation`, {
    method: "GET",
    headers: getHeaderSimple(),
  });
  const json = await response.json();

  if (json.message === "Ok") {
    return {
      status: "Ok",
      message: "Ok",
      content: json.data,
    };
  } else {
    return {
      status: "Error",
      message: json.content ?? "Error de conexión",
    };
  }
};

export const searchZipCode = async (search) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}zipcode/${search}`,
    {
      method: "GET",
      headers: getHeaderSimple(),
    }
  );
  const json = await response.json();

  if (json.message === "Ok") {
    return {
      status: "Ok",
      message: "Ok",
      content: json.data,
    };
  } else {
    return {
      status: "Error",
      message: json.content ?? "Error de conexión",
    };
  }
};
