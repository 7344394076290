import ErrorImage from "../../assets/imagesDefault";
import { Card } from "@mui/material";
const CommonErrorsEvents = () => {
  return (
    <div
      style={{
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      <Card id="card-style-error-401">
        <img src={ErrorImage.BackTo401} alt={"Error 401, acceso denegado"} />
      </Card>
    </div>
  );
};

export default CommonErrorsEvents;
