import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
} from "@mui/material";
import * as cornerstone from "cornerstone-core";
import * as cornerstoneWADOImageLoader from "cornerstone-wado-image-loader";
import { filesStore } from "../actions";
import {
  getDicomPatientName,
  getDicomStudyId,
  getDicomStudyDate,
  getDicomStudyTime,
  getDicomStudyDescription,
  getDicomSeriesDate,
  getDicomSeriesTime,
  getDicomSeriesDescription,
  getDicomSeriesNumber,
  getDicomInstanceNumber,
  getDicomSliceLocation,
  getDicomSliceDistance,
  getDicomRows,
  getDicomColumns,
  getDicomEchoNumber,
  getFileNameCorrect,
  dicomDateTimeToLocale,
} from "../functions";

cornerstoneWADOImageLoader.external.cornerstone = cornerstone;

class OpenMultipleFilesDlg extends PureComponent {
  state = {
    progress: 0,
    cancel: false,
  };

  componentDidMount() {
    this.items = [];
    this.count = 0;
    this.step = 0;

    const files = this.props.files;

    this.step = files.length / 50;
    this.nextProgress = this.step;
    this.t0 = performance.now();

    let imageIds = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (this.props.origin === "local")
        imageIds.push(cornerstoneWADOImageLoader.wadouri.fileManager.add(file));
      else
        imageIds.push(
          cornerstoneWADOImageLoader.wadouri.fileManager.addBuffer(file.data)
        );
    }

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (this.state.cancel) {
        this.props.setFilesStore(null);
        this.close();
        return;
      }

      cornerstone.loadImage(imageIds[i]).then(
        (image) => {
          const patientName = getDicomPatientName(image);

          const studyId = getDicomStudyId(image);
          const studyDate = getDicomStudyDate(image);
          const studyTime = getDicomStudyTime(image);
          const studyDescription = getDicomStudyDescription(image);

          const seriesDate = getDicomSeriesDate(image);
          const seriesTime = getDicomSeriesTime(image);
          const seriesDescription = getDicomSeriesDescription(image);
          const seriesNumber = getDicomSeriesNumber(image);

          const instanceNumber = getDicomInstanceNumber(image);
          const sliceDistance = getDicomSliceDistance(image);
          const echoNumber = getDicomEchoNumber(image);
          const sliceLocation = getDicomSliceLocation(image);
          const columns = getDicomColumns(image);
          const rows = getDicomRows(image);

          const studyDateTime =
            studyDate === undefined
              ? undefined
              : dicomDateTimeToLocale(`${studyDate}.${studyTime}`);

          let item = null;
          if (this.props.origin === "local")
            item = {
              imageId: imageIds[i],
              instanceNumber: instanceNumber,
              name: getFileNameCorrect(file.name),
              image: image,
              rows: rows,
              columns: columns,
              sliceDistance: sliceDistance,
              sliceLocation: sliceLocation,
              patient: {
                patientName: patientName,
              },
              study: {
                studyId: studyId,
                studyDate: studyDate,
                studyTime: studyTime,
                studyDateTime: studyDateTime,
                studyDescription: studyDescription,
              },
              series: {
                seriesDate: seriesDate,
                seriesTime: seriesTime,
                seriesDescription: seriesDescription,
                seriesNumber: seriesNumber,
                echoNumber: echoNumber,
              },
            };
          else
            item = {
              imageId: imageIds[i],
              instanceNumber: instanceNumber,
              name: file.name,
              image: image,
              rows: rows,
              columns: columns,
              sliceDistance: sliceDistance,
              sliceLocation: sliceLocation,
              patient: {
                patientName: patientName,
              },
              study: {
                studyId: studyId,
                studyDate: studyDate,
                studyTime: studyTime,
                studyDateTime: studyDateTime,
                studyDescription: studyDescription,
              },
              series: {
                seriesDate: seriesDate,
                seriesTime: seriesTime,
                seriesDescription: seriesDescription,
                seriesNumber: seriesNumber,
                echoNumber: echoNumber,
              },
            };
          this.items.push(item);
          this.count++;

          const progress = Math.floor(this.count * (100 / files.length));
          if (progress > this.nextProgress) {
            this.nextProgress += this.step;
            this.setState({ progress: progress });
          }
          if (this.count === files.length) {
            this.items.sort((l, r) => {
              return l.instanceNumber - r.instanceNumber;
            });
            this.t1 = performance.now();
            this.props.setFilesStore(this.items);
            this.close();
          }
        },
        (e) => {
          this.count++;
        }
      );
      if (this.count === files.length) {
      }
    }
  }

  close = () => {
    this.props.onClose();
  };

  cancel = () => {
    this.setState({ cancel: true });
  };

  render() {
    return (
      <div>
        <Dialog
          open={true}
          onClose={this.close}
          aria-labelledby="alert-dialog-title"
        >
          <DialogTitle id="alert-dialog-title">
            {"Opening multiple files ..."}
          </DialogTitle>
          <DialogContent>
            <LinearProgress
              variant="determinate"
              value={this.state.progress}
              color="secondary"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.cancel}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setFilesStore: (files) => dispatch(filesStore(files)),
  };
};

export default connect(null, mapDispatchToProps)(OpenMultipleFilesDlg);
