import React, { useEffect, useState } from "react";
import { PieChart } from "@mui/icons-material";
import { Button, Paper, Skeleton } from "@mui/material";
import { styled } from "@mui/material/styles";
import dummyResultsDashboard from "../../assets/helpers/dummyResultsDashboard";
import RowAverageResult from "./RowAverageResult";
import theme from "../../assets/theme";
import ResumeCardSelectFilter from "./ResumeCardSelectFilter";

const ViewMoreButton = styled(Button)({
  boxShadow: "0px 3px 6px #00000029",
  textTransform: "none",
  fontSize: 18,
  borderStyle: "none",
  fontFamily: "Helvetica",
  fontWeight: "bold",
  color: theme.palette.pink.default,
  backgroundColor: theme.palette.gray.one,
  paddingLeft: "67px",
  paddingRight: "67px",
  borderRadius: "5px",
});

const DeliveryOfResults = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData("month");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async (type) => {
    setLoading(false);
  };

  const onSelect = (type) => {
    getData(type);
  };

  return (
    <div id="div-style-16" style={{ paddingBottom: "14px" }}>
      <Paper id="paper-style-3" style={{ height: "100%" }}>
        {loading ? (
          <div id="div-style-23">
            <div id="div-style-24">
              <div id="div-style-25">
                <Skeleton variant="circular" height={"30px"} width={"30px"} />
                <Skeleton
                  variant="rectangular"
                  width={"100px"}
                  id="div-style-17"
                />
              </div>

              <Skeleton variant="rectangular" height={"40px"} width={"80px"} />
            </div>

            <div id="div-style-26">
              <div id="div-style-25">
                <Skeleton
                  variant="rectangular"
                  height={"40px"}
                  width={"80px"}
                  style={{ marginBottom: "5px" }}
                />
              </div>

              <div id="div-style-25">
                <Skeleton
                  variant="rectangular"
                  height={"40px"}
                  width={"150px"}
                />
              </div>

              <div id="div-style-29">
                <div id="div-style-30">
                  <Skeleton variant="rectangular" height={"200px"} />
                </div>
              </div>

              <div id="div-style-25">
                <Skeleton
                  variant="rectangular"
                  height={"48px"}
                  width={"200px"}
                />
              </div>
            </div>
          </div>
        ) : (
          <div id="div-style-23">
            <div id="div-style-24">
              <div id="div-style-25">
                <PieChart id="icon-style-5" />
                <div id="div-style-17">Entrega de resultados</div>
              </div>

              <ResumeCardSelectFilter onSelect={onSelect} />
            </div>

            <div id="div-style-26">
              <div id="div-style-27">45 Hrs</div>
              <div id="div-style-28">Tiempo promedio</div>

              <div id="div-style-29">
                <div id="div-style-30">
                  {dummyResultsDashboard.map((itemResult) => {
                    return (
                      <RowAverageResult
                        establishmentName={itemResult.establishmentName}
                        studiesAmount={itemResult.studiesAmount}
                        averageHour={itemResult.averageHour}
                      />
                    );
                  })}
                </div>
              </div>

              <ViewMoreButton>Ver detalle</ViewMoreButton>
            </div>
          </div>
        )}
      </Paper>
    </div>
  );
};

export default DeliveryOfResults;
