import React, { useEffect, useState } from "react";
import { PieChart } from "@mui/icons-material";
import { Button, Grid, Paper, Skeleton } from "@mui/material";
import ReactEcharts from "echarts-for-react";
import { styled } from "@mui/material/styles";
import theme from "../../assets/theme";
import RowStatisticsOfResults from "./RowStatisticsOfResults";
import dummyStatisticsOfResult from "../../assets/helpers/dummyStatisticsOfResult";
import ResumeCardSelectFilter from "./ResumeCardSelectFilter";

const ViewMoreButton = styled(Button)({
  boxShadow: "0px 3px 6px #00000029",
  textTransform: "none",
  fontSize: 18,
  borderStyle: "none",
  fontFamily: "Helvetica",
  fontWeight: "bold",
  color: theme.palette.pink.default,
  backgroundColor: theme.palette.gray.one,
  paddingLeft: "67px",
  paddingRight: "67px",
  borderRadius: "5px",
});

const StatisticsByResults = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData("month");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async (type) => {
    setLoading(false);
  };

  const onSelect = (type) => {
    getData(type);
  };

  return (
    <div id="div-style-16" style={{ paddingBottom: "14px" }}>
      <Paper id="paper-style-3" style={{ height: "100%" }}>
        {loading ? (
          <div
            style={{
              width: "100%",
              textAlign: "center",
              marginBottom: "10px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div id="div-style-25">
                <Skeleton variant="circular" height={"30px"} width={"30px"} />
                <Skeleton
                  variant="rectangular"
                  width={"100px"}
                  id="div-style-17"
                />
              </div>

              <Skeleton variant="rectangular" height={"40px"} width={"80px"} />
            </div>

            <div
              style={{
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "22px",
                marginBottom: "22px",
              }}
            >
              <div id="div-style-25" style={{ marginTop: "80px" }}>
                <Grid sm={8}>
                  <Skeleton variant="rectangular" height={"200px"} />
                </Grid>
              </div>
            </div>

            <div id="div-style-25" style={{ marginTop: "40px" }}>
              <Skeleton variant="rectangular" height={"48px"} width={"200px"} />
            </div>
          </div>
        ) : (
          <div
            style={{
              width: "100%",
              textAlign: "center",
              marginBottom: "10px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div id="div-style-25">
                <PieChart style={{ color: "#1AA1B9" }} />
                <div
                  style={{
                    fontSize: "24px",
                    fontWeight: "600",
                    marginLeft: "5px",
                  }}
                >
                  Estadísticos por resultados
                </div>
              </div>

              <ResumeCardSelectFilter onSelect={onSelect} />
            </div>

            <div
              style={{
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "22px",
                marginBottom: "22px",
              }}
            >
              <div id="div-style-25">
                <Grid sm={7}>
                  <ReactEcharts
                    option={{
                      color: [
                        theme.palette.pink.default,
                        theme.palette.gray.default,
                        theme.palette.secondary.main,
                        theme.palette.gray.two,
                        theme.palette.orange.default,
                      ],
                      tooltip: {
                        trigger: "item",
                        formatter: "{b}: {d}%",
                      },
                      legend: {
                        show: false,
                      },
                      series: [
                        {
                          type: "pie",
                          radius: ["40%", "55%"],
                          avoidLabelOverlap: false,
                          itemStyle: {
                            borderColor: "#fff",
                          },
                          label: {
                            show: false,
                          },
                          emphasis: {
                            label: {
                              show: false,
                            },
                          },
                          labelLine: {
                            show: false,
                          },
                          data: [
                            { value: 15, name: "Grado 1" },
                            { value: 33, name: "Grado 2" },
                            { value: 20, name: "Grado 3" },
                            { value: 12, name: "Grado 4" },
                            { value: 20, name: "Grado 5" },
                          ],
                        },
                      ],
                    }}
                  />
                </Grid>

                <Grid sm={5}>
                  <div>
                    {dummyStatisticsOfResult.map((item) => {
                      return (
                        <RowStatisticsOfResults
                          title={item.title}
                          percent={item.percent}
                        />
                      );
                    })}
                  </div>
                </Grid>
              </div>
            </div>

            <ViewMoreButton>Ver detalle</ViewMoreButton>
          </div>
        )}
      </Paper>
    </div>
  );
};

export default StatisticsByResults;
