import React, { PureComponent } from "react";
import {
  Button,
  InputLabel,
  TextField,
  CircularProgress,
  Grid,
  Card,
  IconButton,
} from "@mui/material";
import CollectionsIcon from "@mui/icons-material/Collections";
import { Close } from "@mui/icons-material";
import SnackBarMessage from "../../components/SnackBarMessage";
import { connect } from "react-redux";
import DicomViewer from "../components/DicomViewer";
import { isMobile } from "react-device-detect";

import "../../pages/components/DicomViewerPage.css";
import { log, getDicomStudyId, getDicomIpp, objectIsEmpty } from "../functions";
import {
  clearStore,
  localFileStore,
  dcmIsOpen,
  activeDcm,
  activeDcmIndex,
  activeMeasurements,
  setLayout,
  dcmTool,
  setDicomdir,
  setZippedFile,
  setVolume,
  filesStore,
  explorerActiveSeriesIndex,
} from "../actions/index";
import { createResult } from "../repositories/ResultsRepository";
import DialogProgressUploadFiles from "../components/DialogProgressUploadFiles";

log();

class ImageResultForm extends PureComponent {
  constructor(props) {
    super(props);
    this.files = [];
    this.folder = null;
    this.file = null;
    this.url = null;
    this.explorer = null;
    this.series = null;
    this.mprData = {};
    this.mprPlane = "";
    this.echoNumber = 0;
    this.volume = [];
    this.fileOpen = React.createRef();
    this.showFileOpen = this.showFileOpen.bind(this);
    this.openDicomdir = React.createRef();
    this.showOpenDicomdir = this.showOpenDicomdir.bind(this);
    this.openFolder = React.createRef();
    this.showOpenFolder = this.showOpenFolder.bind(this);
    this.openUrlField = React.createRef();
    this.dicomViewersActive = [];
    this.dicomViewersActiveSameStudy = [];
    this.dicomViewersActiveSamePlane = [];
    this.dicomViewersRefs = [];
    this.dicomViewers = [];
    for (let i = 0; i < 16; i++) {
      this.dicomViewers.push(this.setDcmViewer(i));
    }

    this.activeDcmViewersNum = 0;
    this.referenceLines = {};
    this.arrayImage = [];
    this.defaultImage = [];
    this.loading = false;
    this.snackOpen = false;
    this.snackSeverity = "success";
    this.snackMessage = "";
  }

  state = {
    anchorElLayout: null,
    anchorElToolsPanel: null,
    openMenu: true,
    openImageEdit: false,
    openTools: false,
    mprMenu: false,
    mprMode: false,
    textMessage: "",
    titleMessage: "",
    visibleMainMenu: true,
    visibleHeader: false,
    visibleSettings: false,
    visibleToolbar: true,
    visibleOpenUrl: false,
    visibleToolbox: false,
    visibleTools: false,
    visibleMeasure: false,
    visibleClearMeasureDlg: false,
    visibleAbout: false,
    visibleDicomdir: false,
    visibleFileManager: false,
    visibleZippedFileDlg: false,
    visibleDownloadZipDlg: false,
    visibleOpenMultipleFilesDlg: false,
    dialogProgress: 0,
    toolState: 1,
    toolActive: "notool",
    sliceIndex: 0,
    sliceMax: 1,
    listOpenFilesScrolling: false,
    visibleVolumeBuilding: false,
    visibleMpr3D: false,
    visibleMprOrthogonal: false,
    visibleMprCoronal: false,
    visibleMprSagittal: false,
    visibleMprAxial: false,
    visibleExplorer: false,
    visibleMessage: false,
    visibleReferenceLines: true,
    visibleSeriesLink: true,
    arraySelectedFiles: [],
    updateKey: 0,
  };

  setDcmViewer = (index) => {
    return (
      <DicomViewer
        dcmRef={(ref) => {
          this.dicomViewersRefs[index] = ref;
        }}
        index={index}
        dicomViewersRefs={this.dicomViewersRef}
        runTool={(ref) => (this.runTool = ref)}
        changeTool={(ref) => (this.changeTool = ref)}
        onLoadedImage={this.onLoadedImage}
        onRenderedImage={this.onRenderedImage}
        listOpenFilesPreviousFrame={this.listOpenFilesPreviousFrame}
        listOpenFilesNextFrame={this.listOpenFilesNextFrame}
        overlay={true}
        visible={true}
        onLoadFileInfo={this.onLoadFileInfo}
      />
    );
  };

  onLoadFileInfo = (value) => {
    const list = this.state.arraySelectedFiles;

    for (let w = 0; w < value.length; w++) {
      list.push(value[w]);
    }

    this.setState({ arraySelectedFiles: list });
    this.setState({
      updateKey: this.state.updateKey + 1,
    });
  };

  onLoadedImage = () => {};

  onRenderedImage = (index) => {
    if (
      this.referenceLines.crossViewer !== undefined &&
      this.state.visibleReferenceLines &&
      this.isSliceChange &&
      this.referenceLines.crossViewer.layoutIndex === index
    ) {
      this.isSliceChange = false;
      this.referenceLinesShow();
    }
  };

  getDcmViewerRef = (index) => {
    return this.dicomViewersRefs[index];
  };

  getDcmViewer = (index) => {
    return this.dicomViewers[index];
  };

  showFileOpen() {
    this.props.isOpenStore(false);
    this.fileOpen.current.click();
  }

  recursiveReadFile = (index, filesSelected) => {
    if (index < filesSelected.length) {
      setTimeout(() => {
        const file = filesSelected[index];

        if (
          file.type === "application/x-zip-compressed" ||
          file.type === "application/zip"
        ) {
          this.file = file;
          this.url = null;
          this.setState({ visibleZippedFileDlg: true });
        } else {
          this.setState({ sliceIndex: 0, sliceMax: 1 });
          this.props.setLocalFileStore(file);
          this.dicomViewersRefs[this.props.activeDcmIndex].runTool("clear");
          this.dicomViewersRefs[this.props.activeDcmIndex].runTool(
            "openLocalFs",
            file
          );
        }

        const totalFiles = filesSelected.length;
        const nextProggress = ((index + 1) / totalFiles) * 100;

        this.setState({ dialogProgress: nextProggress });
        this.recursiveReadFile(index + 1, filesSelected);
      }, 750);
    } else {
      this.setState({ visibleOpenMultipleFilesDlg: false });
    }
  };

  handleOpenLocalFs = (filesSelected) => {
    if (filesSelected.length > 1) {
      this.setState({ visibleOpenMultipleFilesDlg: true });
      this.setState({ dialogProgress: 0 });
      this.recursiveReadFile(0, filesSelected);
    } else {
      const file = filesSelected[0];
      if (
        file.type === "application/x-zip-compressed" ||
        file.type === "application/zip"
      ) {
        this.file = file;
        this.url = null;
        this.setState({ visibleZippedFileDlg: true });
      } else {
        this.setState({ sliceIndex: 0, sliceMax: 1 });
        this.props.setLocalFileStore(file);
        this.dicomViewersRefs[this.props.activeDcmIndex].runTool("clear");
        this.dicomViewersRefs[this.props.activeDcmIndex].runTool(
          "openLocalFs",
          file
        );
      }
    }
  };

  handleOpenImage = (index) => {
    this.dicomViewersRefs[this.props.activeDcmIndex].sliceIndex = index;

    const visibleMprOrthogonal = this.state.visibleMprOrthogonal;
    const visibleMprSagittal = this.state.visibleMprSagittal;
    const visibleMprCoronal = this.state.visibleMprCoronal;
    const visibleMprAxial = this.state.visibleMprAxial;
    const plane = this.mprPlanePosition();

    if (visibleMprOrthogonal) {
      if (this.props.activeDcmIndex === 0) {
        this.dicomViewersRefs[0].runTool("openimage", index);
        if (this.state.mprMode) {
          this.dicomViewersRefs[1].mprReferenceLines(index);
          this.dicomViewersRefs[2].mprReferenceLines(index);
        }
      } else if (this.props.activeDcmIndex === 1) {
        this.dicomViewersRefs[1].mprRenderXZPlane(
          this.dicomViewersRefs[0].filename,
          plane,
          index,
          this.mprData
        );
        this.dicomViewersRefs[2].mprReferenceLines2(index);
        this.dicomViewersRefs[0].mprReferenceLines3(index, this.mprData);
      } else if (this.props.activeDcmIndex === 2) {
        this.dicomViewersRefs[2].mprRenderYZPlane(
          this.dicomViewersRefs[0].filename,
          plane,
          index,
          this.mprData
        );
        this.dicomViewersRefs[1].mprReferenceLines2(index);
        this.dicomViewersRefs[0].mprReferenceLines3(index, this.mprData);
      }
    } else {
      if (objectIsEmpty(this.mprData)) {
        this.dicomViewersRefs[this.props.activeDcmIndex].runTool(
          "openimage",
          index
        );
      } else if (
        (plane === "sagittal" && visibleMprSagittal) ||
        (plane === "axial" && visibleMprAxial) ||
        (plane === "coronal" && visibleMprCoronal)
      )
        this.dicomViewersRefs[this.props.activeDcmIndex].runTool(
          "openimage",
          index
        );
      else if (plane === "sagittal" && visibleMprAxial)
        this.dicomViewersRefs[this.props.activeDcmIndex].mprRenderXZPlane(
          this.dicomViewersRefs[0].filename,
          plane,
          index,
          this.mprData
        );
      else if (plane === "sagittal" && visibleMprCoronal)
        this.dicomViewersRefs[this.props.activeDcmIndex].mprRenderYZPlane(
          this.dicomViewersRefs[0].filename,
          plane,
          index,
          this.mprData
        );
      else if (plane === "axial" && visibleMprSagittal)
        this.dicomViewersRefs[this.props.activeDcmIndex].mprRenderYZPlane(
          this.dicomViewersRefs[0].filename,
          plane,
          index,
          this.mprData
        );
      else if (plane === "axial" && visibleMprCoronal)
        this.dicomViewersRefs[this.props.activeDcmIndex].mprRenderXZPlane(
          this.dicomViewersRefs[0].filename,
          plane,
          index,
          this.mprData
        );
      else if (plane === "coronal" && visibleMprSagittal)
        this.dicomViewersRefs[this.props.activeDcmIndex].mprRenderYZPlane(
          this.dicomViewersRefs[0].filename,
          plane,
          index,
          this.mprData
        );
      else if (plane === "coronal" && visibleMprAxial)
        this.dicomViewersRefs[this.props.activeDcmIndex].mprRenderXZPlane(
          this.dicomViewersRefs[0].filename,
          plane,
          index,
          this.mprData
        );
      else {
        this.dicomViewersRefs[this.props.activeDcmIndex].runTool(
          "openimage",
          index
        );
      }
    }

    this.activeDcmViewersNum = this.getActiveDcmViewers();
    if (this.activeDcmViewersNum > 0) {
      this.getActiveDcmViewersSameStudy();
      this.getActiveDcmViewersSamePlane();
      if (
        this.state.visibleReferenceLines &&
        this.dicomViewersActive.length > 1
      ) {
        this.referenceLinesShow();
      }
    }
  };

  showOpenFolder() {
    this.openFolder.current.click();
  }

  showOpenDicomdir() {
    this.openDicomdir.current.click();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  layoutGridClick = (index) => {
    if (isMobile && index === this.props.activeDcmIndex) return;

    const sliceMax = this.dicomViewersRefs[index].sliceMax;
    const sliceIndex = this.dicomViewersRefs[index].sliceIndex;
    this.setState({ sliceMax: sliceMax, sliceIndex: sliceIndex });

    this.props.setActiveDcmIndex(index);

    if (this.state.visibleMprOrthogonal) {
      this.setState({ sliceMax: sliceMax, sliceIndex: sliceIndex });
    }

    const dcmViewer = this.getDcmViewerRef(index);
    if (dcmViewer.image === null) return;

    this.props.setActiveMeasurements(dcmViewer.measurements);
    this.props.setActiveDcm(dcmViewer);
    this.props.setExplorerActiveSeriesIndex(dcmViewer.explorerIndex);

    if (
      this.state.visibleReferenceLines &&
      this.dicomViewersActive.length > 1
    ) {
      if (this.referenceLines.scoutViewer !== undefined)
        this.referenceLines.scoutViewer.updateImage();
      this.setState({ visibleReferenceLines: false }, () => {
        this.referenceLinesToggle();
      });
    }
  };

  layoutGridTouch = (index) => {
    if (!isMobile && index === this.props.activeDcmIndex) return;
  };

  buildLayoutGrid = () => {
    let dicomviewers = [];
    for (let i = 0; i < this.props.layout[0]; i++) {
      for (let j = 0; j < this.props.layout[1]; j++) {
        const styleLayoutGrid = {
          border:
            this.props.layout[0] === 1 && this.props.layout[1] === 1
              ? "solid 1px #000000"
              : "solid 1px #444444",
        };
        const index = i * 4 + j;
        dicomviewers.push(
          <div
            key={index}
            style={styleLayoutGrid}
            onClick={() => this.layoutGridClick(index)}
            onTouchStart={() => this.layoutGridTouch(index)}
          >
            {this.getDcmViewer(index)}
          </div>
        );
      }
    }

    return (
      <div
        id="dicomviewer-grid"
        style={{
          display: "grid",
          gridTemplateRows: `repeat(${this.props.layout[0]}, ${
            100 / this.props.layout[0]
          }%)`,
          gridTemplateColumns: `repeat(${this.props.layout[1]}, ${
            100 / this.props.layout[1]
          }%)`,
          height: "100%",
          width: "100%",
        }}
      >
        {dicomviewers}
      </div>
    );
  };

  referenceLinesToggle = () => {
    const visible = !this.state.visibleReferenceLines;
    this.setState({ visibleReferenceLines: visible });
    if (visible && this.dicomViewersActive.length > 1) {
      this.referenceLinesShow();
    } else {
      this.referenceLinesHide();
    }
  };

  referenceLinesShow = () => {
    if (
      this.state.mprMode ||
      !this.state.visibleReferenceLines ||
      this.dicomViewersActive.length < 2
    )
      return;
    this.referenceLines.crossViewer = this.getDcmViewerRef(
      this.props.activeDcmIndex
    );
    const crossMprPlane = this.referenceLines.crossViewer.mprPlane;
    this.openViewers = this.dicomViewersActive.filter(
      (v) =>
        v.layoutIndex !== this.props.activeDcmIndex &&
        crossMprPlane !== v.mprPlane
    );
    this.referenceLines.crossViewer.updateImage();
    for (let i = 0; i < this.openViewers.length; i++) {
      this.openViewers[i].referenceLinesBuild(
        this.referenceLines.crossViewer.image
      );
    }
  };

  referenceLinesHide = () => {
    if (this.state.mprMode || this.openViewers === undefined) return;
    for (let i = 0; i < this.openViewers.length; i++) {
      this.openViewers[i].updateImage();
    }
  };

  mprPlanePosition = (force = false, index = this.props.activeDcmIndex) => {
    if (this.mprPlane === "" || force) {
      this.mprPlane = this.dicomViewersRefs[index].mprPlanePosition();
      if (!this.state.visibleMprOrthogonal) {
        if (this.mprPlane === "sagittal")
          this.setState({
            visibleMprOrthogonal: false,
            visibleMprSagittal: true,
            visibleMprAxial: false,
            visibleMprCoronal: false,
          });
        else if (this.mprPlane === "coronal")
          this.setState({
            visibleMprOrthogonal: false,
            visibleMprSagittal: false,
            visibleMprAxial: false,
            visibleMprCoronal: true,
          });
        else
          this.setState({
            visibleMprOrthogonal: false,
            visibleMprSagittal: false,
            visibleMprAxial: true,
            visibleMprCoronal: false,
          });
      }
    }
    return this.mprPlane;
  };

  listOpenFilesPreviousFrame = () => {
    let index = this.state.sliceIndex;
    index = index === 0 ? this.state.sliceMax - 1 : index - 1;

    this.setState({ sliceIndex: index }, () => {
      this.isSliceChange = true;
      this.handleOpenImage(index);
      this.syncActiveDcmViewersSamePlane(-1);
    });
  };

  listOpenFilesNextFrame = () => {
    let index = this.state.sliceIndex;
    index = index === this.state.sliceMax - 1 ? 0 : index + 1;
    this.setState({ sliceIndex: index }, () => {
      this.isSliceChange = true;
      this.handleOpenImage(index);
      this.syncActiveDcmViewersSamePlane(+1);
    });
  };

  getActiveDcmViewers = () => {
    this.dicomViewersActive = this.dicomViewersRefs.filter(
      (v) => v !== undefined && v.image !== null
    );
    return this.dicomViewersActive.length;
  };

  getActiveDcmViewersSameStudy = () => {
    const studyId = getDicomStudyId(
      this.dicomViewersRefs[this.props.activeDcmIndex].image
    );
    this.dicomViewersActiveSameStudy = this.dicomViewersRefs.filter(
      (v) => v !== undefined && getDicomStudyId(v.image) === studyId
    );
    return this.dicomViewersActiveSameStudy.length;
  };

  getActiveDcmViewersSamePlane = () => {
    this.dicomViewersActiveSamePlane = [];
    const plane = this.dicomViewersRefs[this.props.activeDcmIndex].mprPlane;
    for (let i = 0; i < this.dicomViewersActiveSameStudy.length; i++) {
      if (
        this.dicomViewersActiveSameStudy[i].mprPlane === plane &&
        this.dicomViewersActiveSameStudy[i].layoutIndex !==
          this.props.activeDcmIndex
      )
        this.dicomViewersActiveSamePlane.push(
          this.dicomViewersActiveSameStudy[i]
        );
    }
  };

  syncActiveDcmViewersSamePlane = (direction) => {
    if (!this.state.visibleSeriesLink) return;
    if (this.dicomViewersActiveSamePlane.length > 0) {
      const plane = this.dicomViewersRefs[this.props.activeDcmIndex].mprPlane;
      const ippX = getDicomIpp(
        this.dicomViewersRefs[this.props.activeDcmIndex].image,
        0
      );
      const ippY = getDicomIpp(
        this.dicomViewersRefs[this.props.activeDcmIndex].image,
        1
      );
      const ippZ = getDicomIpp(
        this.dicomViewersRefs[this.props.activeDcmIndex].image,
        2
      );

      let j = 0;
      for (let i = 0; i < this.dicomViewersActiveSamePlane.length; i++) {
        if (plane === "sagittal")
          j = this.dicomViewersActiveSamePlane[i].findFirstSliceWithIppValue(
            ippX,
            0
          );
        else if (plane === "coronal")
          j = this.dicomViewersActiveSamePlane[i].findFirstSliceWithIppValue(
            ippY,
            1
          );
        else if (plane === "axial")
          j = this.dicomViewersActiveSamePlane[i].findFirstSliceWithIppValue(
            ippZ,
            2
          );
        if (j >= 0) this.dicomViewersActiveSamePlane[i].runTool("openimage", j);
      }
    }
  };

  deleteFile = (item) => {
    const files = this.state.arraySelectedFiles;
    const list = [];

    for (let q = 0; q < files.length; q += 1) {
      const itemQ = files[q];
      if (itemQ.fileName !== item.fileName) {
        list.push(itemQ);
      }
    }

    this.setState({ arraySelectedFiles: list });
    this.setState({
      updateKey: this.state.updateKey + 1,
    });
  };

  uploadImageToResult = async () => {
    if (this.state.arraySelectedFiles.length > 0) {
      this.loading = true;
      this.setState({
        updateKey: this.state.updateKey + 1,
      });

      const response = await createResult(
        this.props.patientItem,
        this.state.arraySelectedFiles
      );

      if (response.data.message === "Ok") {
        this.props.onCloseSuccess(this.props.patientItem._id);
        this.loading = false;
        this.setState({
          updateKey: this.state.updateKey + 1,
        });
      } else {
        this.snackSeverity = "warning";
        this.snackMessage = response.data.content ?? "Error de conexión";
        this.snackOpen = true;
        this.setState({
          updateKey: this.state.updateKey + 1,
        });
      }
    } else {
      this.snackOpen = true;
      this.snackSeverity = "warning";
      this.snackMessage = "No hay imágenes";
      this.setState({
        updateKey: this.state.updateKey + 1,
      });
    }
  };

  render() {
    this.isMultipleFiles = false;
    if (this.dicomViewersRefs[this.props.activeDcmIndex] === undefined) {
      this.isMultipleFiles = false;
    } else {
      if (this.dicomViewersRefs[this.props.activeDcmIndex].files !== null)
        this.isMultipleFiles =
          this.dicomViewersRefs[this.props.activeDcmIndex].files.length > 1;
      else this.isMultipleFiles = false;
    }

    const visibleOpenMultipleFilesDlg = this.state.visibleOpenMultipleFilesDlg;

    return (
      <div
        id="div-scrolling-style-result"
        style={{ overflowY: "auto", height: "80vh", paddingBottom: "50px" }}
      >
        <div id="div-style-9-result">
          <Grid container spacing={1}>
            <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
              <CollectionsIcon
                sx={{
                  float: "left",
                  color: "#EC7BA1",
                  marginRight: "10px",
                  marginLeft: "3%",
                }}
              />
              <InputLabel> Imágenes de resultado </InputLabel>
            </Grid>

            <Grid
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{ height: "38vh" }}
            >
              <div style={{ position: "relative" }}>
                <div
                  style={{
                    textAlign: "center",
                    width: "100%",
                    position: "absolute",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      height: "36vh",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Grid
                      item
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{ marginTop: "12vh" }}
                    >
                      <div>Arrastra el archivo dicom aquí</div>
                      <div>o</div>
                      <div
                        style={{
                          background: "#DEDEDE",
                          padding: "9px",
                          borderRadius: "5px",
                          color: "#EC7BA1",
                          marginTop: "5px",
                        }}
                      >
                        Súbelo desde tu equipo
                      </div>
                    </Grid>
                  </div>
                </div>

                <div
                  style={{
                    width: "100%",
                    height: "32vh",
                    position: "absolute",
                  }}
                >
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div id="div-style-10-result">
                      <TextField
                        type="file"
                        inputProps={{ multiple: true }}
                        id="text-file-style-1-result"
                        fullWidth
                        onChange={(e) => this.handleOpenLocalFs(e.target.files)}
                      />
                    </div>
                  </Grid>
                </div>
              </div>
            </Grid>

            <div style={{ display: "none" }}>{this.buildLayoutGrid()}</div>

            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{ overflowY: "auto" }}
            >
              {this.state.arraySelectedFiles.length > 0 ? (
                <div
                  style={{
                    marginLeft: "24px",
                    marginRight: "36px",
                  }}
                >
                  {this.state.arraySelectedFiles.map((item, index) => (
                    <Card id="card-style-file-result">
                      <Grid container>
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            marginLeft: "24px",
                            marginRight: "24px",
                            paddingLeft: "12",
                            paddingTop: "8px",
                            paddingRight: "12",
                            paddingBottom: "8px",
                          }}
                        >
                          <Grid item xl={5} lg={5} md={5} sm={5} xs={5}>
                            <div>{item.fileName}</div>
                          </Grid>
                          <Grid item xl={5} lg={5} md={5} sm={5} xs={5}>
                            <div>{`${item.frames} imágenes`}</div>
                          </Grid>
                          <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                            <div style={{ float: "right" }}>
                              <IconButton
                                aria-label="delete"
                                size="small"
                                color="danger"
                                onClick={() => {
                                  this.deleteFile(item);
                                }}
                              >
                                <Close
                                  style={{
                                    borderRadius: "100%",
                                    color: "red",
                                  }}
                                />
                              </IconButton>
                            </div>
                          </Grid>
                        </div>
                      </Grid>
                    </Card>
                  ))}
                </div>
              ) : null}

              <div
                style={{
                  width: "100%",
                  marginTop: "100px",
                  marginBottom: "24px",
                  textAlign: "center",
                }}
              >
                {this.loading ? (
                  <CircularProgress />
                ) : (
                  <div
                    style={{
                      paddingLeft: "30%",
                      paddingRight: "30%",
                    }}
                  >
                    <Button
                      id="button-style-save-reception-form"
                      sise="small"
                      variant="contained"
                      fullWidth
                      onClick={() => {
                        this.uploadImageToResult();
                      }}
                    >
                      Guardar
                    </Button>
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
        </div>

        {visibleOpenMultipleFilesDlg ? (
          <DialogProgressUploadFiles
            files={this.files}
            progress={this.state.dialogProgress}
          />
        ) : null}

        <SnackBarMessage
          severity={this.snackSeverity}
          message={this.snackMessage}
          open={this.snackOpen}
          onClose={() => {
            this.snackOpen = false;
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    localFileStore: state.localFileStore,
    files: state.files,
    series: state.series,
    isOpen: state.isOpen,
    tool: state.tool,
    activeDcmIndex: state.activeDcmIndex,
    explorerActivePatientIndex: state.explorerActivePatientIndex,
    explorerActiveStudyIndex: state.explorerActiveStudyIndex,
    explorerActiveSeriesIndex: state.explorerActiveSeriesIndex,
    measurements: state.measurements,
    layout: state.layout,
    dicomdir: state.dicomdir,
    fsZippedFile: state.fsZippedFile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearingStore: () => dispatch(clearStore()),
    setLocalFileStore: (file) => dispatch(localFileStore(file)),
    toolStore: (tool) => dispatch(dcmTool(tool)),
    isOpenStore: (value) => dispatch(dcmIsOpen(value)),
    setActiveDcm: (dcm) => dispatch(activeDcm(dcm)),
    setActiveDcmIndex: (index) => dispatch(activeDcmIndex(index)),
    setActiveMeasurements: (measurements) =>
      dispatch(activeMeasurements(measurements)),
    setLayoutStore: (row, col) => dispatch(setLayout(row, col)),
    setDicomdirStore: (dicomdir) => dispatch(setDicomdir(dicomdir)),
    setFsZippedFile: (file) => dispatch(setZippedFile(file)),
    setVolumeStore: (file) => dispatch(setVolume(file)),
    setFilesStore: (files) => dispatch(filesStore(files)),
    setExplorerActiveSeriesIndex: (index) =>
      dispatch(explorerActiveSeriesIndex(index)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageResultForm);
