import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Card,
  Grid,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  tableCellClasses,
  Paper,
  IconButton,
  Divider,
  CardContent,
  CardActions,
  Tooltip,
  Fade,
  Chip,
} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import InputBase from "@mui/material/InputBase";
import { Search, EditNote } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  getPermissions,
  deleteData,
} from "../repositories/PermissionRepository";
import SnackBarMessage from "../../components/SnackBarMessage";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import PermissionsForm from "../forms/PermissionsForm";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { useNavigate } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#EC7BA1",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },

  // hide last border
  " &:first-child tr, &:first-child": {
    borderTopLeftRadius: "50px",
  },
  " &:first-child tr, &:last-child": {
    borderTopRightRadius: "50px",
  },
  " &:last-child tr, &:first-child": {
    borderBottomLeftRadius: "50px",
  },
  " &:last-child tr, &:last-child": {
    borderBottomRightRadius: "50px",
  },
}));

const CommonPermissionsEvents = () => {
  const [permissionsList, setPermissionsList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackSeverity, setSnackSeverity] = useState("success");
  const [snackMessage, setSnackMessage] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteItem, setDeleteItem] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [objectToEdit, setObjectToEdit] = useState(null);
  const navigate = useNavigate();

  const closeDialog = () => {
    setOpenDelete(false);
  };
  const refreshTable = () => {
    getData();
  };
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - permissionsList.length)
      : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const deletePermission = async () => {
    const response = await deleteData(deleteItem);

    if (response.status === "Ok") {
      refreshTable();
      setSnackSeverity("success");
      setSnackMessage(response.message);
      setSnackOpen(true);
    } else {
      setSnackSeverity("warning");
      setSnackMessage(response.message);
      setSnackOpen(true);
    }
  };

  const getData = async () => {
    try {
      const response = await getPermissions();
      if (response.status === "Ok") {
        setPermissionsList(response.content);
      } else if(response.status === "401") {
        setPermissionsList([]);
        navigate(`../error401`);
      } else {
        setPermissionsList([]);
      }
    } catch (e) {}
  };

  const handleEditing = () => {
    setIsEditing(false);
  };

  useEffect(() => {
    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div id="div-style-8">
        <VpnKeyIcon color={"primary"} fontSize="large" id="icon-style-1" />
        <h2>Permisos</h2>
      </div>
      <Grid container>
        <Grid
          xl={5}
          lg={5}
          md={5}
          sm={12}
          xs={12}
          style={{ paddingRight: "5px" }}
        >
          <div id="div-style-add-rol">
            <div
              id="div-style-8"
              style={{ marginLeft: "10px", color: "white" }}
            >
              <h3>Confuiguración de permisos</h3>
            </div>
            <Card id="card-style-add-rol">
              <CardContent>
                <Grid container spacing={2} id="grid-style-2">
                  <PermissionsForm
                    refreshTable={refreshTable}
                    handleEditing={handleEditing}
                    isEditing={isEditing}
                    objectToEdit={objectToEdit}
                  />
                </Grid>
              </CardContent>
            </Card>
          </div>
        </Grid>
        <Grid
          xl={7}
          lg={7}
          md={7}
          sm={12}
          xs={12}
          style={{ paddingLeft: "5px" }}
        >
          <div id="div-style-show-rol">
            <Card id="card-style-show-rol">
              <CardContent>
                <div id="div-style-8">
                  <LockOpenIcon
                    color={"primary"}
                    fontSize="medium"
                    id="icon-style-unlock"
                  />
                  <h4 id="h4-style-permissions">Permisos otorgados</h4>
                </div>
                <Grid container spacing={2} id="grid-style-2">
                  <Grid sm={6}>
                    <Paper component="form" id="paper-style-2">
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Buscar..."
                        inputProps={{ "aria-label": "search google maps" }}
                      />
                      <Divider
                        sx={{ height: 28, m: 0.5 }}
                        orientation="vertical"
                      />
                      <IconButton
                        type="button"
                        id="icon-style-2"
                        aria-label="search"
                      >
                        <Search color="primary" />
                      </IconButton>
                    </Paper>
                  </Grid>
                </Grid>
                <Grid>
                  <TableContainer id="table-container-style-1">
                    <Table aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>
                            No.
                          </StyledTableCell>
                          <StyledTableCell >
                            Nombre de rol
                          </StyledTableCell>
                          <StyledTableCell
                            id="styledtablecell-style-permissions"
                            align="left"
                          >
                            Permisos otorgados
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Acciones
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {permissionsList &&
                          permissionsList
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row) => {
                              return (
                                <StyledTableRow key={row.id}>
                                  <StyledTableCell
                                    align="center"
                                  >
                                    {row.id}
                                  </StyledTableCell>
                                  <StyledTableCell >
                                    {row.rolname}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    id="styledtablecell-style-permissions"
                                    align="left"
                                  >
                                    <Grid
                                      container
                                      spacing={1}
                                      style={{ maxWidth: "600px"}}
                                    >
                                      {row.permissionsTag.map((tag) => {
                                        return (
                                          <>
                                            {tag !== "" ? (
                                              <Chip
                                                label={tag}
                                                color="secondary"
                                                variant="outlined"
                                                style={{marginLeft: "4px", marginBottom: "4px"}}
                                              />
                                            ) : (
                                              <></>
                                            )}
                                          </>
                                        );
                                      })}
                                    </Grid>
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    <Tooltip
                                      title="Editar rol"
                                      TransitionComponent={Fade}
                                      TransitionProps={{ timeout: 600 }}
                                      arrow
                                    >
                                      <IconButton
                                        aria-label="edit"
                                        size="small"
                                        color="secondary"
                                        onClick={() => {
                                          setIsEditing(true);
                                          setObjectToEdit(row);
                                        }}
                                      >
                                        <EditNote fontSize="medium" />
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip
                                      title="Borrar rol"
                                      TransitionComponent={Fade}
                                      TransitionProps={{ timeout: 600 }}
                                      arrow
                                    >
                                      <IconButton
                                        aria-label="view"
                                        size="small"
                                        color="error"
                                        onClick={async () => {
                                          setOpenDelete(true);
                                          setDeleteItem(row._id);
                                        }}
                                      >
                                        <DeleteIcon fontSize="medium" />
                                      </IconButton>
                                    </Tooltip>
                                  </StyledTableCell>
                                </StyledTableRow>
                              );
                            })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </CardContent>
              <CardActions>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  count={permissionsList.length}
                  component="div"
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage={"Resultados por página:"}
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} de ${count}`
                  }
                />
              </CardActions>
            </Card>
          </div>
        </Grid>
      </Grid>
      <ConfirmationDialog
        title={"Estás a punto de eliminar un permiso"}
        message={
          "¿Estás seguro que deseas eliminar este permiso?, esto puede ocasionar que los usuarios pierda sus privilegios en la plataforma"
        }
        openDialog={openDelete}
        closeDialog={closeDialog}
        executeAction={deletePermission}
      />
      <SnackBarMessage
        severity={snackSeverity}
        message={snackMessage}
        open={snackOpen}
        onClose={() => {
          setSnackOpen(false);
        }}
      />
    </div>
  );
};

export default CommonPermissionsEvents;
