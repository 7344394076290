import { getHeaderMultipart, getHeaderSimple } from "./utils";
import axios from "axios";

export const getUsers = async () => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}user/`, {
    method: "GET",
    headers: getHeaderSimple(),
  });

  const json = await response.json();

  if (json.message === "Ok") {
    return {
      status: "Ok",
      message: "Ok",
      content: json.data,
    };
  } else {
    return {
      status: json.status,
      message: json.error ?? "Error de conexión",
    };
  }
};

export const createUser = async (userObject) => {
  const formData = new FormData();

  formData.append("name", userObject.name);
  formData.append("lastname", userObject.lastname);
  formData.append("phone", userObject.phone);
  formData.append("email", userObject.email);
  formData.append("birthday", userObject.birthday);
  formData.append("gender", userObject.gender);
  formData.append("branchOffice", userObject.branchOffice);
  formData.append("occupation", userObject.occupation);
  formData.append("rol", userObject.rol);
  formData.append("rolId", userObject.rolId);
  formData.append("password", userObject.password);
  formData.append("isZombie", false);
  formData.append("tenant", "XMI");
  formData.append("image", userObject.image);

  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}user`,
    data: formData,
    headers: getHeaderMultipart(),
  });
};

export const updateUser = async (userObject, userId) => {
  const formData = new FormData();

  formData.append("name", userObject.name);
  formData.append("lastname", userObject.lastname);
  formData.append("phone", userObject.phone);
  formData.append("email", userObject.email);
  formData.append("birthday", userObject.birthday);
  formData.append("gender", userObject.gender);
  formData.append("branchOffice", userObject.branchOffice);
  formData.append("occupation", userObject.occupation);
  formData.append("rol", userObject.rol);
  formData.append("rolId", userObject.rolId);
  if (userObject.password !== "") formData.append("password", userObject.password);
  formData.append("isZombie", false);
  formData.append("tenant", "XMI");
  if (
    userObject.image !== '' &&
    !userObject.image.toString().includes('https://res.cloudinary.com')
  ) formData.append("image", userObject.image);

  return axios({
    method: "PUT",
    url: `${process.env.REACT_APP_API_URL}user/${userId}`,
    data: formData,
    headers: getHeaderMultipart(),
  });
};

export const deleteUser = async (id) => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}user/${id}`, {
    method: "DELETE",
    headers: getHeaderSimple(),
  });
  const json = await response.json();
  if (json.message === "Ok") {
    return {
      status: "Ok",
      message: "El elemento se ha eliminado correctamente",
      content: json.data,
    };
  } else {
    return {
      status: "Error",
      message: json.content ?? "Error de conexión",
    };
  }
};

export const deleteData = async (id) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}permission/${id}`,
    {
      method: "DELETE",
      headers: getHeaderSimple(),
    }
  );
  const json = await response.json();
  if (json.message === "Ok") {
    return {
      status: "Ok",
      message: "El elemento se ha eliminado correctamente",
      content: json.data,
    };
  } else {
    return {
      status: "Error",
      message: json.content ?? "Error de conexión",
    };
  }
};

export const updatePermission = async (permissionObject) => {
  const permissionId = permissionObject._id;
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}permission/${permissionId}`,
    {
      method: "PUT",
      body: JSON.stringify(permissionObject),
      headers: getHeaderSimple(),
    }
  );
  const json = await response.json();

  if (json.status === "Ok") {
    return {
      status: "Ok",
      message: "El elemento se ha actualizado correctamente",
      content: json.data,
    };
  } else {
    return {
      status: "Error",
      message: json.content ?? "Error de conexión",
    };
  }
};
