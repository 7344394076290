/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Typography,
  Checkbox,
  TextField,
  Radio,
  RadioGroup,
  InputLabel,
  Chip,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import { DataContext } from "../../contexts/DataContext";
import LabelIcon from "@mui/icons-material/Label";

const SheetFormTab1 = ({ setNextTab, handleContextStatus, showOrEdit }) => {
  const [isAlcohol, setIsAlcohol] = useState(true);
  const [isTobaco, setIsTobaco] = useState(true);
  const contextData = useContext(DataContext);
  const [imcStatus, setImcStatus] = useState("Sin Medida");
  const [imcColor, setImcColor] = useState("#1D0BD7");
  let itemContext = contextData.clinicalSheet.clinicalHistory;

  const calculateIMC = (kg, mts) => {
    let imcValue = 0;
    if (kg > 0 && mts > 0) {
      imcValue = kg / (mts * mts);
    } else {
      imcValue = 0;
    }
    formik.setFieldValue("imc", imcValue.toFixed(2));
    itemContext.imc = imcValue.toFixed(2);

    if (imcValue < 16) {
      itemContext.imcStatus = "Delgadez severa";
      itemContext.imcColor = "#D10D0E";
    } else {
      if (imcValue >= 16 && imcValue <= 16.99) {
        itemContext.imcStatus = "Delgadez moderada";
        itemContext.imcColor = "#FFB900";
      } else {
        if (imcValue >= 17 && imcValue <= 18.49) {
          itemContext.imcStatus = "Delgadez leve";
          itemContext.imcColor = "#FFF001";
        } else {
          if (imcValue >= 18.5 && imcValue <= 24.99) {
            itemContext.imcStatus = "Normal";
            itemContext.imcColor = "#8FE415";
          } else {
            if (imcValue >= 25 && imcValue <= 29.99) {
              itemContext.imcStatus = "Sobrepeso";
              itemContext.imcColor = "#FFF001";
            } else {
              if (imcValue >= 30 && imcValue <= 24.99) {
                itemContext.imcStatus = "Obesidad tipo I";
                itemContext.imcColor = "#8FE415";
              } else {
                if (imcValue >= 35 && imcValue <= 39.99) {
                  itemContext.imcStatus = "Obesidad tipo II";
                  itemContext.imcColor = "#FA5F03";
                } else {
                  if (imcValue >= 40 && imcValue <= 49.99) {
                    itemContext.imcStatus = "Obesidad tipo III (mórbida)";
                    itemContext.imcColor = "#D10D0E";
                  } else {
                    if (imcValue >= 50) {
                      itemContext.imcStatus = "Obesidad tipo IV (extrema)";
                      itemContext.imcColor = "#D10D0E";
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    setImcStatus(itemContext.imcStatus);
    setImcColor(itemContext.imcColor);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      weight: "",
      height: "",
      imc: "",
      imcColor: "#1D0BD7",
      imcStatus: "Sin medida",
      alcohol: "no",
      drinkfrequency: "",
      tobaco: "no",
      smokefrequency: "",
      breastCancer: false,
      colonCancer: false,
      ovarianCancer: false,
      hypertension: false,
      thyroid: false,
      diabetes: false,
      lymphopro: false,
      autoimmune: false,
      breastMother: false,
      breastFather: false,
      breastSiblings: false,
      breastChildren: false,
      colonMother: false,
      colonFather: false,
      colonSiblings: false,
      colonChildren: false,
      ovarianMother: false,
      ovarianFather: false,
      ovarianSiblings: false,
      ovarianChildren: false,
      uterus: false,
      ovary: false,
      endometrium: false,
      otherCheck: false,
      otherInput: "",
    },
    validationSchema: Yup.object().shape({
      //Aqui tengo que poner la validacion con yup
    }),
    onSubmit: async () => {
      setNextTab("2");
    },
  });

  useEffect(() => {
    const showItems = () => {
      formik.setValues({
        weight: itemContext?.weight,
        height: itemContext?.height,
        imc: itemContext?.imc,
        imcColor: itemContext?.imcColor,
        imcStatus: itemContext?.imcStatus,
        alcohol: itemContext?.alcohol,
        drinkfrequency: itemContext?.drinkfrequency,
        tobaco: itemContext?.tobaco,
        smokefrequency: itemContext?.smokefrequency,
        breastCancer: itemContext?.chronicDegenerative.breastCancer,
        colonCancer: itemContext?.chronicDegenerative.colonCancer,
        ovarianCancer: itemContext?.chronicDegenerative.ovarianCancer,
        hypertension: itemContext?.chronicDegenerative.hypertension,
        thyroid: itemContext?.chronicDegenerative.thyroid,
        diabetes: itemContext?.chronicDegenerative.diabetes,
        lymphopro: itemContext?.chronicDegenerative.lymphopro,
        autoimmune: itemContext?.chronicDegenerative.autoimmune,
        breastMother: itemContext?.breastCancer.breastMother,
        breastFather: itemContext?.breastCancer.breastFather,
        breastSiblings: itemContext?.breastCancer.breastSiblings,
        breastChildren: itemContext?.breastCancer.breastChildren,
        colonMother: itemContext?.colonCancer.colonMother,
        colonFather: itemContext?.colonCancer.colonFather,
        colonSiblings: itemContext?.colonCancer.colonSiblings,
        colonChildren: itemContext?.colonCancer.colonChildren,
        ovarianMother: itemContext?.ovarianCancer.ovarianMother,
        ovarianFather: itemContext?.ovarianCancer.ovarianFather,
        ovarianSiblings: itemContext?.ovarianCancer.ovarianSiblings,
        ovarianChildren: itemContext?.ovarianCancer.ovarianChildren,
        uterus: itemContext?.oncologicalHistory.uterus,
        ovary: itemContext?.oncologicalHistory.ovary,
        endometrium: itemContext?.oncologicalHistory.endometrium,
        otherCheck: itemContext?.oncologicalHistory.otherCheck,
        otherInput: itemContext?.oncologicalHistory.otherInput,
      });
      setImcStatus(itemContext?.imcStatus);
      setImcColor(itemContext?.imcColor);
    };
    showItems();
    setNextTab("1");
    handleContextStatus();
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNextTab, itemContext, showOrEdit]);

  return (
    <div
      id="div-scrolling-style-result"
      style={{
        overflowY: "auto",
        height: "74vh",
        paddingBottom: "100px",
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={1}>
          <Grid item={true} md={12}>
            <Typography variant="subtitle2" gutterBottom>
              Información general
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <InputLabel shrink>Peso</InputLabel>
            <TextField
              id="weight"
              size="small"
              disabled={showOrEdit}
              placeholder="00"
              onChange={(e) => {
                formik.setFieldValue("weight", e.target.value);
                calculateIMC(e.target.value, formik.values.height);
                itemContext.weight = e.target.value;
              }}
              value={formik.values.weight}
              onBlur={formik.handleBlur}
            />
          </Grid>
          <Grid item md={2}>
            <InputLabel shrink>Altura</InputLabel>
            <TextField
              id="height"
              size="small"
              disabled={showOrEdit}
              placeholder="0.00"
              onChange={(e) => {
                formik.setFieldValue("height", e.target.value);
                calculateIMC(formik.values.weight, e.target.value);
                itemContext.height = e.target.value;
              }}
              value={formik.values.height}
              onBlur={formik.handleBlur}
            />
          </Grid>
          <Grid item md={2}>
            <InputLabel shrink>IMC calculado</InputLabel>
            <TextField
              id="imc"
              size="small"
              disabled
              onChange={(e) => {
                formik.setFieldValue("imc", e.target.value);
                itemContext.imc = e.target.value;
              }}
              value={formik.values.imc}
              onBlur={formik.handleBlur}
            />
          </Grid>
          <Grid item md={6} sx={{ position: "relative" }}>
            <LabelIcon
              sx={{
                position: "absolute",
                bottom: "5px",
                left: "10px",
                color: `${imcColor}`,
              }}
            />
            <Chip
              label={imcStatus}
              variant="outlined"
              color="primary"
              disabled={showOrEdit}
              sx={{
                flex: 1,
                color: `${imcColor}`,
                width: "100%",
                marginTop: "22px",
                borderRadius: 1,
                fontSize: 16,
              }}
            />
          </Grid>
          <Grid container>
            <Grid item={true} xs={6}>
              <FormControl>
                <FormGroup row={true}>
                  <FormLabel id="formlabel-style-1">
                    <Typography variant="body2">
                      ¿Realiza consumo de alcohol?
                    </Typography>
                  </FormLabel>
                  <RadioGroup
                    id="alcohol"
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    row={true}
                    value={formik.values.alcohol}
                    disabled={showOrEdit}
                    onChange={(e) => {
                      formik.setFieldValue("alcohol", e.target.value);
                      itemContext.alcohol = e.target.value;
                      if (e.target.value === "si") {
                        setIsAlcohol(false);
                      } else {
                        setIsAlcohol(true);
                        formik.setFieldValue("drinkfrequency", "");
                        itemContext.drinkfrequency = "";
                      }
                    }}
                    onBlur={formik.handleBlur}
                  >
                    <FormControlLabel
                      id="formcontrollabel-style-1"
                      value="si"
                      disabled={showOrEdit}
                      label={<Typography variant="body2">Si</Typography>}
                      control={<Radio size="small" />}
                    />
                    <FormControlLabel
                      id="formcontrollabel-style-1"
                      value="no"
                      disabled={showOrEdit}
                      label={<Typography variant="body2">No</Typography>}
                      control={<Radio size="small" />}
                    />
                  </RadioGroup>
                </FormGroup>
              </FormControl>
              <FormControl id="formcontrol-style-1">
                <FormGroup>
                  <RadioGroup
                    id="drinkfrequency"
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    row={true}
                    value={formik.values.drinkfrequency}
                    disabled={showOrEdit}
                    onChange={(e) => {
                      formik.setFieldValue("drinkfrequency", e.target.value);
                      itemContext.drinkfrequency = e.target.value;
                    }}
                    onBlur={formik.handleBlur}
                  >
                    <Grid>
                      <FormControlLabel
                        value="diario"
                        label={<Typography variant="body2">Diario</Typography>}
                        control={<Radio size="small" />}
                        sx={{ marginRight: "37px" }}
                        disabled={isAlcohol}
                      />
                      <FormControlLabel
                        value="semanal"
                        label={<Typography variant="body2">Semanal</Typography>}
                        control={<Radio size="small" />}
                        disabled={isAlcohol}
                      />
                    </Grid>
                    <Grid>
                      <FormControlLabel
                        value="quincenal"
                        label={
                          <Typography variant="body2">Quincenal</Typography>
                        }
                        control={<Radio size="small" />}
                        disabled={isAlcohol}
                      />
                      <FormControlLabel
                        value="mensual"
                        label={<Typography variant="body2">Mensual</Typography>}
                        control={<Radio size="small" />}
                        disabled={isAlcohol}
                      />
                    </Grid>
                  </RadioGroup>
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item={true} xs={6}>
              <FormControl>
                <FormGroup row={true}>
                  <FormLabel id="formlabel-style-1">
                    <Typography variant="body2">
                      ¿Realiza consumo de tabaco?
                    </Typography>
                  </FormLabel>
                  <RadioGroup
                    id="tobaco"
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    row={true}
                    value={formik.values.tobaco}
                    disabled={showOrEdit}
                    onChange={(e) => {
                      formik.setFieldValue("tobaco", e.target.value);
                      itemContext.tobaco = e.target.value;
                      if (e.target.value === "si") {
                        setIsTobaco(false);
                      } else {
                        setIsTobaco(true);
                        formik.setFieldValue("smokefrequency", "");
                        itemContext.smokefrequency = "";
                      }
                    }}
                    onBlur={formik.handleBlur}
                  >
                    <FormControlLabel
                      id="formcontrollabel-style-1"
                      value="si"
                      disabled={showOrEdit}
                      label={<Typography variant="body2">Si</Typography>}
                      control={<Radio size="small" />}
                    />
                    <FormControlLabel
                      id="formcontrollabel-style-1"
                      value="no"
                      disabled={showOrEdit}
                      label={<Typography variant="body2">No</Typography>}
                      control={<Radio size="small" />}
                    />
                  </RadioGroup>
                </FormGroup>
              </FormControl>
              <FormControl id="formcontrol-style-1">
                <FormGroup>
                  <RadioGroup
                    id="smokefrequency"
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    row={true}
                    value={formik.values.smokefrequency}
                    onChange={(e) => {
                      formik.setFieldValue("smokefrequency", e.target.value);
                      itemContext.smokefrequency = e.target.value;
                    }}
                    onBlur={formik.handleBlur}
                  >
                    <Grid>
                      <FormControlLabel
                        value="diario"
                        disabled={isTobaco}
                        label={<Typography variant="body2">Diario</Typography>}
                        control={<Radio size="small" />}
                        sx={{ marginRight: "37px" }}
                      />
                      <FormControlLabel
                        value="semanal"
                        disabled={isTobaco}
                        label={<Typography variant="body2">Semanal</Typography>}
                        control={<Radio size="small" />}
                      />
                    </Grid>
                    <Grid>
                      <FormControlLabel
                        value="quincenal"
                        disabled={isTobaco}
                        label={
                          <Typography variant="body2">Quincenal</Typography>
                        }
                        control={<Radio size="small" />}
                      />
                      <FormControlLabel
                        value="mensual"
                        disabled={isTobaco}
                        label={<Typography variant="body2">Mensual</Typography>}
                        control={<Radio size="small" />}
                      />
                    </Grid>
                  </RadioGroup>
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item={true} md={12}>
            <Typography variant="subtitle2" gutterBottom>
              Enfermedades crónico-degenerativas
            </Typography>
          </Grid>
          <Grid id="grid-style-9" item={true} md={12}>
            <FormControlLabel
              control={
                <Checkbox
                  id="breastCancer"
                  checked={formik.values.breastCancer}
                  disabled={showOrEdit}
                  onChange={(e) => {
                    formik.setFieldValue("breastCancer", e.target.checked);
                    itemContext.chronicDegenerative.breastCancer =
                      e.target.checked;
                  }}
                  onBlur={formik.handleBlur}
                />
              }
              label="Cáncer de mama"
            />
            <FormControlLabel
              control={
                <Checkbox
                  id="hypertension"
                  checked={formik.values.hypertension}
                  disabled={showOrEdit}
                  onChange={(e) => {
                    formik.setFieldValue("hypertension", e.target.checked);
                    itemContext.chronicDegenerative.hypertension =
                      e.target.checked;
                  }}
                  onBlur={formik.handleBlur}
                />
              }
              label="Hipertensión"
            />
            <FormControlLabel
              control={
                <Checkbox
                  id="autoimmune"
                  checked={formik.values.autoimmune}
                  disabled={showOrEdit}
                  onChange={(e) => {
                    formik.setFieldValue("autoimmune", e.target.checked);
                    itemContext.chronicDegenerative.autoimmune =
                      e.target.checked;
                  }}
                  onBlur={formik.handleBlur}
                />
              }
              label="Enfermedad autoinmune"
            />
          </Grid>
          <Grid id="grid-style-9" item={true} md={12}>
            <FormControlLabel
              control={
                <Checkbox
                  id="ovarianCancer"
                  checked={formik.values.ovarianCancer}
                  disabled={showOrEdit}
                  onChange={(e) => {
                    formik.setFieldValue("ovarianCancer", e.target.checked);
                    itemContext.chronicDegenerative.ovarianCancer =
                      e.target.checked;
                  }}
                  onBlur={formik.handleBlur}
                />
              }
              label="Cáncer de ovario"
            />
            <FormControlLabel
              control={
                <Checkbox
                  id="thyroid"
                  checked={formik.values.thyroid}
                  disabled={showOrEdit}
                  onChange={(e) => {
                    formik.setFieldValue("thyroid", e.target.checked);
                    itemContext.chronicDegenerative.thyroid = e.target.checked;
                  }}
                  onBlur={formik.handleBlur}
                />
              }
              label="Tiroides"
              sx={{ marginRight: "50px" }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  id="lymphopro"
                  checked={formik.values.lymphopro}
                  disabled={showOrEdit}
                  onChange={(e) => {
                    formik.setFieldValue("lymphopro", e.target.checked);
                    itemContext.chronicDegenerative.lymphopro =
                      e.target.checked;
                  }}
                  onBlur={formik.handleBlur}
                />
              }
              label="Linfoproliferativas (Linfona/Leucemia)"
            />
          </Grid>
          <Grid id="grid-style-9" item={true} md={12}>
            <FormControlLabel
              control={
                <Checkbox
                  id="colonCancer"
                  checked={formik.values.colonCancer}
                  disabled={showOrEdit}
                  onChange={(e) => {
                    formik.setFieldValue("colonCancer", e.target.checked);
                    itemContext.chronicDegenerative.colonCancer =
                      e.target.checked;
                  }}
                  onBlur={formik.handleBlur}
                />
              }
              label="Cáncer de colon"
              sx={{ marginRight: "19px" }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  id="diabetes"
                  checked={formik.values.diabetes}
                  disabled={showOrEdit}
                  onChange={(e) => {
                    formik.setFieldValue("diabetes", e.target.checked);
                    itemContext.chronicDegenerative.diabetes = e.target.checked;
                  }}
                  onBlur={formik.handleBlur}
                />
              }
              label="Diabetes"
            />
          </Grid>
          <Grid item={true} md={12}>
            <Typography variant="subtitle2" gutterBottom>
              Antecedentes oncológicos heredofamiliares
            </Typography>
          </Grid>
          <Grid container md={12}>
            <Grid item={true} md={12} sx={{ marginLeft: "25px" }}>
              <FormControlLabel
                control={
                  <Typography variant="body1" gutterBottom>
                    Cáncer de mama
                  </Typography>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="breastMother"
                    checked={formik.values.breastMother}
                    disabled={showOrEdit}
                    onChange={(e) => {
                      formik.setFieldValue("breastMother", e.target.checked);
                      itemContext.breastCancer.breastMother = e.target.checked;
                    }}
                    onBlur={formik.handleBlur}
                  />
                }
                label="Madre"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="breastFather"
                    checked={formik.values.breastFather}
                    disabled={showOrEdit}
                    onChange={(e) => {
                      formik.setFieldValue("breastFather", e.target.checked);
                      itemContext.breastCancer.breastFather = e.target.checked;
                    }}
                    onBlur={formik.handleBlur}
                  />
                }
                label="Padre"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="breastSiblings"
                    checked={formik.values.breastSiblings}
                    disabled={showOrEdit}
                    onChange={(e) => {
                      formik.setFieldValue("breastSiblings", e.target.checked);
                      itemContext.breastCancer.breastSiblings =
                        e.target.checked;
                    }}
                    onBlur={formik.handleBlur}
                  />
                }
                label="Hermanos"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="breastChildren"
                    checked={formik.values.breastChildren}
                    disabled={showOrEdit}
                    onChange={(e) => {
                      formik.setFieldValue("breastChildren", e.target.checked);
                      itemContext.breastCancer.breastChildren =
                        e.target.checked;
                    }}
                    onBlur={formik.handleBlur}
                  />
                }
                label="Hijos"
              />
            </Grid>
            <Grid item={true} md={12} sx={{ marginLeft: "25px" }}>
              <FormControlLabel
                control={
                  <Typography variant="body1" gutterBottom>
                    Cáncer de colon
                  </Typography>
                }
                sx={{ marginRight: "20px" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="colonMother"
                    checked={formik.values.colonMother}
                    disabled={showOrEdit}
                    onChange={(e) => {
                      formik.setFieldValue("colonMother", e.target.checked);
                      itemContext.colonCancer.colonMother = e.target.checked;
                    }}
                    onBlur={formik.handleBlur}
                  />
                }
                label="Madre"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="colonFather"
                    checked={formik.values.colonFather}
                    disabled={showOrEdit}
                    onChange={(e) => {
                      formik.setFieldValue("colonFather", e.target.checked);
                      itemContext.colonCancer.colonFather = e.target.checked;
                    }}
                    onBlur={formik.handleBlur}
                  />
                }
                label="Padre"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="colonSiblings"
                    checked={formik.values.colonSiblings}
                    disabled={showOrEdit}
                    onChange={(e) => {
                      formik.setFieldValue("colonSiblings", e.target.checked);
                      itemContext.colonCancer.colonSiblings = e.target.checked;
                    }}
                    onBlur={formik.handleBlur}
                  />
                }
                label="Hermanos"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="colonChildren"
                    checked={formik.values.colonChildren}
                    disabled={showOrEdit}
                    onChange={(e) => {
                      formik.setFieldValue("colonChildren", e.target.checked);
                      itemContext.colonCancer.colonChildren = e.target.checked;
                    }}
                    onBlur={formik.handleBlur}
                  />
                }
                label="Hijos"
              />
            </Grid>
            <Grid item={true} md={12} sx={{ marginLeft: "25px" }}>
              <FormControlLabel
                control={
                  <Typography variant="body1" gutterBottom>
                    Cáncer de ovario
                  </Typography>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="ovarianMother"
                    checked={formik.values.ovarianMother}
                    disabled={showOrEdit}
                    onChange={(e) => {
                      formik.setFieldValue("ovarianMother", e.target.checked);
                      itemContext.ovarianCancer.ovarianMother =
                        e.target.checked;
                    }}
                    onBlur={formik.handleBlur}
                  />
                }
                label="Madre"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="ovarianFather"
                    checked={formik.values.ovarianFather}
                    disabled={showOrEdit}
                    onChange={(e) => {
                      formik.setFieldValue("ovarianFather", e.target.checked);
                      itemContext.ovarianCancer.ovarianFather =
                        e.target.checked;
                    }}
                    onBlur={formik.handleBlur}
                  />
                }
                label="Padre"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="ovarianSiblings"
                    checked={formik.values.ovarianSiblings}
                    disabled={showOrEdit}
                    onChange={(e) => {
                      formik.setFieldValue("ovarianSiblings", e.target.checked);
                      itemContext.ovarianCancer.ovarianSiblings =
                        e.target.checked;
                    }}
                    onBlur={formik.handleBlur}
                  />
                }
                label="Hermanos"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="ovarianChildren"
                    checked={formik.values.ovarianChildren}
                    disabled={showOrEdit}
                    onChange={(e) => {
                      formik.setFieldValue("ovarianChildren", e.target.checked);
                      itemContext.ovarianCancer.ovarianChildren =
                        e.target.checked;
                    }}
                    onBlur={formik.handleBlur}
                  />
                }
                label="Hijos"
              />
            </Grid>
          </Grid>
          <Grid item={true} md={12}>
            <Typography variant="subtitle2" gutterBottom>
              Antecedentes oncológicos heredofamiliares
            </Typography>
          </Grid>
          <Grid id="grid-style-9" item={true} md={12}>
            <FormControlLabel
              control={
                <Checkbox
                  id="uterus"
                  checked={formik.values.uterus}
                  disabled={showOrEdit}
                  onChange={(e) => {
                    formik.setFieldValue("uterus", e.target.checked);
                    itemContext.oncologicalHistory.uterus = e.target.checked;
                  }}
                  onBlur={formik.handleBlur}
                />
              }
              label="Útero"
            />
            <FormControlLabel
              control={
                <Checkbox
                  id="ovary"
                  checked={formik.values.ovary}
                  disabled={showOrEdit}
                  onChange={(e) => {
                    formik.setFieldValue("ovary", e.target.checked);
                    itemContext.oncologicalHistory.ovary = e.target.checked;
                  }}
                  onBlur={formik.handleBlur}
                />
              }
              label="Ovario"
            />
            <FormControlLabel
              control={
                <Checkbox
                  id="endometrium"
                  checked={formik.values.endometrium}
                  disabled={showOrEdit}
                  onChange={(e) => {
                    formik.setFieldValue("endometrium", e.target.checked);
                    itemContext.oncologicalHistory.endometrium =
                      e.target.checked;
                  }}
                  onBlur={formik.handleBlur}
                />
              }
              label="Endométrio"
            />
            <FormControlLabel
              control={
                <Checkbox
                  id="otherCheck"
                  checked={formik.values.otherCheck}
                  disabled={showOrEdit}
                  onChange={(e) => {
                    formik.setFieldValue("otherCheck", e.target.checked);
                    itemContext.oncologicalHistory.otherCheck =
                      e.target.checked;
                  }}
                  onBlur={formik.handleBlur}
                />
              }
              label="Otra"
            />
            <FormControlLabel
              control={
                <TextField
                  id="otherInput"
                  value={formik.values.otherInput}
                  disabled={!formik.values.otherCheck || showOrEdit}
                  onChange={(e) => {
                    formik.setFieldValue("otherInput", e.target.value);
                    itemContext.oncologicalHistory.otherInput = e.target.value;
                  }}
                  onBlur={formik.handleBlur}
                  size="small"
                  sx={{ width: "250px" }}
                />
              }
            />
          </Grid>
        </Grid>

        <Grid
          container
          sx={{ marginTop: "30px", paddingLeft: "24px", paddingRight: "24px", justifyContent: "center" }}
        >
          <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
            <Button
              id="button-none-transform-color-white"
              color="secondary"
              type="submit"
              variant="contained"
              disabled={showOrEdit}
              fullWidth
            >
              Siguiente
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default SheetFormTab1;
