import { Container } from "@mui/material";
import CommonUsersEvents from "./common/CommonUsersEvents";

const UsersPage = () => {
  return (
    <div style={{ paddingTop: "4rem" }}>
      <Container maxWidth="xxl">
        <CommonUsersEvents />
      </Container>
    </div>
  );
};

export default UsersPage;