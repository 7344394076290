import React, { useEffect, useState } from "react";
import { Button, MenuItem, Menu } from "@mui/material";
import FILTERVALUES from "../../assets/helpers/dummyResumeFilterValues";
import theme from "../../assets/theme";

const ResumeCardSelectFilter = ({ onSelect }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState(FILTERVALUES.MONTH);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    onSelect("month");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Button
        id="basic-button"
        style={{
          boxShadow: "none",
          textTransform: "none",
          fontSize: 14,
          borderStyle: "none",
          fontFamily: "Helvetica",
          fontWeight: "bold",
          color: theme.palette.secondary.main,
          backgroundColor: theme.palette.blue.one,
        }}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {selectedOption.name}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {Object.keys(FILTERVALUES).map((s) => (
          <MenuItem
            value={FILTERVALUES[s].value}
            style={{
              fontSize: "14px",
            }}
            onClick={() => {
              onSelect(FILTERVALUES[s].value);
              setSelectedOption(FILTERVALUES[s]);
              setAnchorEl(null);
            }}
          >
            {FILTERVALUES[s].name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default ResumeCardSelectFilter;
