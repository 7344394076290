import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Logo from "../../assets/imagesDefault";

export const ImageWomanLogin = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <div
      style={{
        height: "100%",
        position: "relative",
      }}
    >
      <img
        height={"100%"}
        src={Logo.WomanXmi}
        alt="woman"
        style={{
          display: matches ? "flex" : "none",
          position: "absolute",
          bottom: 0,
          left: "1rem",
          right: 0,
          top: "1rem",
        }}
      />
    </div>
  );
}