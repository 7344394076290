import React, { useState } from "react";
import { Paper, Skeleton, Avatar, Grid } from "@mui/material";
import ResumeCardSelectFilter from "./ResumeCardSelectFilter";
import { ArrowOutward } from "@mui/icons-material";

const ResumeCard = ({
  widgetLeft,
  title,
  subTitle,
  percentAmount,
  loading,
  error,
  onSelect,
}) => {
  const [percentText, setPercentText] = useState("");

  const onSelectFilter = (type) => {
    onSelect(type);
    switch (type) {
      case "year":
        setPercentText("Del año anterior");
        break;
      case "month":
        setPercentText("Del mes anterior");
        break;
      case "daily":
        setPercentText("Del día anterior");
        break;
      default:
        setPercentText("");
        break;
    }
  };

  return (
    <div id="div-style-16" style={{ paddingBottom: "14px" }}>
      <Paper id="paper-style-3" style={{ height: "100%" }}>
        {loading ? (
          <>
            <Grid container>
              <Grid item xl={9} lg={8} md={9} sm={9} xs={9}>
                <Grid container columns={12}>
                  <Grid item xl={4} lg={12} md={4} sm={4} xs={4}>
                    <div id="div-style-10">
                      <Skeleton variant="circular">
                        <div id="div-style-10">{widgetLeft}</div>
                      </Skeleton>
                    </div>
                  </Grid>

                  <Grid item xl={8} lg={8} md={8} sm={8} xs={8}>
                    <div id="div-style-11">
                      <Skeleton variant="rectangular">
                        <div id="div-style-12">{title}</div>
                      </Skeleton>
                      <Skeleton
                        variant="rectangular"
                        style={{ marginTop: "10px" }}
                      >
                        <div id="div-style-13">{subTitle}</div>
                      </Skeleton>
                      <Skeleton
                        variant="rectangular"
                        style={{ marginTop: "10px" }}
                      >
                        <div id="div-style-15">{percentAmount}</div>
                      </Skeleton>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xl={3} lg={4} md={3} sm={3} xs={3}>
                <div style={{ textAlign: "right" }}>
                  <Skeleton variant="rectangular">
                    <div id="div-style-12">{percentAmount}</div>
                  </Skeleton>
                </div>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            {error ? (
              <Grid container>
                <Grid item xl={9} lg={8} md={9} sm={9} xs={9}>
                  <Grid container columns={12}>
                    <Grid item xl={4} lg={12} md={4} sm={4} xs={4}>
                      <div id="div-style-10">
                        <Avatar src={null} id="avatar-style-1">
                          {""}
                        </Avatar>
                      </div>
                    </Grid>

                    <Grid item xl={8} lg={8} md={8} sm={8} xs={8}>
                      <div id="div-style-11">
                        <div id="div-style-12">{title}</div>
                        <div id="div-style-13">.</div>
                        <div id="div-style-15">.</div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xl={3} lg={4} md={3} sm={3} xs={3}>
                  <div style={{ textAlign: "right" }}>
                    <ResumeCardSelectFilter onSelect={onSelectFilter} />
                  </div>
                </Grid>
              </Grid>
            ) : (
              <Grid container>
                <Grid item xl={9} lg={8} md={9} sm={9} xs={9}>
                  <Grid container columns={12}>
                    <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                      <div id="div-style-10">{widgetLeft}</div>
                    </Grid>

                    <Grid item xl={8} lg={8} md={8} sm={8} xs={8}>
                      <div id="div-style-11">
                        <div id="div-style-12">{title}</div>
                        <div id="div-style-13">{subTitle}</div>
                        <div id="div-style-45">
                          <div
                            id={
                              percentAmount < 0
                                ? "div-style-percent-negative"
                                : "div-style-14"
                            }
                          >
                            {percentAmount}
                          </div>
                          <ArrowOutward
                            id={
                              percentAmount < 0
                                ? "icon-style-negative"
                                : "icon-style-4"
                            }
                          />
                          <div id="div-style-15">{percentText}</div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xl={3} lg={4} md={3} sm={3} xs={3}>
                  <div style={{ textAlign: "right" }}>
                    <ResumeCardSelectFilter onSelect={onSelectFilter} />
                  </div>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Paper>
    </div>
  );
};

export default ResumeCard;
