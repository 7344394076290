import React, { useEffect, useState } from "react";
import { Paper, Button, Skeleton, Grid } from "@mui/material";
import { BarChart } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import ReactEcharts from "echarts-for-react";
import theme from "../../assets/theme";
import ResumeCardSelectFilter from "./ResumeCardSelectFilter";

const ViewMoreButton = styled(Button)({
  boxShadow: "0px 3px 6px #00000029",
  textTransform: "none",
  fontSize: 18,
  borderStyle: "none",
  fontFamily: "Helvetica",
  fontWeight: "bold",
  color: theme.palette.pink.default,
  backgroundColor: theme.palette.gray.one,
  paddingLeft: "67px",
  paddingRight: "67px",
  borderRadius: "5px",
});

const StatisticsByLevelAndAge = () => {
  const [seriesList, setSeriesList] = useState([]);
  const [datasetWithFilters, setDatasetWithFilters] = useState([]);
  const [loading, setLoading] = useState(true);

  const dataDb = [
    ["age", "amount", "name"],
    ["18 - 20", 12, "Grado 1"],
    ["21 - 30", 11, "Grado 1"],
    ["31 - 40", 10, "Grado 1"],
    ["41 - 50", 9, "Grado 1"],
    ["51 - 60", 0, "Grado 1"],
    ["60 +", 7, "Grado 1"],
    ["18 - 20", 9, "Grado 2"],
    ["21 - 30", 8, "Grado 2"],
    ["31 - 40", 7, "Grado 2"],
    ["41 - 50", 6, "Grado 2"],
    ["51 - 60", 5, "Grado 2"],
    ["60 +", 4, "Grado 2"],
    ["18 - 20", 5, "Grado 3"],
    ["21 - 30", 4, "Grado 3"],
    ["31 - 40", 3, "Grado 3"],
    ["41 - 50", 2, "Grado 3"],
    ["51 - 60", 1, "Grado 3"],
    ["60 +", 2, "Grado 3"],
    ["18 - 20", 3, "Grado 4"],
    ["21 - 30", 2, "Grado 4"],
    ["31 - 40", 1, "Grado 4"],
    ["41 - 50", 2, "Grado 4"],
    ["51 - 60", 3, "Grado 4"],
    ["60 +", 4, "Grado 4"],
    ["18 - 20", 1, "Grado 5"],
    ["21 - 30", 2, "Grado 5"],
    ["31 - 40", 3, "Grado 5"],
    ["41 - 50", 4, "Grado 5"],
    ["51 - 60", 5, "Grado 5"],
    ["60 +", 6, "Grado 5"],
  ];

  useEffect(() => {
    getData("month");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async (type) => {
    const newSeries = [];
    const newDatasetWithFilters = [];

    for (let q = 0; q < 5; q += 1) {
      var datasetId = "dataset_Grado " + (q + 1);
      newDatasetWithFilters.push({
        id: datasetId,
        fromDatasetId: "dataset_raw",
        transform: {
          type: "filter",
          config: {
            and: [{ dimension: "name", "=": "Grado " + (q + 1) }],
          },
        },
      });

      newSeries.push({
        type: "line",
        datasetId: datasetId,
        showSymbol: false,
        name: "Grado " + (q + 1),
        endLabel: {
          show: true,
          formatter: function (params) {
            return params.value[2] + ": " + params.value[1];
          },
        },
        labelLayout: {
          moveOverlap: "shiftY",
        },
        emphasis: {
          focus: "series",
        },
        encode: {
          x: "age",
          y: "amount",
          label: ["name", "amount"],
          itemName: "age",
          tooltip: ["amount"],
        },
      });
    }

    setSeriesList(newSeries);
    setDatasetWithFilters(newDatasetWithFilters);
    setLoading(false);
  };

  const onSelect = (type) => {
    getData(type);
  };

  return (
    <div id="div-style-16" style={{ paddingBottom: "14px" }}>
      <Paper id="paper-style-3" style={{ height: "100%" }}>
        {loading ? (
          <div
            style={{
              width: "100%",
              textAlign: "center",
              marginBottom: "10px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div id="div-style-25">
                <Skeleton variant="circular" height={"30px"} width={"30px"} />
                <Skeleton
                  variant="rectangular"
                  width={"100px"}
                  id="div-style-17"
                />
              </div>

              <Skeleton variant="rectangular" height={"40px"} width={"80px"} />
            </div>

            <div
              style={{
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "22px",
                marginBottom: "22px",
              }}
            >
              <div id="div-style-25" style={{ marginTop: "80px" }}>
                <Grid sm={12}>
                  <Skeleton variant="rectangular" height={"200px"} />
                </Grid>
              </div>
            </div>

            <div id="div-style-25" style={{ marginTop: "40px" }}>
              <Skeleton variant="rectangular" height={"48px"} width={"200px"} />
            </div>
          </div>
        ) : (
          <div id="div-style-42">
            <div id="div-style-24">
              <div id="div-style-25">
                <BarChart id="icon-style-5" />
                <div id="div-style-17">Estadísticos por grado y edad</div>
              </div>

              <ResumeCardSelectFilter onSelect={onSelect} />
            </div>

            <ReactEcharts
              option={{
                color: [
                  theme.palette.pink.default,
                  theme.palette.gray.default,
                  theme.palette.secondary.main,
                  theme.palette.gray.two,
                  theme.palette.orange.default,
                ],
                animationDuration: 2000,
                dataset: [
                  {
                    id: "dataset_raw",
                    source: dataDb,
                  },
                  ...datasetWithFilters,
                ],
                tooltip: {
                  order: "valueDesc",
                  trigger: "axis",
                },
                xAxis: {
                  type: "category",
                },
                yAxis: {},
                grid: {
                  left: "5%",
                  top: 40,
                  right: "15%",
                  bottom: 30,
                },
                series: seriesList,
              }}
            />

            <div style={{ marginTop: "22px" }}>
              <ViewMoreButton>Ver detalle</ViewMoreButton>
            </div>
          </div>
        )}
      </Paper>
    </div>
  );
};

export default StatisticsByLevelAndAge;
