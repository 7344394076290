import { Container } from "@mui/material";
import CommonSheetEvents from "./common/CommonSheetEvents";

const PatientsPage = () => {
  return (
    <div style={{ paddingTop: "4rem" }}>
      <Container maxWidth="xxl">
        <CommonSheetEvents />
      </Container>
    </div>
  );
};

export default PatientsPage;
