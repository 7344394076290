import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  InputLabel,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import SnackBarMessage from "../../components/SnackBarMessage";
import LockIcon from "@mui/icons-material/Lock";
import {
  createPermission,
  updatePermission,
} from "../repositories/PermissionRepository";

const PermissionsForm = ({
  refreshTable,
  handleEditing,
  isEditing,
  objectToEdit,
}) => {
  const [loading, setLoading] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackSeverity, setSnackSeverity] = useState("success");
  const [snackMessage, setSnackMessage] = useState("");
  const [buttonTitle, setButtonTitle] = useState("");
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      rolname: "",
      dashboard: false,
      reception: false,
      clinicalsheet: false,
      results: false,
      diagnosis: false,
      analytics: false,
      users: false,
      permissions: false,
    },
    validationSchema: Yup.object().shape({
      rolname: Yup.string().required("Campo obligatorio"),
    }),
    onSubmit: async () => {
      setLoading(true);
      if (isEditing) {
        const updateResponse = await updateNewPermission();
        if (updateResponse.status === "Ok") {
          refreshTable();
          clearFields();
          handleEditing();
          setButtonTitle("Guardar");
          setSnackSeverity("success");
          setSnackMessage(updateResponse.message);
          setSnackOpen(true);
        } else {
          setSnackSeverity("warning");
          setSnackMessage(updateResponse.message);
          setSnackOpen(true);
        }
      } else {
        const createResponse = await createNewPermission();

        if (createResponse.status === "Ok") {
          refreshTable();
          clearFields();
          setSnackSeverity("success");
          setSnackMessage(createResponse.message);
          setSnackOpen(true);
        } else {
          setSnackSeverity("warning");
          setSnackMessage(createResponse.message);
          setSnackOpen(true);
        }
      }
      setLoading(false);
    },
  });

  const createNewPermission = async () => {
    let permissionObject = {
      rolname: formik.values.rolname,
      permissionsTag: [
        formik.values.dashboard ? "Dashboard" :"",
        formik.values.reception ? "Recepción": "",
        formik.values.clinicalsheet ? "Hoja clínica": "",
        formik.values.results ? "Resultados": "",
        formik.values.diagnosis ? "Diagnósticos": "",
        formik.values.analytics ? "Analíticas": "",
        formik.values.users ? "Usuarios": "",
        formik.values.permissions ? "Permisos": "",
      ],
      permissions: [
        formik.values.dashboard ? "dashboard": "",
        formik.values.reception ? "reception": "",
        formik.values.clinicalsheet ? "clinicalsheet": "",
        formik.values.results ? "results": "",
        formik.values.diagnosis ? "diagnosis": "",
        formik.values.analytics ? "analytics": "",
        formik.values.users ? "users": "",
        formik.values.permissions ? "permissions": "",
      ],
      godmode: false,
      isZombie: false,
      tenant: "XMI",
    };
    
    const response = await createPermission(permissionObject);

    return response;
  };

  const updateNewPermission = async () => {
    let updateObject = {
      _id: objectToEdit._id,
      rolname: formik.values.rolname,
      dashboard: {
        allow: formik.values.dashboard,
      },
      health: {
        reception: formik.values.reception,
        clinicalsheet: formik.values.clinicalsheet,
        results: formik.values.results,
        diagnosis: formik.values.diagnosis,
      },
      analytics: {
        allow: formik.values.analytics,
      },
      config: {
        users: formik.values.users,
        permissions: formik.values.permissions,
      },
      godmode: false,
      isZombie: false,
      tenant: "XMI",
    };

    const response = await updatePermission(updateObject);

    return response;
  };

  const clearFields = () => {
    formik.setValues({});
    formik.resetForm();
  };

  useEffect(() => {
    if (isEditing === true) {
      const showItem = () => {
        formik.setValues({
          rolname: objectToEdit.rolname,
          dashboard: objectToEdit.dashboard.allow,
          reception: objectToEdit.health.reception,
          clinicalsheet: objectToEdit.health.clinicalsheet,
          results: objectToEdit.health.results,
          diagnosis: objectToEdit.health.diagnosis,
          analytics: objectToEdit.analytics.allow,
          users: objectToEdit.config.users,
          permissions: objectToEdit.config.permissions,
        });
      };
      setButtonTitle("Actualizar");
      showItem();
    } else {
      clearFields();
      setButtonTitle("Guardar");
    }
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing, objectToEdit]);

  return (
    <div
      style={{
        marginLeft: "20px",
        width: "100%",
      }}
    >
      <div id="div-style-8">
        <LockIcon color={"primary"} fontSize="medium" id="icon-style-unlock" />
        <h4 id="h4-style-permissions">Permisos otorgados</h4>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <Grid containerF>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <InputLabel shrink>Nombre de rol</InputLabel>
            <TextField
              id="rolname"
              size="small"
              onChange={formik.handleChange}
              value={formik.values.rolname}
              onBlur={formik.handleBlur}
              autoComplete="off"
            />
            {Boolean(formik.errors.rolname && formik.touched.rolname) && (
              <div id="div-style-input-error">{formik.errors.rolname}</div>
            )}
          </Grid>

          <Grid item xs={12} style={{ marginTop: "15px" }}>
            <Typography variant="subtitle2" gutterBottom>
              Módulos permitidos
            </Typography>
          </Grid>
          <Grid item={true} md={12} sx={{ marginLeft: "25px" }}>
            <FormControlLabel
              control={
                <Typography variant="body1" gutterBottom>
                  Dashboard:
                </Typography>
              }
            />
            <Grid>
              <FormControlLabel
                control={
                  <Checkbox
                    id="dashboard"
                    checked={formik.values.dashboard}
                    onChange={(e) => {
                      formik.setFieldValue("dashboard", e.target.checked);
                    }}
                    onBlur={formik.handleBlur}
                  />
                }
                label="Permitir"
              />
            </Grid>
          </Grid>
          <Grid item={true} md={12} sx={{ marginLeft: "25px" }}>
            <FormControlLabel
              control={
                <Typography variant="body1" gutterBottom>
                  Salud:
                </Typography>
              }
            />
            <Grid>
              <FormControlLabel
                control={
                  <Checkbox
                    id="reception"
                    checked={formik.values.reception}
                    onChange={(e) => {
                      formik.setFieldValue("reception", e.target.checked);
                    }}
                    onBlur={formik.handleBlur}
                  />
                }
                label="Recepción"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="clinicalsheet"
                    checked={formik.values.clinicalsheet}
                    onChange={(e) => {
                      formik.setFieldValue("clinicalsheet", e.target.checked);
                    }}
                    onBlur={formik.handleBlur}
                  />
                }
                label="Hoja clínica"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="results"
                    checked={formik.values.results}
                    onChange={(e) => {
                      formik.setFieldValue("results", e.target.checked);
                    }}
                    onBlur={formik.handleBlur}
                  />
                }
                label="Resultados"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="diagnosis"
                    checked={formik.values.diagnosis}
                    onChange={(e) => {
                      formik.setFieldValue("diagnosis", e.target.checked);
                    }}
                    onBlur={formik.handleBlur}
                  />
                }
                label="Diagnósticos"
              />
            </Grid>
          </Grid>
          <Grid item={true} md={12} sx={{ marginLeft: "25px" }}>
            <FormControlLabel
              control={
                <Typography variant="body1" gutterBottom>
                  Analíticas:
                </Typography>
              }
            />
            <Grid>
              <FormControlLabel
                control={
                  <Checkbox
                    id="analytics"
                    checked={formik.values.analytics}
                    onChange={(e) => {
                      formik.setFieldValue("analytics", e.target.checked);
                    }}
                    onBlur={formik.handleBlur}
                  />
                }
                label="Permitir"
              />
            </Grid>
          </Grid>
          <Grid item={true} md={12} sx={{ marginLeft: "25px" }}>
            <FormControlLabel
              control={
                <Typography variant="body1" gutterBottom>
                  Configuración:
                </Typography>
              }
            />
            <Grid>
              <FormControlLabel
                control={
                  <Checkbox
                    id="users"
                    checked={formik.values.users}
                    onChange={(e) => {
                      formik.setFieldValue("users", e.target.checked);
                    }}
                    onBlur={formik.handleBlur}
                  />
                }
                label="Usuarios"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="permissions"
                    checked={formik.values.permissions}
                    onChange={(e) => {
                      formik.setFieldValue("permissions", e.target.checked);
                    }}
                    onBlur={formik.handleBlur}
                  />
                }
                label="Permisos"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            marginTop: "30px",
            paddingLeft: "24px",
            paddingRight: "24px",
            justifyContent: "center",
          }}
        >
          <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
            <Button
              id="button-none-transform-color-white"
              color="secondary"
              type="submit"
              variant="contained"
              fullWidth
            >
              {buttonTitle}
            </Button>
          </Grid>
        </Grid>

        <SnackBarMessage
          severity={snackSeverity}
          message={snackMessage}
          open={snackOpen}
          onClose={() => {
            setSnackOpen(false);
          }}
        />
      </form>
    </div>
  );
};

export default PermissionsForm;
