import React, { useEffect, useState } from "react";
import moment from "moment";
import { debounce } from "lodash";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  createDate,
  searchPatient,
  updateEvent,
} from "../repositories/ReceptionRepository";
import SnackBarMessage from "../../components/SnackBarMessage";
import theme from "../../assets/theme";
import { reasonsData, studyTypeData } from "../../assets/helpers/selectData";

const ReceptionForm = ({
  itemDate,
  returnNewDate,
  viewExpedient,
  foundationList,
}) => {
  const [loading, setLoading] = useState(false);
  const now = new Date();
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackSeverity, setSnackSeverity] = useState("success");
  const [snackMessage, setSnackMessage] = useState("");
  const [patientList, setPatientList] = useState([]);
  const [loadingSearchPatient, setLoadingSearchPatient] = useState(false);
  const [openSearchInput, setOpenSearchInput] = useState(false);
  const [patientFound, setPatientFound] = useState({ label: "found" });
  const [patientTextfieldValue, setPatientTextfieldValue] = useState({
    label: "",
  });
  const [foundationSelected, setFoundationSelected] = useState(null);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      lastName: "",
      email: "",
      phone: "",
      studyDate: "",
      reason: reasonsData[0].reason,
      studyType: studyTypeData[0].name,
      referred: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Correo obligatorio"),
      lastName: Yup.string().required("Campo obligatorio"),
      email: Yup.string()
        .email("Formato incorrecto")
        .required("Campo obligatorio"),
      phone: Yup.string()
        .required("Campo obligatorio")
        .min(10, "10 caracteres")
        .max(10, "10 caracteres"),
      studyDate: Yup.string().required("Campo obligatorio"),
    }),
    onSubmit: async () => {
      setLoading(true);
      const receptionExist = itemDate.receptionData?._id || null;

      if (itemDate.receptionData !== null && receptionExist !== null) {
        let updatePatientId = "";

        if (itemDate.receptionData.patientData) {
          updatePatientId = itemDate.receptionData.patientData._id;
        }

        const response = await updateEvent(
          moment(formik.values.studyDate).format(
            moment.HTML5_FMT.DATETIME_LOCAL
          ),
          formik.values.name,
          formik.values.lastName,
          formik.values.email,
          formik.values.phone,
          itemDate.receptionData?._id,
          updatePatientId,
          formik.values.reason,
          formik.values.studyType,
          foundationSelected,
          formik.values.referred
        );
        setLoading(false);

        if (response) {
          if (response.status === "Ok") {
            const updateItemResponse = response.content;
            updateItemResponse.id = itemDate.id;

            returnNewDate(updateItemResponse);
          } else {
            setSnackSeverity("warning");
            setSnackMessage(response.message);
            setSnackOpen(true);
          }
        }
      } else {
        let patientId = "";

        if (patientFound.data) {
          patientId = patientFound.data._id;
        }

        const response = await createDate(
          moment(formik.values.studyDate).format(
            moment.HTML5_FMT.DATETIME_LOCAL
          ),
          formik.values.name,
          formik.values.lastName,
          formik.values.email,
          formik.values.phone,
          patientId,
          formik.values.reason,
          formik.values.studyType,
          foundationSelected,
          formik.values.referred
        );
        setLoading(false);

        if (response) {
          if (response.status === "Ok") {
            returnNewDate(response.content);
          } else {
            setSnackSeverity("warning");
            setSnackMessage(response.message);
            setSnackOpen(true);
          }
        }
      }
    },
  });

  useEffect(() => {
    formik.setValues({});
    formik.resetForm();

    if (itemDate !== null) {
      formik.setValues({
        name: itemDate.receptionData?.patientData.name || null,
        lastName: itemDate.receptionData?.patientData.lastname || null,
        email: itemDate.receptionData?.patientData.email || null,
        phone: itemDate.receptionData?.patientData.phone || null,
        studyDate: moment(itemDate.start)._d,
        reason: itemDate.receptionData?.reason || reasonsData[0].reason,
        studyType: itemDate.receptionData?.studyType || studyTypeData[0].name,
        referred: itemDate.receptionData?.foundationName ?? "",
      });

      const existReferred = itemDate.receptionData?.foundationName;

      if (existReferred) {
        for (let q = 0; q < foundationList.length; q += 1) {
          const itemQ = foundationList[q];

          if (itemQ.name === itemDate.receptionData.foundationName ?? "") {
            setFoundationSelected({
              _id: itemQ._id,
              name: itemQ.name,
            });

            q = foundationList.length;
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemDate]);

  const delayedHandleSearch = debounce(async (search) => {
    if (search !== "") {
      setLoadingSearchPatient(true);
      const response = await searchPatient(search);
      const formatList = [];

      for (let q = 0; q < response.content.length; q += 1) {
        const itemQ = response.content[q];

        formatList.push({
          label: itemQ.name + " " + itemQ.lastname,
          data: itemQ,
        });
      }

      setLoadingSearchPatient(false);
      setPatientList(formatList);
    } else {
      setLoadingSearchPatient(false);
      setPatientList([]);
    }
  }, 800);

  const onSelectPatient = (value) => {
    setPatientFound(value);
    setPatientTextfieldValue(value);
    formik.setFieldValue("name", value.data.name);
    formik.setFieldValue("lastName", value.data.lastname);
    formik.setFieldValue("email", value.data.email);
    formik.setFieldValue("phone", value.data.phone);
  };

  const validateOnlyNumber = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      formik.setFieldValue("phone", e.target.value);
    } else {
      formik.setFieldValue("phone", "");
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid
        container
        spacing={1}
        style={{
          marginTop: "16px",
          paddingLeft: "12px",
          paddingRight: "12px",
        }}
      >
        {!itemDate.receptionData && (
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ marginTop: "8px" }}
          >
            <InputLabel shrink>Buscar paciente</InputLabel>
            <Autocomplete
              disablePortal
              size="small"
              fullWidth
              value={patientTextfieldValue}
              id="free-solo-2-demo"
              noOptionsText={"Sin resultados"}
              open={openSearchInput}
              onOpen={() => {
                setOpenSearchInput(true);
              }}
              onClose={() => {
                setOpenSearchInput(false);
              }}
              options={patientList}
              inputProps={{ style: { fontSize: 14 } }}
              onChange={(event, value) => {
                if (openSearchInput) {
                  if (value) onSelectPatient(value);
                }
              }}
              onSelect={(event) => {
                if (
                  openSearchInput &&
                  patientFound.label !== patientTextfieldValue.label
                ) {
                  delayedHandleSearch(event.target.value.toLowerCase());
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label=""
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loadingSearchPatient ? (
                          <CircularProgress
                            size={16}
                            style={{ color: theme.palette.secondary.main }}
                          />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
        )}

        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={6}
          xs={12}
          style={{ marginTop: "8px" }}
        >
          <InputLabel shrink>Nombres</InputLabel>
          <TextField
            id="name"
            size="small"
            fullWidth
            autoComplete="off"
            onChange={formik.handleChange}
            value={formik.values.name}
            onBlur={formik.handleBlur}
            inputProps={{ style: { fontSize: 14 } }}
            disabled={viewExpedient}
          />
          {Boolean(formik.errors.name && formik.touched.name) && (
            <div id="div-style-input-error">{formik.errors.name}</div>
          )}
        </Grid>

        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={6}
          xs={12}
          style={{ marginTop: "8px" }}
        >
          <InputLabel shrink>Apellidos</InputLabel>
          <TextField
            id="lastName"
            size="small"
            fullWidth
            autoComplete="off"
            onChange={formik.handleChange}
            value={formik.values.lastName}
            onBlur={formik.handleBlur}
            inputProps={{ style: { fontSize: 14 } }}
            disabled={viewExpedient}
          />
          {Boolean(formik.errors.lastName && formik.touched.lastName) && (
            <div id="div-style-input-error">{formik.errors.lastName}</div>
          )}
        </Grid>

        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={6}
          xs={12}
          style={{ marginTop: "8px" }}
        >
          <InputLabel shrink>Correo electrónico</InputLabel>
          <TextField
            id="email"
            size="small"
            fullWidth
            autoComplete="off"
            onChange={formik.handleChange}
            value={formik.values.email}
            onBlur={formik.handleBlur}
            inputProps={{ style: { fontSize: 14 } }}
            disabled={viewExpedient}
          />
          {Boolean(formik.errors.email && formik.touched.email) && (
            <div id="div-style-input-error">{formik.errors.email}</div>
          )}
        </Grid>

        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={6}
          xs={12}
          style={{ marginTop: "8px" }}
        >
          <InputLabel shrink>Teléfono</InputLabel>
          <TextField
            id="phone"
            size="small"
            fullWidth
            autoComplete="off"
            onChange={(e) => validateOnlyNumber(e)}
            value={formik.values.phone}
            onBlur={(e) => validateOnlyNumber(e)}
            inputProps={{ style: { fontSize: 14 } }}
            disabled={viewExpedient}
          />
          {Boolean(formik.errors.phone && formik.touched.phone) && (
            <div id="div-style-input-error">{formik.errors.phone}</div>
          )}
        </Grid>

        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={6}
          xs={12}
          style={{ marginTop: "8px" }}
        >
          <InputLabel shrink>Día</InputLabel>
          <TextField
            id="studyDate"
            size="small"
            type="datetime-local"
            fullWidth
            autoComplete="off"
            onChange={(e) => {
              if (new Date(e.target.value) > now) {
                formik.setFieldValue("studyDate", e.target.value);
              } else {
                setSnackSeverity("warning");
                setSnackMessage("No se pueden hacer citas con fechas pasadas.");
                setSnackOpen(true);
              }
            }}
            value={moment(formik.values.studyDate).format(
              moment.HTML5_FMT.DATETIME_LOCAL
            )}
            onBlur={formik.handleBlur}
            inputProps={{
              style: { fontSize: 14 },
            }}
            disabled={viewExpedient}
          />
          {Boolean(formik.errors.studyDate && formik.touched.studyDate) && (
            <div id="div-style-input-error">{formik.errors.studyDate}</div>
          )}
        </Grid>

        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={6}
          xs={12}
          style={{ marginTop: "8px" }}
        >
          <InputLabel shrink>Motivo de consulta</InputLabel>
          <TextField
            select
            defaultValue=""
            id="reason"
            size="small"
            onChange={(e) => {
              formik.setFieldValue("reason", e.target.value);
              formik.values.reason = e.target.value;
            }}
            value={formik.values.reason}
            onBlur={formik.handleBlur}
            disabled={viewExpedient}
          >
            {reasonsData.map((reason) => (
              <MenuItem key={reason.id} value={reason.reason}>
                {reason.reason}
              </MenuItem>
            ))}
          </TextField>
          {Boolean(formik.errors.reason && formik.touched.reason) && (
            <div id="div-style-input-error">{formik.errors.reason}</div>
          )}
        </Grid>

        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={6}
          xs={12}
          style={{ marginTop: "8px" }}
        >
          <InputLabel shrink>Tipo de estudio</InputLabel>
          <TextField
            select
            defaultValue=""
            id="studyType"
            size="small"
            onChange={(e) => {
              formik.setFieldValue("studyType", e.target.value);
              formik.values.studyType = e.target.value;
            }}
            value={formik.values.studyType}
            onBlur={formik.handleBlur}
            disabled={viewExpedient}
          >
            {studyTypeData.map((studyItem) => (
              <MenuItem key={studyItem.id} value={studyItem.name}>
                {studyItem.name}
              </MenuItem>
            ))}
          </TextField>
          {Boolean(formik.errors.studyType && formik.touched.studyType) && (
            <div id="div-style-input-error">{formik.errors.studyType}</div>
          )}
        </Grid>

        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={6}
          xs={12}
          style={{ marginTop: "8px" }}
        >
          <InputLabel shrink>Referido de:</InputLabel>
          <TextField
            select
            id="referred"
            size="small"
            onChange={(e) => {
              const value = e.target.value;

              for (let q = 0; q < foundationList.length; q += 1) {
                const itemQ = foundationList[q];

                if (itemQ.name === value) {
                  setFoundationSelected({
                    _id: itemQ._id,
                    name: itemQ.name,
                  });
                  formik.setFieldValue("referredId", itemQ._id);
                  formik.setFieldValue("referred", value);

                  q = foundationList.length;
                }
              }
            }}
            value={formik.values.referred}
            onBlur={formik.handleBlur}
            disabled={viewExpedient}
          >
            {foundationList.map((referred) => (
              <MenuItem key={referred.id} value={referred.name}>
                {referred.name}
              </MenuItem>
            ))}
          </TextField>
          {Boolean(formik.errors.referred && formik.touched.referred) && (
            <div id="div-style-input-error">{formik.errors.referred}</div>
          )}
        </Grid>
      </Grid>

      <div
        style={{
          marginTop: "100px",
          marginBottom: "24px",
          textAlign: "center",
        }}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <div>
            {viewExpedient === false && (
              <div
                style={{
                  paddingLeft: "30%",
                  paddingRight: "30%",
                }}
              >
                <Button
                  id="button-style-save-reception-form"
                  sise="small"
                  type="submit"
                  variant="contained"
                  fullWidth
                >
                  Guardar
                </Button>
              </div>
            )}
          </div>
        )}
      </div>

      <SnackBarMessage
        severity={snackSeverity}
        message={snackMessage}
        open={snackOpen}
        onClose={() => {
          setSnackOpen(false);
        }}
      />
    </form>
  );
};

export default ReceptionForm;
