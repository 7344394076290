import React, { PureComponent } from "react";
import { IconButton } from "@mui/material";

import {
  FastForward,
  FastRewind,
  SkipNext,
  SkipPrevious,
} from "@mui/icons-material";

class CinePlayer extends PureComponent {
  firstframe = () => {
    this.props.runCinePlayer("firstframe");
  };

  previousframe = () => {
    this.props.runCinePlayer("previousframe");
  };

  play = () => {
    this.props.runCinePlayer("play");
  };

  pause = () => {
    this.props.runCinePlayer("pause");
  };

  nextframe = () => {
    this.props.runCinePlayer("nextframe");
  };

  lastframe = () => {
    this.props.runCinePlayer("lastframe");
  };

  render() {
    return (
      <div style={{ width: 240, margin: "0 auto" }}>
        <IconButton color="primary" onClick={this.firstframe}>
          <FastRewind />
        </IconButton>
        <IconButton
          color="primary"
          onClick={this.previousframe}
          style={{ marginRight: "8px" }}
        >
          <SkipPrevious />
        </IconButton>
        <IconButton
          color="primary"
          onClick={this.nextframe}
          style={{ marginLeft: "8px" }}
        >
          <SkipNext />
        </IconButton>
        <IconButton color="primary" onClick={this.lastframe}>
          <FastForward />
        </IconButton>
      </div>
    );
  }
}

export default CinePlayer;
