import React, { PureComponent } from "react";
import moment from "moment";
import {
  CardActions,
  Fade,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  tableCellClasses,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { InsertDriveFile, Visibility } from "@mui/icons-material";
import { connect } from "react-redux";
import DicomViewer from "../components/DicomViewer";
import { isMobile } from "react-device-detect";

import "../../pages/components/DicomViewerPage.css";
import {
  clearStore,
  localFileStore,
  dcmIsOpen,
  activeDcm,
  activeDcmIndex,
  activeMeasurements,
  setLayout,
  dcmTool,
  setDicomdir,
  setZippedFile,
  setVolume,
  filesStore,
  explorerActiveSeriesIndex,
} from "../actions/index";

import {
  log,
  getDicomStudyId,
  getDicomIpp,
  groupBy,
  objectIsEmpty,
} from "../functions";
import OpenMultipleFilesDlg from "./OpenMultipleFilesDlg";
import axios from "axios";

log();

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#EC7BA1",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  " &:first-child tr, &:first-child": {
    borderTopLeftRadius: "50px",
  },
  " &:first-child tr, &:last-child": {
    borderTopRightRadius: "50px",
  },
  " &:last-child tr, &:first-child": {
    borderBottomLeftRadius: "50px",
  },
  " &:last-child tr, &:last-child": {
    borderBottomRightRadius: "50px",
  },
}));

class StudyImagesCompare extends PureComponent {
  constructor(props) {
    super(props);
    this.files = [];
    this.folder = null;
    this.file = null;
    this.url = null;
    this.explorer = null;
    this.series = null;

    this.mprData = {};
    this.mprPlane = "";
    this.echoNumber = 0;

    this.volume = [];

    this.fileOpen = React.createRef();
    this.showFileOpen = this.showFileOpen.bind(this);

    this.openDicomdir = React.createRef();
    this.showOpenDicomdir = this.showOpenDicomdir.bind(this);

    this.openFolder = React.createRef();
    this.showOpenFolder = this.showOpenFolder.bind(this);

    this.openUrlField = React.createRef();

    this.dicomViewersActive = [];
    this.dicomViewersActiveSameStudy = [];
    this.dicomViewersActiveSamePlane = [];
    this.dicomViewersRefs = [];
    this.dicomViewers = [];
    for (let i = 0; i < 16; i++) {
      this.dicomViewers.push(this.setDcmViewer(i));
    }

    this.activeDcmViewersNum = 0;

    this.referenceLines = {};
  }

  state = {
    anchorElLayout: null,
    anchorElToolsPanel: null,
    openMenu: false,
    openImageEdit: false,
    openTools: false,
    mprMenu: false,
    mprMode: false,
    textMessage: "",
    titleMessage: "",
    visibleMainMenu: true,
    visibleHeader: false,
    visibleSettings: false,
    visibleToolbar: true,
    visibleOpenUrl: false,
    visibleToolbox: false,
    visibleTools: false,
    visibleMeasure: false,
    visibleClearMeasureDlg: false,
    visibleAbout: false,
    visibleDicomdir: false,
    visibleFileManager: false,
    visibleZippedFileDlg: false,
    visibleDownloadZipDlg: false,
    visibleOpenMultipleFilesDlg: false,
    toolState: 1,
    toolActive: "notool",
    sliceIndex: 0,
    sliceMax: 1,
    listOpenFilesScrolling: false,
    visibleVolumeBuilding: false,
    visibleMpr3D: false,
    visibleMprOrthogonal: false,
    visibleMprCoronal: false,
    visibleMprSagittal: false,
    visibleMprAxial: false,
    visibleExplorer: false,
    visibleMessage: false,
    visibleReferenceLines: true,
    visibleSeriesLink: true,
    page: 0,
    rowsPerPage: 5,
    emptyRows: 0,
    sampleIndex: 0,
    selectedResult: null,
    selectAnoteherResult: false,
    fileNameShowing: "",
  };

  setDcmViewer = (index) => {
    return (
      <DicomViewer
        dcmRef={(ref) => {
          this.dicomViewersRefs[index] = ref;
        }}
        index={index}
        dicomViewersRefs={this.dicomViewersRef}
        runTool={(ref) => (this.runTool = ref)}
        changeTool={(ref) => (this.changeTool = ref)}
        onLoadedImage={this.onLoadedImage}
        onRenderedImage={this.onRenderedImage}
        listOpenFilesPreviousFrame={this.listOpenFilesPreviousFrame}
        listOpenFilesNextFrame={this.listOpenFilesNextFrame}
        overlay={true}
        visible={true}
        onLoadFileInfo={this.onLoadFileInfo}
      />
    );
  };

  onLoadFileInfo = (value) => {};

  onLoadedImage = () => {};

  onRenderedImage = (index) => {
    if (
      this.referenceLines.crossViewer !== undefined &&
      this.state.visibleReferenceLines &&
      this.isSliceChange &&
      this.referenceLines.crossViewer.layoutIndex === index
    ) {
      this.isSliceChange = false;
      this.referenceLinesShow();
    }
  };

  getDcmViewerRef = (index) => {
    return this.dicomViewersRefs[index];
  };

  getDcmViewer = (index) => {
    return this.dicomViewers[index];
  };

  showFileOpen() {
    this.props.isOpenStore(false);
    this.fileOpen.current.click();
  }

  handleOpenLocalFs = (filesSelected) => {
    const file = filesSelected[0];

    this.setState({ sliceIndex: 0, sliceMax: 1 });
    this.props.setLocalFileStore(file);
    this.dicomViewersRefs[this.props.activeDcmIndex].runTool("clear");
    this.dicomViewersRefs[this.props.activeDcmIndex].runTool(
      "openLocalFs",
      file
    );
    this.dicomViewersRefs[this.props.activeDcmIndex].runTool("cine");
  };

  handleOpenImage = (index) => {
    this.dicomViewersRefs[this.props.activeDcmIndex].sliceIndex = index;

    const visibleMprOrthogonal = this.state.visibleMprOrthogonal;
    const visibleMprSagittal = this.state.visibleMprSagittal;
    const visibleMprCoronal = this.state.visibleMprCoronal;
    const visibleMprAxial = this.state.visibleMprAxial;
    const plane = this.mprPlanePosition(); // plane of source

    if (visibleMprOrthogonal) {
      if (this.props.activeDcmIndex === 0) {
        this.dicomViewersRefs[0].runTool("openimage", index);
        if (this.state.mprMode) {
          this.dicomViewersRefs[1].mprReferenceLines(index);
          this.dicomViewersRefs[2].mprReferenceLines(index);
        }
      } else if (this.props.activeDcmIndex === 1) {
        this.dicomViewersRefs[1].mprRenderXZPlane(
          this.dicomViewersRefs[0].filename,
          plane,
          index,
          this.mprData
        );
        this.dicomViewersRefs[2].mprReferenceLines2(index);
        this.dicomViewersRefs[0].mprReferenceLines3(index, this.mprData);
      } else if (this.props.activeDcmIndex === 2) {
        this.dicomViewersRefs[2].mprRenderYZPlane(
          this.dicomViewersRefs[0].filename,
          plane,
          index,
          this.mprData
        );
        this.dicomViewersRefs[1].mprReferenceLines2(index);
        this.dicomViewersRefs[0].mprReferenceLines3(index, this.mprData);
      }
    } else {
      if (objectIsEmpty(this.mprData)) {
        // works on new image
        this.dicomViewersRefs[this.props.activeDcmIndex].runTool(
          "openimage",
          index
        );
      } else if (
        (plane === "sagittal" && visibleMprSagittal) ||
        (plane === "axial" && visibleMprAxial) ||
        (plane === "coronal" && visibleMprCoronal)
      )
        this.dicomViewersRefs[this.props.activeDcmIndex].runTool(
          "openimage",
          index
        );
      else if (plane === "sagittal" && visibleMprAxial)
        this.dicomViewersRefs[this.props.activeDcmIndex].mprRenderXZPlane(
          this.dicomViewersRefs[0].filename,
          plane,
          index,
          this.mprData
        );
      else if (plane === "sagittal" && visibleMprCoronal)
        this.dicomViewersRefs[this.props.activeDcmIndex].mprRenderYZPlane(
          this.dicomViewersRefs[0].filename,
          plane,
          index,
          this.mprData
        );
      else if (plane === "axial" && visibleMprSagittal)
        this.dicomViewersRefs[this.props.activeDcmIndex].mprRenderYZPlane(
          this.dicomViewersRefs[0].filename,
          plane,
          index,
          this.mprData
        );
      else if (plane === "axial" && visibleMprCoronal)
        this.dicomViewersRefs[this.props.activeDcmIndex].mprRenderXZPlane(
          this.dicomViewersRefs[0].filename,
          plane,
          index,
          this.mprData
        );
      else if (plane === "coronal" && visibleMprSagittal)
        this.dicomViewersRefs[this.props.activeDcmIndex].mprRenderYZPlane(
          this.dicomViewersRefs[0].filename,
          plane,
          index,
          this.mprData
        );
      else if (plane === "coronal" && visibleMprAxial)
        this.dicomViewersRefs[this.props.activeDcmIndex].mprRenderXZPlane(
          this.dicomViewersRefs[0].filename,
          plane,
          index,
          this.mprData
        );
      else {
        // it's not a possible MPR, then open as normal dicom file
        this.dicomViewersRefs[this.props.activeDcmIndex].runTool(
          "openimage",
          index
        );
      }
    }

    this.activeDcmViewersNum = this.getActiveDcmViewers();
    if (this.activeDcmViewersNum > 0) {
      this.getActiveDcmViewersSameStudy();
      this.getActiveDcmViewersSamePlane();
      if (
        this.state.visibleReferenceLines &&
        this.dicomViewersActive.length > 1
      ) {
        this.referenceLinesShow();
      }
    }
  };

  showOpenFolder() {
    this.openFolder.current.click();
  }

  showOpenDicomdir() {
    this.openDicomdir.current.click();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({ selectedResult: this.props.results[0].images });
    this.getFileFromUrl(this.props.results[0].images[0].url);
    this.setState({
      fileNameShowing: this.props.results[0].createdAt,
    });
  }

  getFileFromUrl = (url) => {
    axios({
      url: url,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const blob = new Blob([response.data], { type: "text/dcm" });

      this.handleOpenLocalFs([blob]);
    });
  };

  toggleDicomdir = () => {
    const visible = !this.state.visibleDicomdir;
    this.setState({ visibleDicomdir: visible });
    if (visible)
      this.setState({
        visibleMeasure: false,
        visibleToolbox: false,
        visibleHeader: false,
        visibleFileManager: false,
      });
  };

  hideOpenMultipleFilesDlg = () => {
    this.setState({ visibleOpenMultipleFilesDlg: false });
    this.openMultipleFilesCompleted();
  };

  openMultipleFilesCompleted = () => {
    if (this.props.files !== null) {
      this.changeLayout(1, 1);

      this.dicomViewersRefs[this.props.activeDcmIndex].runTool("openimage", 0);

      const sliceMax = this.props.files.length;

      this.setState({ sliceMax: sliceMax }, () => {});

      // check if there are studies and series, if so then prepare Explorer

      const patientList = groupBy(
        this.props.files,
        (a) => a.patient.patientName
      );
      const patientKeys = [...patientList.keys()];
      const patient = {
        list: patientList,
        keys: patientKeys,
      };

      this.explorer = {
        folder: this.folder,
        patient: patient,
      };

      if (sliceMax > 1)
        this.setState({ visibleExplorer: true, visibleFileManager: false });
    }
  };

  clear = () => {
    this.layoutGridClick(0);
    for (let i = 0; i < this.props.isOpen.length; i++)
      if (this.props.isOpen[i]) this.dicomViewersRefs[i].runTool("clear");
    setTimeout(() => {
      this.setState(
        {
          openImageEdit: false,
          openTools: false,
          mprMenu: false,
          visibleToolbox: false,
          visibleMeasure: false,
          visibleHeader: false,
          visibleDicomdir: false,
        },
        () => {}
      );
      this.changeLayout(1, 1);
      this.props.setFilesStore(null);
      this.props.setDicomdirStore(null);
      //this.props.clearingStore()
    }, 100);
  };

  //#region Layout handler
  changeLayout = (row, col) => {
    // if reduce the grid clear the unused views
    if (row < this.props.layout[0] || col < this.props.layout[1]) {
      for (let i = 0; i < 4; i++) {
        for (let j = 0; j < 4; j++) {
          if (i + 1 > row || j + 1 > col) {
            const index = i * 4 + j;
            this.dicomViewersRefs[index] = undefined;
            if (index === this.props.activeDcmIndex)
              this.layoutGridClick(index - 1);
          }
        }
      }
      this.activeDcmViewersNum = this.getActiveDcmViewers();
      if (this.isMultipleFiles && this.activeDcmViewersNum === 1)
        this.setState({ mprMenu: true });
    }
    this.props.setLayoutStore(row, col);
  };

  //#endregion

  layoutGridClick = (index) => {
    if (isMobile && index === this.props.activeDcmIndex) return;

    const sliceMax = this.dicomViewersRefs[index].sliceMax;
    const sliceIndex = this.dicomViewersRefs[index].sliceIndex;
    this.setState({ sliceMax: sliceMax, sliceIndex: sliceIndex });

    this.props.setActiveDcmIndex(index);

    if (this.state.visibleMprOrthogonal) {
      this.setState({ sliceMax: sliceMax, sliceIndex: sliceIndex });
    }

    const dcmViewer = this.getDcmViewerRef(index);
    if (dcmViewer.image === null) return; // click on empty frame

    this.props.setActiveMeasurements(dcmViewer.measurements);
    this.props.setActiveDcm(dcmViewer);
    this.props.setExplorerActiveSeriesIndex(dcmViewer.explorerIndex);

    if (
      this.state.visibleReferenceLines &&
      this.dicomViewersActive.length > 1
    ) {
      if (this.referenceLines.scoutViewer !== undefined)
        this.referenceLines.scoutViewer.updateImage();
      this.setState({ visibleReferenceLines: false }, () => {
        this.referenceLinesToggle();
      });
    }
  };

  layoutGridTouch = (index) => {
    if (!isMobile && index === this.props.activeDcmIndex) return;
  };

  buildLayoutGrid = () => {
    let dicomviewers = [];
    for (let i = 0; i < this.props.layout[0]; i++) {
      for (let j = 0; j < this.props.layout[1]; j++) {
        const styleLayoutGrid = {
          border:
            this.props.layout[0] === 1 && this.props.layout[1] === 1
              ? "solid 1px #000000"
              : "solid 1px #444444",
        };
        const index = i * 4 + j;
        dicomviewers.push(
          <div
            key={index}
            style={styleLayoutGrid}
            onClick={() => this.layoutGridClick(index)}
            onTouchStart={() => this.layoutGridTouch(index)}
          >
            {this.getDcmViewer(index)}
          </div>
        );
      }
    }

    return (
      <div
        id="dicomviewer-grid"
        style={{
          display: "grid",
          gridTemplateRows: `repeat(${this.props.layout[0]}, ${
            100 / this.props.layout[0]
          }%)`,
          gridTemplateColumns: `repeat(${this.props.layout[1]}, ${
            100 / this.props.layout[1]
          }%)`,
          height: "100%",
          width: "100%",
        }}
      >
        {dicomviewers}
      </div>
    );
  };

  // ---------------------------------------------------------------------------------------------- LINK SERIES
  // #region LINK SERIES

  seriesLinkToggle = () => {
    this.setState({ visibleSeriesLink: !this.state.visibleSeriesLink });
  };

  // ---------------------------------------------------------------------------------------------- REFERENCE LINES
  // #region REFERENCE LINES

  referenceLinesToggle = () => {
    const visible = !this.state.visibleReferenceLines;
    this.setState({ visibleReferenceLines: visible });
    if (visible && this.dicomViewersActive.length > 1) {
      this.referenceLinesShow();
    } else {
      this.referenceLinesHide();
    }
  };

  referenceLinesShow = () => {
    if (
      this.state.mprMode ||
      !this.state.visibleReferenceLines ||
      this.dicomViewersActive.length < 2
    )
      return;
    this.referenceLines.crossViewer = this.getDcmViewerRef(
      this.props.activeDcmIndex
    ); // this is cross-sectional image
    const crossMprPlane = this.referenceLines.crossViewer.mprPlane;
    this.openViewers = this.dicomViewersActive.filter(
      (v) =>
        v.layoutIndex !== this.props.activeDcmIndex &&
        crossMprPlane !== v.mprPlane
    );
    this.referenceLines.crossViewer.updateImage();
    for (let i = 0; i < this.openViewers.length; i++) {
      this.openViewers[i].referenceLinesBuild(
        this.referenceLines.crossViewer.image
      ); // this is scout image
    }
  };

  referenceLinesHide = () => {
    if (this.state.mprMode || this.openViewers === undefined) return;
    for (let i = 0; i < this.openViewers.length; i++) {
      this.openViewers[i].updateImage(); // this is scout image
    }
  };
  // #endregion

  // ---------------------------------------------------------------------------------------------- MPR
  // #region MPR

  mprPlanePosition = (force = false, index = this.props.activeDcmIndex) => {
    if (this.mprPlane === "" || force) {
      this.mprPlane = this.dicomViewersRefs[index].mprPlanePosition();
      if (!this.state.visibleMprOrthogonal) {
        if (this.mprPlane === "sagittal")
          this.setState({
            visibleMprOrthogonal: false,
            visibleMprSagittal: true,
            visibleMprAxial: false,
            visibleMprCoronal: false,
          });
        else if (this.mprPlane === "coronal")
          this.setState({
            visibleMprOrthogonal: false,
            visibleMprSagittal: false,
            visibleMprAxial: false,
            visibleMprCoronal: true,
          });
        else
          this.setState({
            visibleMprOrthogonal: false,
            visibleMprSagittal: false,
            visibleMprAxial: true,
            visibleMprCoronal: false,
          });
      }
    }
    return this.mprPlane;
  };

  // ---------------------------------------------------------------------------------------------- FILES/SLICE MANIPULATION
  //#region FILES/SLICE MANIPULATION

  listOpenFilesPreviousFrame = () => {
    let index = this.state.sliceIndex;
    index = index === 0 ? this.state.sliceMax - 1 : index - 1;
    this.setState({ sliceIndex: index }, () => {
      this.isSliceChange = true;
      this.handleOpenImage(index);
      this.syncActiveDcmViewersSamePlane(-1);
    });
  };

  listOpenFilesNextFrame = () => {
    let index = this.state.sliceIndex;
    index = index === this.state.sliceMax - 1 ? 0 : index + 1;
    this.setState({ sliceIndex: index }, () => {
      this.isSliceChange = true;
      this.handleOpenImage(index);
      this.syncActiveDcmViewersSamePlane(+1);
    });
  };

  getActiveDcmViewers = () => {
    this.dicomViewersActive = this.dicomViewersRefs.filter(
      (v) => v !== undefined && v.image !== null
    );
    return this.dicomViewersActive.length;
  };

  getActiveDcmViewersSameStudy = () => {
    const studyId = getDicomStudyId(
      this.dicomViewersRefs[this.props.activeDcmIndex].image
    );
    this.dicomViewersActiveSameStudy = this.dicomViewersRefs.filter(
      (v) => v !== undefined && getDicomStudyId(v.image) === studyId
    );
    return this.dicomViewersActiveSameStudy.length;
  };

  getActiveDcmViewersSamePlane = () => {
    this.dicomViewersActiveSamePlane = [];
    const plane = this.dicomViewersRefs[this.props.activeDcmIndex].mprPlane;
    for (let i = 0; i < this.dicomViewersActiveSameStudy.length; i++) {
      if (
        this.dicomViewersActiveSameStudy[i].mprPlane === plane &&
        this.dicomViewersActiveSameStudy[i].layoutIndex !==
          this.props.activeDcmIndex
      )
        this.dicomViewersActiveSamePlane.push(
          this.dicomViewersActiveSameStudy[i]
        );
    }
  };

  syncActiveDcmViewersSamePlane = (direction) => {
    if (!this.state.visibleSeriesLink) return;
    if (this.dicomViewersActiveSamePlane.length > 0) {
      const plane = this.dicomViewersRefs[this.props.activeDcmIndex].mprPlane;
      const ippX = getDicomIpp(
        this.dicomViewersRefs[this.props.activeDcmIndex].image,
        0
      );
      const ippY = getDicomIpp(
        this.dicomViewersRefs[this.props.activeDcmIndex].image,
        1
      );
      const ippZ = getDicomIpp(
        this.dicomViewersRefs[this.props.activeDcmIndex].image,
        2
      );

      let j = 0;
      for (let i = 0; i < this.dicomViewersActiveSamePlane.length; i++) {
        if (plane === "sagittal")
          j = this.dicomViewersActiveSamePlane[i].findFirstSliceWithIppValue(
            ippX,
            0
          );
        else if (plane === "coronal")
          j = this.dicomViewersActiveSamePlane[i].findFirstSliceWithIppValue(
            ippY,
            1
          );
        else if (plane === "axial")
          j = this.dicomViewersActiveSamePlane[i].findFirstSliceWithIppValue(
            ippZ,
            2
          );
        if (j >= 0) this.dicomViewersActiveSamePlane[i].runTool("openimage", j);
      }
    }
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value });
    this.setState({ page: 0 });
  };

  formatDate = (value) => {
    if (value === "") {
      return "No";
    } else {
      return moment(new Date(value)).format("DD/MM/YYYY");
    }
  };

  changeResultSelected = (sample) => {
    const currentSample = sample.id - 1;

    this.setState({ sampleIndex: currentSample });
    this.getFileFromUrl(this.state.selectedResult[currentSample].url);
  };

  render() {
    this.isMultipleFiles = false;
    if (this.dicomViewersRefs[this.props.activeDcmIndex] === undefined) {
      this.isMultipleFiles = false;
    } else {
      if (this.dicomViewersRefs[this.props.activeDcmIndex].files !== null)
        this.isMultipleFiles =
          this.dicomViewersRefs[this.props.activeDcmIndex].files.length > 1;
      else this.isMultipleFiles = false;
    }

    const visibleOpenMultipleFilesDlg = this.state.visibleOpenMultipleFilesDlg;

    return (
      <div style={{ marginBottom: "50px" }}>
        {visibleOpenMultipleFilesDlg ? (
          <OpenMultipleFilesDlg
            onClose={this.hideOpenMultipleFilesDlg}
            files={this.files}
            origin={"local"}
          />
        ) : null}

        <Dialog open={this.state.visibleMessage}>
          <DialogTitle>{this.state.titleMessage}</DialogTitle>
          <DialogContent>
            <DialogContentText>{this.state.textMessage}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ visibleMessage: false })}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        <div
          style={{
            paddingLeft: "30px",
            paddingRight: "30px",
          }}
        >
          <Grid
            id="grid-style-4"
            md={12}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography
              variant="subtitle2"
              gutterBottom
              style={{
                color: "#1AA1B9",
                fontSize: "20px",
                fontWeight: "600",
              }}
            >
              {"Estudio del " + this.formatDate(this.state.fileNameShowing)}
            </Typography>
          </Grid>
        </div>

        <div style={{ height: "calc(100vh - 48px)" }}>
          {this.buildLayoutGrid()}
        </div>

        <div
          style={{
            paddingLeft: "30px",
            paddingRight: "30px",
            marginTop: "30px",
          }}
        >
          <Grid
            id="grid-style-4"
            md={12}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography
              variant="subtitle2"
              gutterBottom
              style={{
                color: "#1AA1B9",
                fontSize: "20px",
                fontWeight: "600",
              }}
            >
              Tomas realizadas
            </Typography>
          </Grid>

          <Grid>
            <TableContainer id="table-container-style-1">
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Nombre</StyledTableCell>
                    <StyledTableCell align="center">Acciones</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.selectedResult &&
                    this.state.selectedResult.map((row) => {
                      return (
                        <StyledTableRow key={row.id}>
                          <StyledTableCell>{row.name}</StyledTableCell>

                          <StyledTableCell align="center">
                            <Tooltip
                              title="Ver muestra"
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              arrow
                            >
                              <IconButton
                                aria-label="view"
                                size="small"
                                color="secondary"
                                onClick={() => {
                                  this.changeResultSelected(row);
                                }}
                              >
                                <Visibility />
                              </IconButton>
                            </Tooltip>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </div>

        <div
          style={{
            paddingLeft: "30px",
            paddingRight: "30px",
            marginTop: "30px",
          }}
        >
          <Grid
            id="grid-style-4"
            md={12}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography
              variant="subtitle2"
              gutterBottom
              style={{
                color: "#1AA1B9",
                fontSize: "20px",
                fontWeight: "600",
              }}
            ></Typography>
          </Grid>

          <Grid>
            <TableContainer id="table-container-style-1">
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Estudio realizado</StyledTableCell>
                    <StyledTableCell>Fecha</StyledTableCell>
                    <StyledTableCell align="center">
                      Diagnóstico
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.results &&
                    this.props.results
                      .slice(
                        this.state.page * this.state.rowsPerPage,
                        this.state.page * this.state.rowsPerPage +
                          this.state.rowsPerPage
                      )
                      .map((row) => {
                        return (
                          <StyledTableRow key={row.id}>
                            <StyledTableCell>{row.studyType}</StyledTableCell>
                            <StyledTableCell>
                              {this.formatDate(row.createdAt)}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Tooltip
                                title="Ver diagnóstico"
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                arrow
                              >
                                <IconButton
                                  aria-label="view"
                                  size="small"
                                  color="primary"
                                  onClick={() => {
                                    this.setState({
                                      selectedResult: row.images,
                                    });
                                    this.setState({ sampleIndex: 0 });
                                    this.getFileFromUrl(row.images[0].url);
                                    this.setState({
                                      fileNameShowing: row.createdAt,
                                    });
                                  }}
                                >
                                  <InsertDriveFile fontSize="medium" />
                                </IconButton>
                              </Tooltip>
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                </TableBody>
              </Table>
            </TableContainer>
            <CardActions>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                count={this.props.results.length}
                component="div"
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
                labelRowsPerPage={"Resultados por página:"}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} de ${count}`
                }
              />
            </CardActions>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    localFileStore: state.localFileStore,
    files: state.files,
    series: state.series,
    isOpen: state.isOpen,
    tool: state.tool,
    activeDcmIndex: state.activeDcmIndex,
    explorerActivePatientIndex: state.explorerActivePatientIndex,
    explorerActiveStudyIndex: state.explorerActiveStudyIndex,
    explorerActiveSeriesIndex: state.explorerActiveSeriesIndex,
    measurements: state.measurements,
    layout: state.layout,
    dicomdir: state.dicomdir,
    fsZippedFile: state.fsZippedFile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearingStore: () => dispatch(clearStore()),
    setLocalFileStore: (file) => dispatch(localFileStore(file)),
    toolStore: (tool) => dispatch(dcmTool(tool)),
    isOpenStore: (value) => dispatch(dcmIsOpen(value)),
    setActiveDcm: (dcm) => dispatch(activeDcm(dcm)),
    setActiveDcmIndex: (index) => dispatch(activeDcmIndex(index)),
    setActiveMeasurements: (measurements) =>
      dispatch(activeMeasurements(measurements)),
    setLayoutStore: (row, col) => dispatch(setLayout(row, col)),
    setDicomdirStore: (dicomdir) => dispatch(setDicomdir(dicomdir)),
    setFsZippedFile: (file) => dispatch(setZippedFile(file)),
    setVolumeStore: (file) => dispatch(setVolume(file)),
    setFilesStore: (files) => dispatch(filesStore(files)),
    setExplorerActiveSeriesIndex: (index) =>
      dispatch(explorerActiveSeriesIndex(index)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudyImagesCompare);
