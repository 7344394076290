import React from "react";
import ResumeBarChart from "./ResumeBarChart";
import { Avatar, Divider, Grid, Paper } from "@mui/material";
import theme from "../../assets/theme";
import { PriceChange } from "@mui/icons-material";
import ReactEcharts from "echarts-for-react";

const AnnualComparison = () => {
  return (
    <div id="div-style-16" style={{ paddingBottom: "14px" }}>
      <Paper id="paper-style-3" style={{ height: "100%" }}>
        <div style={{ width: "100%" }}>
          <div id="div-style-45">
            <PriceChange id="icon-style-5" />
            <div id="div-style-17">Comparativa anual</div>
          </div>

          <div id="div-style-18">
            <ReactEcharts
              style={{ height: "45vh" }}
              option={{
                tooltip: {
                  trigger: "axis",
                  axisPointer: {
                    type: "cross",
                  },
                },
                legend: {
                  show: false,
                },
                grid: {
                  left: "5%",
                  top: 20,
                  right: "5%",
                  bottom: 30,
                },
                xAxis: [
                  {
                    type: "category",
                    data: [
                      "Ene",
                      "Feb",
                      "Mar",
                      "Abr",
                      "May",
                      "Jun",
                      "Jul",
                      "Ago",
                      "Sep",
                      "Oct",
                      "Nov",
                      "Dic",
                    ],
                    axisPointer: {
                      type: "shadow",
                    },
                  },
                ],
                yAxis: [
                  {
                    type: "value",
                    min: 0,
                    interval: 10,
                  },
                ],
                series: [
                  {
                    name: "Ingresos",
                    type: "bar",
                    data: [12, 7, 12, 15, 8, 12, 20, 11, 24, 12, 17, 29],
                    itemStyle: {
                      color: theme.palette.pink.default,
                    },
                  },
                  {
                    name: "Egresos",
                    type: "bar",
                    data: [9, 4, 4, 11, 5, 9, 11, 8, 12, 8, 5, 12],
                    itemStyle: {
                      color: theme.palette.gray.default,
                    },
                  },
                  {
                    name: "Utilidad",
                    type: "line",
                    data: [15, 10, 8, 4, 7, 6, 2, 6, 13, 19, 22, 22],
                    symbol: "none",
                    itemStyle: {
                      color: theme.palette.orange.default,
                    },
                    lineStyle: {
                      color: theme.palette.orange.default,
                      width: 2,
                      type: "dashed",
                    },
                  },
                ],
              }}
            />
          </div>

          <div style={{ width: "100%" }}>
            <Divider />

            <Grid container>
              <Grid
                xl={4}
                lg={4}
                md={12}
                sm={12}
                xs={12}
                style={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ResumeBarChart
                  widgetTitle={
                    <div id="div-style-45">
                      <Avatar src={null} id="avatar-style-2">
                        {""}
                      </Avatar>
                      <div id="div-style-21">Ingresos</div>
                    </div>
                  }
                  amount={"$105,285.00"}
                />
              </Grid>

              <Grid
                xl={4}
                lg={4}
                md={12}
                sm={12}
                xs={12}
                style={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Grid container style={{ justifyContent: "space-between" }}>
                  <Divider orientation="vertical" variant="middle" flexItem />

                  <Grid
                    xl={11}
                    lg={11}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <ResumeBarChart
                      widgetTitle={
                        <div id="div-style-45">
                          <Avatar src={null} id="avatar-style-3">
                            {""}
                          </Avatar>
                          <div id="div-style-21">Egresos</div>
                        </div>
                      }
                      amount={"$37,240.00"}
                    />
                  </Grid>

                  <Divider orientation="vertical" variant="middle" flexItem />
                </Grid>
              </Grid>

              <Grid
                xl={4}
                lg={4}
                md={12}
                sm={12}
                xs={12}
                style={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ResumeBarChart
                  widgetTitle={
                    <div id="div-style-45">
                      <Avatar src={null} id="avatar-style-4">
                        {""}
                      </Avatar>
                      <div id="div-style-21">Utilidad</div>
                    </div>
                  }
                  amount={"$68,045.00"}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default AnnualComparison;
