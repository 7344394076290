const fisicaDelEstudio = {
  name: "fisicaDelEstudio",
  value:
    "Es un sistema especializado en llevar a cabo una tomografía computarizada de impedancia eléctrica, la aplicación del MEM permite detectar patologías en los tejidos de la mama por la medición de los cambios relativos de conductividad de los tejidos biológicos a  diferentes frecuencias y visualizar el patrón de distribución de conductividad en la pantalla del ordenador (PC) con la aplicación de 256 electrodos, permite reconstruir las distribuciones de conductividad en 3D (en forma de secciones cruzadas en el tomograma a diferentes profundidades) y obtener imágenes detalladas, realiza mediciones a diferentes frecuencias. Estudio basado en las diferencias de conductividad eléctrica entre los tejidos sanos y alterados. El equipo analiza la glándula mamaria fisiológica y anatómicamente, a través de la obtención de resultados cuantitativos y cualitativos, reconstrucción tomográfica en siete cortes de 7mm, detectando la composición de la misma y su estado fisiológico."
};

const CONSTANTSOBSERVATIUONS = {
  FISICADELSTUDIO: fisicaDelEstudio,
};

export function getStatusDataWithName(statusName) {
  return CONSTANTSOBSERVATIUONS[
    Object.keys(CONSTANTSOBSERVATIUONS).filter(
      (f) => CONSTANTSOBSERVATIUONS[f].name.toString() === statusName
    )
  ];
}

export default CONSTANTSOBSERVATIUONS;
