import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Button, CircularProgress, Paper, TextField } from "@mui/material";
import { login } from "../repositories/LoginRepository";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import SnackBarMessage from "../../components/SnackBarMessage";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import theme from "../../assets/theme";

const LoginButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 18,
  borderStyle: "none",
  fontFamily: "Helvetica",
  fontWeight: "bold",
});

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "&.Mui-focused": {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
});

const LoginForm = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackSeverity, setSnackSeverity] = useState("success");
  const [snackMessage, setSnackMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("Correo inválido")
        .required("Correo obligatorio"),
      password: Yup.string().required("Campo obligatorio"),
    }),
    onSubmit: async () => {
      setLoading(true);
      const response = await login(formik.values.email, formik.values.password);
      setLoading(false);

      if (response) {
        if (response.status === "Ok") {
          navigate(`../dashboard`);
        } else {
          setSnackSeverity("warning");
          setSnackMessage(response.message);
          setSnackOpen(true);
        }
      }
    },
  });

  const onClickVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Paper id="paper-style-1">
          <StyledTextField
            id="email"
            label="Escribe tu usuario"
            fullWidth
            onChange={formik.handleChange}
            value={formik.values.email}
            onBlur={formik.handleBlur}
            inputProps={{ style: { fontSize: 14, padding: "10px" } }}
            InputLabelProps={{ style: { fontSize: 12 } }}
          />
        </Paper>
        {Boolean(formik.errors.email && formik.touched.email) && (
          <div id="div-style-input-error">{formik.errors.email}</div>
        )}
        <div id="div-style-6">
          <Paper id="paper-style-password-input">
            <StyledTextField
              id="password"
              label="Escribe tu contraseña"
              fullWidth
              type={showPassword ? "text" : "password"}
              onChange={formik.handleChange}
              value={formik.values.password}
              onBlur={formik.handleBlur}
              inputProps={{ style: { fontSize: 14, padding: "10px" } }}
              InputLabelProps={{ style: { fontSize: 12 } }}
            />
            <div id="div-style-toggle" onClick={onClickVisibility}>
              {showPassword ? (
                <VisibilityOff
                  fontSize="small"
                  style={{ color: theme.palette.secondary.main }}
                />
              ) : (
                <Visibility
                  fontSize="small"
                  style={{ color: theme.palette.secondary.main }}
                />
              )}
            </div>
          </Paper>
          {Boolean(formik.errors.password && formik.touched.password) && (
            <div id="div-style-input-error">{formik.errors.password}</div>
          )}
        </div>
        <div id="div-style-7">Olvidé mi contraseña</div>
        {loading ? (
          <CircularProgress />
        ) : (
          <LoginButton variant="contained" type="submit" id="button-style-1">
            Iniciar Sesión
          </LoginButton>
        )}
      </form>
      <SnackBarMessage
        severity={snackSeverity}
        message={snackMessage}
        open={snackOpen}
        onClose={() => {
          setSnackOpen(false);
        }}
      />
    </>
  );
};

export default LoginForm;
