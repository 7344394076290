import Logo from "./images/LogoXMI.png";
import Ribbon from "./images/ribbonX.png";
import RibbonPink from "./images/RibbonXPink.png";
import WomanAvatar from "./images/WomanAvatar.png";
import womanXmi from "./images/login-woman-xmi.png";
import WomaBody from "./images/WomanBody.png";
import Mastografia1 from "./images/test/mastografia-1.jpg";
import Mastografia2 from "./images/test/mastografia-2.jpg";
import Mastografia3 from "./images/test/mastografia-3.jpg";
import M3mLogo from "./images/logo-m3m.png";
import MapTest from "./images/maps-cdmx.jpg";
import Dogvatar from "./images/DogAvatar.jpg";
import Error401 from "./images/backto401.png";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  LogoDefault: Logo,
  RibbonDefault: Ribbon,
  RibbonXPink: RibbonPink,
  WomanDefaultAvatar: WomanAvatar,
  WomanXmi: womanXmi,
  BodyCanvas: WomaBody,
  Mastografia1: Mastografia1,
  Mastografia2: Mastografia2,
  Mastografia3: Mastografia3,
  M3mLogo: M3mLogo,
  MapTest: MapTest,
  DogAvatar: Dogvatar,
  BackTo401: Error401,
};
