const dummyResultsDashboard = [
  {
    id: 1,
    establishmentName: "Sucursal 1",
    studiesAmount: "30",
    averageHour: "30",
  },
  {
    id: 2,
    establishmentName: "Sucursal 2",
    studiesAmount: "25",
    averageHour: "72",
  },
  {
    id: 3,
    establishmentName: "Sucursal 3",
    studiesAmount: "35",
    averageHour: "48",
  },
  {
    id: 4,
    establishmentName: "Sucursal 4",
    studiesAmount: "50",
    averageHour: "38",
  },
];

export default dummyResultsDashboard;
