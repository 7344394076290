import { getHeaderSimple } from "./utils";

export const createPermission = async (
    permissionObject
  ) => {
    const permissionItem = permissionObject;
  
   const response = await fetch(`${process.env.REACT_APP_API_URL}permission/`, {
      method: "POST",
      headers: getHeaderSimple(),
      body: JSON.stringify(permissionItem),
    });
    const json = await response.json();
  
    if (json.message === "Ok") {
      return {
        status: "Ok",
        message: "Los datos se han guardado correctamente",
        content: json.data,
      };
    } else {
      return {
        status: "Error",
        message: json.content ?? "Error de conexión",
      };
    }

  };

  export const getPermissions = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}permission/`,
      {
        method: "GET",
        headers: getHeaderSimple(),
      }
    );

    const json = await response.json();
    if (json.message === "Ok") {
      return {
        status: "Ok",
        message: "Ok",
        content: json.data,
      };
    } else {
      return {
        status: "Error",
        message: json.content ?? "Error de conexión",
      };
    }
  };

  export const deleteData = async (id) => {
		const response = await fetch(`${process.env.REACT_APP_API_URL}permission/${id}`, {
			method: 'DELETE',
			headers: getHeaderSimple(),
		});
		const json = await response.json();
		if (json.message === 'Ok') {
			return {
        status: "Ok",
        message: "El elemento se ha eliminado correctamente",
        content: json.data,
      };
		} else {
			return {
        status: "Error",
        message: json.content ?? "Error de conexión",
      };
		}
	};

  export const updatePermission = async (permissionObject) => {
    const permissionId = permissionObject._id;
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}permission/${permissionId}`,
      {
        method: "PUT",
        body: JSON.stringify(permissionObject),
        headers: getHeaderSimple(),
      }
    );
    const json = await response.json();

		if (json.status === 'Ok') {
			return {
        status: "Ok",
        message: "El elemento se ha actualizado correctamente",
        content: json.data,
      };
		} else {
			return {
        status: "Error",
        message: json.content ?? "Error de conexión",
      };
		}
  };
