import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import { DataContext } from "../../contexts/DataContext";

const SheetFormTab2 = ({ setNextTab, showOrEdit }) => {
  const [isContraceptives, setIsContraceptives] = useState(true);
  let contextData = useContext(DataContext);
  let itemContext = contextData.clinicalSheet.gynecologicalHistory;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstMenstruation: "",
      lastDate: "",
      lastMenstruation: "",
      period: false,
      periodsNote: "",
      contraceptives: false,
      contraceptiveName: "",
      usePeriond: "",
      notes: "",
    },
    validationSchema: Yup.object().shape({
      //Aqui tengo que poner la validacion con yup
    }),
    onSubmit: async () => {
      setNextTab("3");
    },
  });

  useEffect(() => {
    const showItems = () => {
      formik.setValues({
        firstMenstruation: itemContext.firstMenstruation,
        lastDate: itemContext.lastDate,
        lastMenstruation: itemContext.lastMenstruation,
        period: itemContext.period,
        periodsNote: itemContext.periodsNote,
        contraceptives: itemContext.contraceptives,
        contraceptiveName: itemContext.contraceptiveName,
        usePeriond: itemContext.usePeriond,
        notes: itemContext.notes,
      });
    };
    showItems();
    setNextTab("2");
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNextTab]);
  return (
    <div
      id="div-scrolling-style-result"
      style={{
        overflowY: "auto",
        height: "74vh",
        paddingBottom: "100px",
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={1}>
          <Grid id="grid-style-10" item={true} xs={12}>
            <Typography variant="subtitle2" gutterBottom>
              Antecedentes gineco-obstétricos
            </Typography>
          </Grid>
          <Grid item={true} md={4}>
            <InputLabel shrink>Primera menstruación (edad)</InputLabel>
            <TextField
              id="firstMenstruation"
              size="small"
              disabled={showOrEdit}
              onChange={(e) => {
                formik.setFieldValue("firstMenstruation", e.target.value);
                itemContext.firstMenstruation = e.target.value;
              }}
              value={formik.values.firstMenstruation}
              onBlur={formik.handleBlur}
            />
          </Grid>
          <Grid item={true} xs={4}>
            <InputLabel shrink>Fecha ultima menstruación</InputLabel>
            <TextField
              type="date"
              id="lastDate"
              size="small"
              disabled={showOrEdit}
              onChange={(e) => {
                formik.setFieldValue("lastDate", e.target.value);
                itemContext.lastDate = e.target.value;
              }}
              value={formik.values.lastDate}
              onBlur={formik.handleBlur}
            />
          </Grid>
          <Grid item={true} md={4}>
            <InputLabel shrink>
              Si ya dejó de menstruar indique el tiempo o la edad
            </InputLabel>
            <TextField
              id="lastMenstruation"
              size="small"
              disabled={showOrEdit}
              onChange={(e) => {
                formik.setFieldValue("lastMenstruation", e.target.value);
                itemContext.lastMenstruation = e.target.value;
              }}
              value={formik.values.lastMenstruation}
              onBlur={formik.handleBlur}
            />
          </Grid>
          <Grid id="grid-style-10" item={true} md={12}>
            <Typography variant="subtitle2" gutterBottom>
              Periodo menstrual
            </Typography>
          </Grid>
          <Grid item={true} xs={6}>
            <FormControl>
              <FormGroup row={true}>
                <FormLabel id="formlabel-style-1">
                  <Typography variant="body2">Su periodo es:</Typography>
                </FormLabel>
                <RadioGroup
                  id="period"
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  row={true}
                  value={formik.values.period}
                  onChange={(e) => {
                    formik.setFieldValue("period", e.target.value);
                    itemContext.period = e.target.value;
                  }}
                  onBlur={formik.handleBlur}
                >
                  <FormControlLabel
                    id="formcontrollabel-style-1"
                    value="regular"
                    disabled={showOrEdit}
                    label={<Typography variant="body2">Regular</Typography>}
                    control={<Radio size="small" />}
                  />
                  <FormControlLabel
                    id="formcontrollabel-style-1"
                    value="irregular"
                    disabled={showOrEdit}
                    label={<Typography variant="body2">Irregular</Typography>}
                    control={<Radio size="small" />}
                  />
                </RadioGroup>
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item={true} xs={12}>
            <TextField
              id="periodsNote"
              size="small"
              disabled={showOrEdit}
              onChange={(e) => {
                formik.setFieldValue("periodsNote", e.target.value);
                itemContext.periodsNote = e.target.value;
              }}
              value={formik.values.periodsNote}
              onBlur={formik.handleBlur}
            />
          </Grid>
          <Grid id="grid-style-10" item={true} md={12}>
            <Typography variant="subtitle2" gutterBottom>
              Anticonceptivos
            </Typography>
          </Grid>
          <Grid item={true} xs={6}>
            <FormControl>
              <FormGroup row={true}>
                <FormLabel id="formlabel-style-1">
                  <Typography variant="body2">
                    Uso de anticonceptivos hormonales:
                  </Typography>
                </FormLabel>
                <RadioGroup
                  id="contraceptives"
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  row={true}
                  value={formik.values.contraceptives}
                  disabled={showOrEdit}
                  onChange={(e) => {
                    formik.setFieldValue("contraceptives", e.target.value);
                    itemContext.contraceptives = e.target.value;
                    if (e.target.value === "si") {
                      setIsContraceptives(false);
                    } else {
                      setIsContraceptives(true);
                    }
                  }}
                  onBlur={formik.handleBlur}
                >
                  <FormControlLabel
                    id="formcontrollabel-style-1"
                    value="si"
                    label={<Typography variant="body2">Si</Typography>}
                    disabled={showOrEdit}
                    control={<Radio size="small" />}
                  />
                  <FormControlLabel
                    id="formcontrollabel-style-1"
                    value="no"
                    label={<Typography variant="body2">No</Typography>}
                    disabled={showOrEdit}
                    control={<Radio size="small" />}
                  />
                </RadioGroup>
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item={true} xs={8}>
            <InputLabel shrink>¿Nombre del anticonceptivo?</InputLabel>
            <TextField
              id="contraceptiveName"
              disabled={isContraceptives || showOrEdit}
              size="small"
              onChange={(e) => {
                formik.setFieldValue("contraceptiveName", e.target.value);
                itemContext.contraceptiveName = e.target.value;
              }}
              value={formik.values.contraceptiveName}
              onBlur={formik.handleBlur}
            />
          </Grid>
          <Grid item={true} xs={4}>
            <InputLabel shrink>Tiempo de uso</InputLabel>
            <TextField
              id="usePeriond"
              disabled={isContraceptives || showOrEdit}
              size="small"
              onChange={(e) => {
                formik.setFieldValue("usePeriond", e.target.value);
                itemContext.usePeriond = e.target.value;
              }}
              value={formik.values.usePeriond}
              onBlur={formik.handleBlur}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item={true} xs={12} sx={{ marginTop: "15px" }}>
            <InputLabel shrink>Comentarios y observaciones</InputLabel>
            <TextField
              minRows={6}
              maxRows={6}
              multiline
              InputProps={{ disableUnderline: true }}
              id="notes"
              disabled={showOrEdit}
              onChange={(e) => {
                formik.setFieldValue("notes", e.target.value);
                itemContext.notes = e.target.value;
              }}
              value={formik.values.notes}
              onBlur={formik.handleBlur}
            />
          </Grid>

          <Grid
            container
            sx={{
              marginTop: "30px",
              paddingLeft: "24px",
              paddingRight: "24px",
            }}
          >
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={6}
              xs={6}
              style={{
                paddingRight: "10px",
              }}
            >
              <Button
                id="button-none-transform-color-white"
                variant="contained"
                fullWidth
                onClick={() => {
                  setNextTab("1");
                }}
              >
                Atrás
              </Button>
            </Grid>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={6}
              xs={6}
              style={{
                paddingLeft: "10px",
              }}
            >
              <Button
                id="button-none-transform-color-white"
                color="secondary"
                type="submit"
                variant="contained"
                disabled={showOrEdit}
                fullWidth
              >
                Siguiente
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default SheetFormTab2;
