import JsPDF from "jspdf";
import moment from "moment";
import imgLogo from "../../assets/imagesDefault";

const paragraphParams = {
  firstQuarter: 10,
  secondQuarter: 60,
  thirdQuarter: 110,
  fourthQuarter: 160,
  fontSize: 12,
  titleFontSize: 16,
  defaultColor: "black",
  primaryColor: "#EC7BA1",
  secondaryColor: "#1AA1B9",
};
const parseBool = (value) => {
  if (value) {
    return "Si";
  } else {
    return "No";
  }
};

const capitalLetter = (text) => {
  const str = text;
  const capitalString = str.charAt(0).toUpperCase() + str.slice(1);
  return capitalString;
};

export function generateEducativeOfferPdf(patientData, patientSheet) {
  const pdf = new JsPDF();
  const totalPagesExp = "{total_pages_count_string}";
  const width = pdf.internal.pageSize.getWidth();
  const sheetInfo = patientSheet.content[0];

  //TITULO PRINCIPAL
  pdf.setFontSize(paragraphParams.titleFontSize);
  pdf.setFont(undefined, "bold");
  pdf.text("HOJA CLÍNICA", width / 2, 20, { align: "center" });
  // LOGO
  pdf.addImage(
    imgLogo.LogoDefault,
    "PNG",
    paragraphParams.fourthQuarter,
    20,
    40, // width
    15 // height
  );
  //DATOS PERSONALES
  pdf.setFontSize(paragraphParams.fontSize);
  pdf.setFont(undefined, "normal");
  pdf.text(`Fecha: ${moment().format("DD-MM-YYYY")}`, 10, 35);
  pdf.text(`Nombre: ${patientData.name} ${patientData.lastname}`, 10, 43);
  pdf.text(`Edad: ${patientData.age}`, paragraphParams.firstQuarter, 51);
  pdf.text(
    `Fecha de nacimiento: ${patientData.birthday}`,
    paragraphParams.secondQuarter,
    51
  );
  pdf.text(`Género: ${patientData.gender}`, paragraphParams.fourthQuarter, 51);
  pdf.text(`Peso: ${sheetInfo.clinicalHistory.weight}`, paragraphParams.firstQuarter, 59);
  pdf.text(`Altura: ${sheetInfo.clinicalHistory.height}`, paragraphParams.secondQuarter, 59);
  pdf.text(`IMC: ${sheetInfo.clinicalHistory.imc}`, paragraphParams.thirdQuarter, 59);
  pdf.text(
    `IMC Status: ${sheetInfo.clinicalHistory.imcStatus}`,
    paragraphParams.fourthQuarter,
    59
  );

  pdf.setFontSize(paragraphParams.fontSize);
  pdf.setTextColor(paragraphParams.secondaryColor);
  pdf.setFont(undefined, "bold");
  pdf.text("Antecedentes y enfermedades", width / 2, 67, { align: "center" });

  pdf.setFontSize(paragraphParams.fontSize);
  pdf.setTextColor(paragraphParams.primaryColor);
  pdf.setFont(undefined, "bold");
  pdf.text(
    "Enfermedades crónico-degenerativas",
    paragraphParams.firstQuarter,
    75
  );

  pdf.setTextColor(paragraphParams.defaultColor);
  pdf.setFontSize(paragraphParams.fontSize);
  pdf.setFont(undefined, "normal");
  pdf.text(
    `Cancer de mama: ${parseBool(
      sheetInfo.clinicalHistory.chronicDegenerative.breastCancer
    )}`,
    paragraphParams.firstQuarter,
    83
  );
  pdf.text(
    `Hipertensión: ${parseBool(
      sheetInfo.clinicalHistory.chronicDegenerative.hypertension
    )}`,
    paragraphParams.secondQuarter,
    83
  );
  pdf.text(
    `Enfermedad autoinmune: ${parseBool(
      sheetInfo.clinicalHistory.chronicDegenerative.autoimmune
    )}`,
    paragraphParams.thirdQuarter,
    83
  );
  pdf.text(
    `Cancer de ovario: ${parseBool(
      sheetInfo.clinicalHistory.chronicDegenerative.ovarianCancer
    )}`,
    paragraphParams.firstQuarter,
    91
  );
  pdf.text(
    `Tiroides: ${parseBool(
      sheetInfo.clinicalHistory.chronicDegenerative.thyroid
    )}`,
    paragraphParams.secondQuarter,
    91
  );
  pdf.text(
    `Linfoproliferativas(Linfona/Leucemia): ${parseBool(
      sheetInfo.clinicalHistory.chronicDegenerative.lymphopro
    )}`,
    paragraphParams.thirdQuarter,
    91
  );
  pdf.text(
    `Cancer de colon: ${parseBool(
      sheetInfo.clinicalHistory.chronicDegenerative.colonCancer
    )}`,
    paragraphParams.firstQuarter,
    99
  );
  pdf.text(
    `Diabetes: ${parseBool(
      sheetInfo.clinicalHistory.chronicDegenerative.diabetes
    )}`,
    paragraphParams.secondQuarter,
    99
  );

  pdf.setFontSize(paragraphParams.fontSize);
  pdf.setTextColor(paragraphParams.primaryColor);
  pdf.setFont(undefined, "bold");
  pdf.text(
    "Antecedentes oncológicos heredofamiliares",
    paragraphParams.firstQuarter,
    107
  );

  pdf.setTextColor(paragraphParams.defaultColor);
  pdf.setFontSize(paragraphParams.fontSize);
  pdf.setFont(undefined, "normal");
  //Cancer de mama
  pdf.text(
    `Cancer de mama:  Madre: ${parseBool(
      sheetInfo.clinicalHistory.breastCancer.breastMother
    )}`,
    paragraphParams.firstQuarter,
    115
  );
  pdf.text(
    `Padre: ${parseBool(sheetInfo.clinicalHistory.breastCancer.breastFather)}`,
    paragraphParams.secondQuarter + 20,
    115
  );
  pdf.text(
    `Hermanos: ${parseBool(
      sheetInfo.clinicalHistory.breastCancer.breastSiblings
    )}`,
    paragraphParams.thirdQuarter,
    115
  );
  pdf.text(
    `Hijos: ${parseBool(
      sheetInfo.clinicalHistory.breastCancer.breastChildren
    )}`,
    paragraphParams.fourthQuarter,
    115
  );
  //Cancer de colon
  pdf.text(
    `Cancer de colon:  Madre: ${parseBool(
      sheetInfo.clinicalHistory.colonCancer.colonMother
    )}`,
    paragraphParams.firstQuarter,
    123
  );
  pdf.text(
    `Padre: ${parseBool(sheetInfo.clinicalHistory.colonCancer.colonFather)}`,
    paragraphParams.secondQuarter + 20,
    123
  );
  pdf.text(
    `Hermanos: ${parseBool(
      sheetInfo.clinicalHistory.colonCancer.colonSiblings
    )}`,
    paragraphParams.thirdQuarter,
    123
  );
  pdf.text(
    `Hijos: ${parseBool(sheetInfo.clinicalHistory.colonCancer.colonChildren)}`,
    paragraphParams.fourthQuarter,
    123
  );
  //Cancer de ovario
  pdf.text(
    `Cancer de ovario:  Madre: ${parseBool(
      sheetInfo.clinicalHistory.ovarianCancer.ovarianMother
    )}`,
    paragraphParams.firstQuarter,
    131
  );
  pdf.text(
    `Padre: ${parseBool(
      sheetInfo.clinicalHistory.ovarianCancer.ovarianFather
    )}`,
    paragraphParams.secondQuarter + 20,
    131
  );
  pdf.text(
    `Hermanos: ${parseBool(
      sheetInfo.clinicalHistory.ovarianCancer.ovarianSiblings
    )}`,
    paragraphParams.thirdQuarter,
    131
  );
  pdf.text(
    `Hijos: ${parseBool(
      sheetInfo.clinicalHistory.ovarianCancer.ovarianChildren
    )}`,
    paragraphParams.fourthQuarter,
    131
  );

  pdf.setFontSize(paragraphParams.fontSize);
  pdf.setTextColor(paragraphParams.primaryColor);
  pdf.setFont(undefined, "bold");
  pdf.text(
    "Antecedentes oncológicos heredofamiliares",
    paragraphParams.firstQuarter,
    139
  );

  pdf.setTextColor(paragraphParams.defaultColor);
  pdf.setFontSize(paragraphParams.fontSize);
  pdf.setFont(undefined, "normal");
  pdf.text(
    `Útero:  ${parseBool(sheetInfo.clinicalHistory.oncologicalHistory.uterus)}`,
    paragraphParams.firstQuarter,
    147
  );
  pdf.text(
    `Ovario:  ${parseBool(sheetInfo.clinicalHistory.oncologicalHistory.ovary)}`,
    paragraphParams.secondQuarter - 20,
    147
  );
  pdf.text(
    `Endometrio:  ${parseBool(
      sheetInfo.clinicalHistory.oncologicalHistory.endometrium
    )}`,
    paragraphParams.thirdQuarter - 30,
    147
  );
  pdf.text(
    `Otra:  ${parseBool(
      sheetInfo.clinicalHistory.oncologicalHistory.otherCheck
    )} ¿cual?: ${parseBool(
      sheetInfo.clinicalHistory.oncologicalHistory.otherInput
    )}`,
    paragraphParams.fourthQuarter - 30,
    147
  );

  pdf.setFontSize(paragraphParams.fontSize);
  pdf.setTextColor(paragraphParams.primaryColor);
  pdf.setFont(undefined, "bold");
  pdf.text("Antecedentes toxicológicos", paragraphParams.firstQuarter, 155);

  pdf.setTextColor(paragraphParams.defaultColor);
  pdf.setFontSize(paragraphParams.fontSize);
  pdf.setFont(undefined, "normal");
  pdf.text(
    `¿Consume alcohol?:  ${capitalLetter(sheetInfo.clinicalHistory.alcohol)}`,
    paragraphParams.firstQuarter,
    163
  );
  pdf.text(
    `¿Consume tabaco?:  ${capitalLetter(sheetInfo.clinicalHistory.tobaco)} `,
    paragraphParams.thirdQuarter,
    163
  );
  pdf.text(
    `¿Con que frecuencia?: ${capitalLetter(
      sheetInfo.clinicalHistory.drinkfrequency
    )}`,
    paragraphParams.firstQuarter,
    171
  );
  pdf.text(
    `¿Con que frecuencia?: ${capitalLetter(
      sheetInfo.clinicalHistory.smokefrequency
    )}`,
    paragraphParams.thirdQuarter,
    171
  );

  pdf.setFontSize(paragraphParams.fontSize);
  pdf.setTextColor(paragraphParams.secondaryColor);
  pdf.setFont(undefined, "bold");
  pdf.text("Antecedentes gineco-obstétricos", width / 2, 179, {
    align: "center",
  });

  pdf.setFontSize(paragraphParams.fontSize);
  pdf.setTextColor(paragraphParams.primaryColor);
  pdf.setFont(undefined, "bold");
  pdf.text(
    "Antecedentes gineco-obstétricos",
    paragraphParams.firstQuarter,
    187
  );

  pdf.setTextColor(paragraphParams.defaultColor);
  pdf.setFontSize(paragraphParams.fontSize);
  pdf.setFont(undefined, "normal");
  pdf.text(
    `Primera menstruación:  ${sheetInfo.gynecologicalHistory.firstMenstruation}`,
    paragraphParams.firstQuarter,
    195
  );
  pdf.text(
    `Fecha última menstruación:  ${sheetInfo.gynecologicalHistory.lastDate} `,
    paragraphParams.secondQuarter + 15,
    195
  );
  pdf.text(
    `Años de menstruación: ${sheetInfo.gynecologicalHistory.lastMenstruation}`,
    paragraphParams.fourthQuarter - 8,
    195
  );

  pdf.setFontSize(paragraphParams.fontSize);
  pdf.setTextColor(paragraphParams.primaryColor);
  pdf.setFont(undefined, "bold");
  pdf.text("Periodo mensual", paragraphParams.firstQuarter, 203);

  pdf.setTextColor(paragraphParams.defaultColor);
  pdf.setFontSize(paragraphParams.fontSize);
  pdf.setFont(undefined, "normal");
  pdf.text(
    `Su periodo es:  ${capitalLetter(sheetInfo.gynecologicalHistory.period)}`,
    paragraphParams.firstQuarter,
    211
  );
  pdf.text(
    `Observaciones:  ${sheetInfo.gynecologicalHistory.periodsNote}`,
    paragraphParams.secondQuarter,
    211
  );

  pdf.setFontSize(paragraphParams.fontSize);
  pdf.setTextColor(paragraphParams.primaryColor);
  pdf.setFont(undefined, "bold");
  pdf.text("Anticonceptivos", paragraphParams.firstQuarter, 219);

  pdf.setTextColor(paragraphParams.defaultColor);
  pdf.setFontSize(paragraphParams.fontSize);
  pdf.setFont(undefined, "normal");
  pdf.text(
    `¿Usa anticonceptivos hormonales?:  ${capitalLetter(
      sheetInfo.gynecologicalHistory.contraceptives
    )} `,
    paragraphParams.firstQuarter,
    227
  );
  pdf.text(
    `Nombre de aticonceptivo: ${sheetInfo.gynecologicalHistory.contraceptiveName}`,
    paragraphParams.thirdQuarter - 20,
    227
  );
  pdf.text(
    `Tiempo de uso: ${sheetInfo.gynecologicalHistory.usePeriond}`,
    paragraphParams.fourthQuarter + 10,
    227
  );
  pdf.text(`Observaciones:`, paragraphParams.firstQuarter, 235);
  pdf.text(
    sheetInfo.gynecologicalHistory.notes,
    paragraphParams.firstQuarter,
    243
  );

  //NUEVA PÁGINA

  pdf.addPage();

  pdf.setFontSize(paragraphParams.fontSize);
  pdf.setTextColor(paragraphParams.secondaryColor);
  pdf.setFont(undefined, "bold");
  pdf.text("Solo en caso de ambarazo", width / 2, 20, {
    align: "center",
  });

  pdf.setTextColor(paragraphParams.defaultColor);
  pdf.setFontSize(paragraphParams.fontSize);
  pdf.setFont(undefined, "normal");
  pdf.text(
    `¿Estas embarazada en  estos momentos:  ${capitalLetter(
      sheetInfo.pregnancyCase.pregnant
    )} `,
    paragraphParams.firstQuarter,
    28
  );

  pdf.setFontSize(paragraphParams.fontSize);
  pdf.setTextColor(paragraphParams.primaryColor);
  pdf.setFont(undefined, "bold");
  pdf.text("Control prenatal", paragraphParams.firstQuarter, 36);

  pdf.setTextColor(paragraphParams.defaultColor);
  pdf.setFontSize(paragraphParams.fontSize);
  pdf.setFont(undefined, "normal");
  pdf.text(
    `¿Realizas control prenatal?:  ${capitalLetter(
      sheetInfo.pregnancyCase.prenatalConntrol
    )} `,
    paragraphParams.firstQuarter,
    44
  );
  pdf.text(
    `Fecha de inicio:  ${capitalLetter(sheetInfo.pregnancyCase.startdate)}`,
    paragraphParams.secondQuarter + 15,
    44
  );

  pdf.setFontSize(paragraphParams.fontSize);
  pdf.setTextColor(paragraphParams.primaryColor);
  pdf.setFont(undefined, "bold");
  pdf.text("Número de partos", paragraphParams.firstQuarter, 52);

  pdf.setTextColor(paragraphParams.defaultColor);
  pdf.setFontSize(paragraphParams.fontSize);
  pdf.setFont(undefined, "normal");
  pdf.text(
    `Normales:  ${sheetInfo.pregnancyCase.normalBirths} `,
    paragraphParams.firstQuarter,
    60
  );
  pdf.text(
    `Cesáreas:  ${sheetInfo.pregnancyCase.caesareanBirths} `,
    paragraphParams.secondQuarter,
    60
  );
  pdf.text(
    `Abortos:  ${sheetInfo.pregnancyCase.abortions} `,
    paragraphParams.thirdQuarter,
    60
  );

  pdf.setFontSize(paragraphParams.fontSize);
  pdf.setTextColor(paragraphParams.primaryColor);
  pdf.setFont(undefined, "bold");
  pdf.text("Lactancia", paragraphParams.firstQuarter, 68);

  pdf.setTextColor(paragraphParams.defaultColor);
  pdf.setFontSize(paragraphParams.fontSize);
  pdf.setFont(undefined, "normal");
  pdf.text(
    `¿Realizó lactancia?:  ${capitalLetter(
      sheetInfo.pregnancyCase.lactation
    )} `,
    paragraphParams.firstQuarter,
    76
  );
  pdf.text(
    `Periodo:  ${sheetInfo.pregnancyCase.lactationTime} `,
    paragraphParams.secondQuarter,
    76
  );

  pdf.setFontSize(paragraphParams.fontSize);
  pdf.setTextColor(paragraphParams.secondaryColor);
  pdf.setFont(undefined, "bold");
  pdf.text("Antecedentes en senos", width / 2, 84, {
    align: "center",
  });

  pdf.setFontSize(paragraphParams.fontSize);
  pdf.setTextColor(paragraphParams.primaryColor);
  pdf.setFont(undefined, "bold");
  pdf.text("Antecedentes", paragraphParams.firstQuarter, 92);

  pdf.setTextColor(paragraphParams.defaultColor);
  pdf.setFontSize(paragraphParams.fontSize);
  pdf.setFont(undefined, "normal");
  pdf.text(
    `¿Se auto examina los senos?:  ${capitalLetter(
      sheetInfo.breastHistory.selfExamine
    )} `,
    paragraphParams.firstQuarter,
    100
  );

  pdf.text(
    `¿Tiene secreciones en los pesones?:  ${capitalLetter(
      sheetInfo.breastHistory.secretions
    )} `,
    paragraphParams.firstQuarter,
    108
  );
  pdf.text(
    `Seno izquierdo:  ${parseBool(
      sheetInfo.breastHistory.nipplesSecretions.leftBreast
    )} `,
    paragraphParams.firstQuarter,
    116
  );
  pdf.text(
    `Seno derecho:  ${parseBool(
      sheetInfo.breastHistory.nipplesSecretions.rightBreast
    )} `,
    paragraphParams.secondQuarter,
    116
  );
  pdf.text(
    `Ambos senos:  ${parseBool(
      sheetInfo.breastHistory.nipplesSecretions.bothBreast
    )} `,
    paragraphParams.thirdQuarter,
    116
  );
  pdf.text(
    `Color y observaciones de la secreción:`,
    paragraphParams.firstQuarter,
    124
  );
  pdf.text(
    sheetInfo.breastHistory.secretionNotes,
    paragraphParams.firstQuarter,
    124
  );
  pdf.text(
    `¿Tiene protesis, implantes o moldeantes?:  ${capitalLetter(
      sheetInfo.breastHistory.prosthesis
    )} `,
    paragraphParams.firstQuarter,
    132
  );
  pdf.text(
    `¿Fecha de intervención?:  ${sheetInfo.breastHistory.prosthesisDate} `,
    paragraphParams.firstQuarter,
    140
  );
  pdf.text(
    `Observaciones:  ${sheetInfo.breastHistory.prosthesisNotes} `,
    paragraphParams.firstQuarter,
    148
  );
  pdf.text(
    `¿Se ha realizado alguna biopsia o cirugia en los senos?:  ${capitalLetter(
      sheetInfo.breastHistory.biopsy
    )} `,
    paragraphParams.firstQuarter,
    156
  );
  pdf.text(
    `¿Fecha de intervención?:  ${sheetInfo.breastHistory.biopsyDate} `,
    paragraphParams.firstQuarter,
    164
  );
  pdf.text(
    `Observaciones:  ${capitalLetter(sheetInfo.breastHistory.biopsyNotes)} `,
    paragraphParams.firstQuarter,
    172
  );

  pdf.setFontSize(paragraphParams.fontSize);
  pdf.setTextColor(paragraphParams.secondaryColor);
  pdf.setFont(undefined, "bold");
  pdf.text("Historial y estudio de senos", width / 2, 180, {
    align: "center",
  });

  pdf.setFontSize(paragraphParams.fontSize);
  pdf.setTextColor(paragraphParams.primaryColor);
  pdf.setFont(undefined, "bold");
  pdf.text("Historial", paragraphParams.firstQuarter, 188);

  pdf.setTextColor(paragraphParams.defaultColor);
  pdf.setFontSize(paragraphParams.fontSize);
  pdf.setFont(undefined, "normal");
  pdf.text(
    `Mastografia rayos X:  ${parseBool(
      sheetInfo.studyBreastHistory.mammogramX
    )} `,
    paragraphParams.firstQuarter,
    196
  );
  pdf.text(
    `Fecha de estudio:  ${
      sheetInfo.studyBreastHistory.mammogramXDate
    } `,
    paragraphParams.secondQuarter+15,
    196
  );
  pdf.text(
    `Diagnostico:  ${
      sheetInfo.studyBreastHistory.mammogramXDiagnosis
    } `,
    paragraphParams.thirdQuarter+25,
    196
  );
  pdf.text(
    `Ultrasonido:  ${parseBool(
      sheetInfo.studyBreastHistory.ultrasound
    )} `,
    paragraphParams.firstQuarter,
    204
  );
  pdf.text(
    `Fecha de estudio:  ${
      sheetInfo.studyBreastHistory.ultrasoundDate
    } `,
    paragraphParams.secondQuarter+15,
    204
  );
  pdf.text(
    `Diagnostico:  ${
      sheetInfo.studyBreastHistory.ultrasoundDiagnosis
    } `,
    paragraphParams.thirdQuarter+25,
    204
  );
  pdf.text(
    `Mastografia M3M:  ${parseBool(
      sheetInfo.studyBreastHistory.mammogramM
    )} `,
    paragraphParams.firstQuarter,
    212
  );
  pdf.text(
    `Fecha de estudio:  ${
      sheetInfo.studyBreastHistory.mammogramMDate
    } `,
    paragraphParams.secondQuarter+15,
    212
  );
  pdf.text(
    `Diagnostico:  ${
      sheetInfo.studyBreastHistory.mammogramMDiagnosis
    } `,
    paragraphParams.thirdQuarter+25,
    212
  );
  pdf.text(
    `Resonancia magnética:  ${parseBool(
      sheetInfo.studyBreastHistory.mri
    )} `,
    paragraphParams.firstQuarter,
    220
  );
  pdf.text(
    `Fecha de estudio:  ${
      sheetInfo.studyBreastHistory.mriDate
    } `,
    paragraphParams.secondQuarter+15,
    220
  );
  pdf.text(
    `Diagnostico:  ${
      sheetInfo.studyBreastHistory.mriDiagnosis
    } `,
    paragraphParams.thirdQuarter+25,
    220
  );
  pdf.text(
    `Otro:  ${parseBool(
      sheetInfo.studyBreastHistory.otherStudy
    )} `,
    paragraphParams.firstQuarter,
    228
  );
  pdf.text(
    `Nombre de estudio:  ${
      sheetInfo.studyBreastHistory.studyName
    } `,
    paragraphParams.secondQuarter-20,
    228
  );
  pdf.text(
    `Fecha de estudio:  ${
      sheetInfo.studyBreastHistory.otherDate
    } `,
    paragraphParams.thirdQuarter+15,
    228
  );
  pdf.text(
    `Diagnostico:  ${
      sheetInfo.studyBreastHistory.otherDiagnosis
    } `,
    paragraphParams.firstQuarter,
    236
  );
  pdf.text(
    `Observaciones:  ${
      sheetInfo.studyBreastHistory.anomalyNotes
    } `,
    paragraphParams.firstQuarter,
    244
  );


  if (typeof pdf.putTotalPages === "function") {
    pdf.putTotalPages(totalPagesExp);
  }
  pdf.save(`Hoja_Clinica_${patientData.phone}.pdf`);
}
