import { Container } from "@mui/material";
import CommonDashboardEvents from "./common/CommonDashboardEvents";

const DashboardPage = () => {
  return (
    <div style={{ paddingTop: "4rem" }}>
      <Container maxWidth="xxl">
        <CommonDashboardEvents />
      </Container>
    </div>
  );
};

export default DashboardPage;
