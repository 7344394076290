import { Container } from "@mui/material";
import CommonErrorsEvents from "../pages/common/CommonErrorsEvents"

const ErroPage = () => {
  return (
    <div style={{ paddingTop: "4rem" }}>
      <Container maxWidth="xxl">
        <CommonErrorsEvents />
      </Container>
    </div>
  );
};

export default ErroPage;
