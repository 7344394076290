const year = {
  value: "year",
  name: "Anual",
};
const month = {
  value: "month",
  name: "Mensual",
};
const daily = {
  value: "daily",
  name: "Día",
};

const FILTERVALUES = {
  YEAR: year,
  MONTH: month,
  DAILY: daily,
};

export function getStatusDataWithName(statusName) {
  return FILTERVALUES[
    Object.keys(FILTERVALUES).filter(
      (f) => FILTERVALUES[f].name.toString() === statusName
    )
  ];
}

export default FILTERVALUES;
