import { Container } from "@mui/material";
import CommonPermissionsEvents from "./common/CommonPermissionsEvents";

const PermissionsPage = () => {
  return (
    <div style={{ paddingTop: "4rem" }}>
      <Container maxWidth="xxl">
        <CommonPermissionsEvents />
      </Container>
    </div>
  );
};

export default PermissionsPage;