export function getHeaderSimple() {
	return {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		token: `${localStorage.getItem('USER_TOKEN')}`,
		userId: `${localStorage.getItem('USER_ID')}`, 
	};
}

export function getHeaderMultipart() {
	return {
		'Content-Type': 'multipart/form-data',
		token: `${localStorage.getItem('USER_TOKEN')}`,
		userId: `${localStorage.getItem('USER_ID')}`, 
	};
}
