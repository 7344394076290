import React from "react";
import { Route, Routes } from "react-router-dom";
import DashboardPage from "../pages/DashboardPage";
import SheePage from "../pages/SheetPage";
import ReceptionPage from "../pages/ReceptionPage";
import ResultsPage from "../pages/ResultsPage";
import HeaderRoutes from "../header/HeaderRoutes";
import { Box } from "@mui/material";
import DiagnosesPage from "../pages/DiagnosesPage";
import UsersPage from "../pages/UsersPage";
import PermissionsPage from "../pages/PermissionsPage";
import ErrorPage401 from "../pages/ErrorPage401";

const Wrapper = () => {
  return (
    <div style={{ display: "flex" }}>
      <HeaderRoutes />
      <Box component="main" style={{ width: "100%" }}>
        <Routes>
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/sheet" element={<SheePage />} />
          <Route path="/reception" element={<ReceptionPage />} />
          <Route path="/results" element={<ResultsPage />} />
          <Route path="/diagnoses" element={<DiagnosesPage />} />
          <Route path="/users" element={<UsersPage />} />
          <Route path="/permissions" element={<PermissionsPage />} />
          <Route path="/error401" element={<ErrorPage401 />} />
        </Routes>
      </Box>
    </div>
  );
};

export default Wrapper;
