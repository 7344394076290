import {
  Dashboard,
  HealthAndSafety,
  Analytics,
  MedicalInformation,
  EventNote,
  Folder,
} from "@mui/icons-material";
import TuneIcon from '@mui/icons-material/Tune';
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import PersonIcon from '@mui/icons-material/Person';
import VpnKeyIcon from '@mui/icons-material/VpnKey';

const NavbarData = [
  {
    menu: "Dashboard",
    icon: <Dashboard />,
    submenu: false,
    link: "/dashboard",
  },
  {
    menu: "Salud",
    icon: <HealthAndSafety />,
    submenu: true,
    subItem: [
      { sublabel: "Recepción", link: "/reception", icon: <EventNote /> },
      {
        sublabel: "Hoja clinica",
        link: "/sheet",
        icon: <MedicalInformation />,
      },
      { sublabel: "Resultados", link: "/results", icon: <MonitorHeartIcon /> },
      { sublabel: "Diagnósticos", link: "/diagnoses", icon: <Folder /> },
    ],
  },
  {
    menu: "Analíticas",
    icon: <Analytics />,
    submenu: true,
    subItem: [],
  },
  {
    menu: "Configuración",
    icon: <TuneIcon />,
    submenu: true,
    subItem: [
      { sublabel: "Usuarios", link: "/users", icon: <PersonIcon /> },
      { sublabel: "Permisos", link: "/permissions", icon: <VpnKeyIcon /> },
    ],
  },
];

export default NavbarData;
