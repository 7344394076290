import React, { useEffect, useState } from "react";
import { Handshake } from "@mui/icons-material";
import { Paper, Skeleton } from "@mui/material";
import RowReferredPattient from "./RowReferredPattient";
import ResumeCardSelectFilter from "./ResumeCardSelectFilter";
import { getReferredPatientDataHeader } from "../repositories/DashboardRepository";

const ReferredPatients = () => {
  const [loading, setLoading] = useState(true);
  const [referredData, setReferredData] = useState([]);

  useEffect(() => {
    getData("month");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async (type) => {
    const response = await getReferredPatientDataHeader(type);

    if (response) {
      if (response.message === "Ok") {
        setReferredData(response.content);
      } else {
        setReferredData(true);
      }
    }

    setLoading(false);
  };

  const onSelect = (type) => {
    getData(type);
  };

  return (
    <div id="div-style-16" style={{ paddingBottom: "14px" }}>
      <Paper id="paper-style-3" style={{ height: "100%" }}>
        {loading ? (
          <div id="div-style-23">
            <div id="div-style-24">
              <div id="div-style-25">
                <Skeleton variant="circular" height={"30px"} width={"30px"} />
                <Skeleton
                  variant="rectangular"
                  width={"100px"}
                  id="div-style-17"
                />
              </div>

              <Skeleton variant="rectangular" height={"40px"} width={"80px"} />
            </div>

            <div id="div-style-37">
              <div id="div-style-38">
                <Skeleton variant="rectangular" height={"130px"} />
              </div>
            </div>
          </div>
        ) : (
          <div id="div-style-23">
            <div id="div-style-24">
              <div id="div-style-25">
                <Handshake id="icon-style-5" />
                <div id="div-style-17">Pacientes referidos</div>
              </div>

              <ResumeCardSelectFilter onSelect={onSelect} />
            </div>

            <div id="div-style-37">
              {referredData.length > 0 ? (
                <div id="div-style-38">
                  {referredData.map((itemReferred) => {
                    return (
                      <RowReferredPattient
                        fundationtName={itemReferred.foundationName}
                        patientAmount={itemReferred.amount}
                      />
                    );
                  })}
                </div>
              ) : (
                <div
                  id="div-style-25"
                  style={{ marginTop: "32px", fontSize: "20px" }}
                >
                  Sin resultados
                </div>
              )}
            </div>
          </div>
        )}
      </Paper>
    </div>
  );
};

export default ReferredPatients;
