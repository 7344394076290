import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Button,
  Card,
  InputLabel,
  TextField,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useFormik } from "formik";
import Logo from "../../assets/imagesDefault";
import { createDiagnose, updateDiagnose } from "../repositories/DiagnosesRepository";
import SnackBarMessage from "../../components/SnackBarMessage";
import CONSTANTSOBSERVATIUONS from "../../assets/helpers/dummyDiagnoseConstants";

const SaveButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 18,
  borderStyle: "none",
  fontFamily: "Helvetica",
  fontWeight: "bold",
  height: "39px",
});

const DiagnoseObservations = ({ patientItem, closeSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackSeverity, setSnackSeverity] = useState("success");
  const [snackMessage, setSnackMessage] = useState("");

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      findings: "",
      diagnosticImpression: "",
    },
    onSubmit: async () => {
      saveDiagnose();
    },
  });

  useEffect(() => {
    formik.setValues({});
    formik.resetForm();

    if (patientItem !== null)  {
      const diagnoseDataExist = patientItem?.diagnoseData || null;

      if (diagnoseDataExist != null) {
        formik.setValues({
          findings: patientItem.diagnoseData.findings ?? "",
          diagnosticImpression: patientItem.diagnoseData.diagnosticImpression ?? "",
        });
      } else {
        formik.setValues({
          findings: "",
          diagnosticImpression: "",
        });
      }
    } else {
      formik.setValues({
        findings: "",
        diagnosticImpression: "",
      });
    }
    
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveDiagnose = async () => {
    setLoading(true);
    let response = null;
    const diagnoseDataExist = patientItem?.diagnoseData || null;

    if (diagnoseDataExist != null) {
      response = await updateDiagnose(
        formik.values.findings,
        formik.values.diagnosticImpression,
        patientItem.diagnoseData[0]._id,
      );
    } else {
      response = await createDiagnose(
        patientItem,
        formik.values.findings,
        formik.values.diagnosticImpression,
      );
    }

    setLoading(false);
    if (response) {
      if (response.status === "Ok") {
        closeSuccess(patientItem._id);
      } else {
        setSnackSeverity("warning");
        setSnackMessage(response.message);
        setSnackOpen(true);
      }
    }
  };

  return (
    <Card
      id="div-scrolling-style-result"
      style={{
        height: "90vh",
        overflowY: "auto",
        borderRadius: "10px",
        marginBottom: "60px",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          paddingLeft: "25px",
          paddingTop: "30px",
          paddingRight: "25px",
          paddingBottom: "30px",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <img
            width="74px"
            height="30px"
            src={Logo.LogoDefault}
            alt="study"
            style={{ borderRadius: "5px" }}
          />
          <img
            width="100px"
            height="54px"
            src={Logo.M3mLogo}
            alt="study"
            style={{ borderRadius: "5px" }}
          />
        </div>

        <div style={{ display: "flex", marginTop: "50px" }}>
          <InputLabel
            style={{
              color: "black",
              marginRight: "5px",
              fontWeight: "700",
            }}
          >
            NOMBRE DEL PACIENTE:
          </InputLabel>
          <InputLabel style={{ color: "black" }}>
            {`${patientItem.patientData.name} ${patientItem.patientData.lastname}`}
          </InputLabel>
        </div>

        <div style={{ display: "flex" }}>
          <InputLabel
            style={{
              color: "black",
              marginRight: "5px",
              fontWeight: "700",
            }}
          >
            EDAD:
          </InputLabel>
          <InputLabel
            style={{ color: "black" }}
          >{`${patientItem.patientData.age} años`}</InputLabel>
        </div>

        <div style={{ display: "flex" }}>
          <InputLabel
            style={{
              color: "black",
              marginRight: "5px",
              fontWeight: "700",
            }}
          >
            FECHA DEL ESTUDIO:
          </InputLabel>
          <InputLabel style={{ color: "black" }}>
            {moment(new Date(patientItem.receptionData.studyDate)).format(
              "DD/MM/YYYY"
            )}
          </InputLabel>
        </div>

        <div style={{ display: "flex" }}>
          <InputLabel
            style={{
              color: "black",
              marginRight: "5px",
              fontWeight: "700",
            }}
          >
            CORREO ELECTRÓNICO:
          </InputLabel>
          <InputLabel style={{ color: "black" }}>
            {patientItem.patientData.email}
          </InputLabel>
        </div>

        <div style={{ display: "flex", marginTop: "30px" }}>
          <InputLabel
            style={{
              color: "black",
              marginRight: "5px",
              fontWeight: "700",
            }}
          >
            DR. (A): A QUIEN CORRESPONDA
          </InputLabel>
        </div>

        <div style={{ display: "flex" }}>
          <InputLabel
            style={{
              color: "black",
              marginRight: "5px",
              fontWeight: "700",
            }}
          >
            MOTIVO DEL ESTUDIO:
          </InputLabel>
          <InputLabel style={{ color: "black" }}>
            {patientItem.receptionData.reason}
          </InputLabel>
        </div>

        <div style={{ display: "flex", marginTop: "30px" }}>
          <InputLabel
            style={{
              color: "black",
              marginRight: "5px",
              fontWeight: "700",
            }}
          >
            MASTOGRAFÍA POR ELECTROIMPEDANCIA MULTIFRECUENCIA:
          </InputLabel>
        </div>

        <div style={{ display: "flex" }}>
          <InputLabel
            style={{
              color: "black",
              marginRight: "5px",
              fontWeight: "700",
            }}
          >
            FÍSICA DEL ESTUDIO:
          </InputLabel>
        </div>
        <TextField
          disabled
          multiline
          value={CONSTANTSOBSERVATIUONS.FISICADELSTUDIO.value}
          variant="standard"
          InputProps={{
            disableUnderline: true,
          }}
        />

        <form onSubmit={formik.handleSubmit}>
          <div>
            <div style={{ display: "flex", marginTop: "30px" }}>
              <InputLabel
                style={{
                  color: "black",
                  marginRight: "5px",
                  fontWeight: "700",
                }}
              >
                HALLAZGOS:
              </InputLabel>
            </div>

            <div style={{ display: "flex" }}>
              <TextField
                id="findings"
                minRows={12}
                multiline
                onChange={formik.handleChange}
                value={formik.values.findings}
                onBlur={formik.handleBlur}
                autoComplete="off"
              />
            </div>

            <div style={{ display: "flex", marginTop: "30px" }}>
              <InputLabel
                style={{
                  color: "black",
                  marginRight: "5px",
                  fontWeight: "700",
                }}
              >
                IMPRESIÓN DIAGNÓSTICA:
              </InputLabel>
            </div>

            <div style={{ display: "flex" }}>
              <TextField
                id="diagnosticImpression"
                minRows={12}
                multiline
                onChange={formik.handleChange}
                value={formik.values.diagnosticImpression}
                onBlur={formik.handleBlur}
                autoComplete="off"
              />
            </div>

            {loading ? (
              <CircularProgress />
            ) : (
              <div
                style={{
                  marginTop: "32px",
                  paddingLeft: "30%",
                  paddingRight: "30%",
                }}
              >
                <SaveButton
                  variant="contained"
                  type="submit"
                  id="button-style-1"
                  style={{ marginBottom: "24px" }}
                >
                  Guardar
                </SaveButton>
              </div>
            )}
          </div>
        </form>
      </div>

      <SnackBarMessage
        severity={snackSeverity}
        message={snackMessage}
        open={snackOpen}
        onClose={() => {
          setSnackOpen(false);
        }}
      />
    </Card>
  );
};

export default DiagnoseObservations;
