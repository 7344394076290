import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Card,
  Grid,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  tableCellClasses,
  Paper,
  IconButton,
  Divider,
  Button,
  CardContent,
  CardActions,
  Tooltip,
  Fade,
} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import InputBase from "@mui/material/InputBase";
import { Search, FileDownload, EditNote } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import AddNewUser from "../offcanvas/AddNewUser";
import { deleteUser, getUsers } from "../repositories/UsersRepository";
import SnackBarMessage from "../../components/SnackBarMessage";
import PersonIcon from "@mui/icons-material/Person";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#EC7BA1",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },

  // hide last border
  " &:first-child tr, &:first-child": {
    borderTopLeftRadius: "50px",
  },
  " &:first-child tr, &:last-child": {
    borderTopRightRadius: "50px",
  },
  " &:last-child tr, &:first-child": {
    borderBottomLeftRadius: "50px",
  },
  " &:last-child tr, &:last-child": {
    borderBottomRightRadius: "50px",
  },
}));

const CommonUsersEvents = () => {
  const [usersList, setUsersList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [openUserCanvas, setOpenUserCanvas] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackSeverity, setSnackSeverity] = useState("success");
  const [snackMessage, setSnackMessage] = useState("");
  const [itemUser, setItemUser] = useState(null);
  const [onlyShow, setOnlyShow] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();

  const snackStatus = (value) => {
    if (value) {
      setSnackSeverity("success");
      setSnackMessage("La acción se ejecutó con éxito");
      setSnackOpen(true);
    } else {
      setSnackSeverity("error");
      setSnackMessage("Error al ejecutar la acción");
      setSnackOpen(true);
    }
  };
  const onlyClose = () => {
    setOpenUserCanvas(false);
    getData();
  };
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - usersList.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleClickOpen = () => {
    setOpenUserCanvas(true);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    const response = await getUsers();

    if (response) {
      if (response.status === "Ok") {
        setUsersList(response.content);
      } else if (response.status === "401") {
        setUsersList([]);
        navigate(`../error401`);
      } else {
        setUsersList([]);
      }
    }
  };

  const onCloseSuccess = () => {
    snackStatus(true);
  };

  const handleDeleteUser = async (user) => {
    const response = await deleteUser(user._id);

    if (response) {
      if (response.status === "Ok") {
        var list = [];

        for (var i = 0; i < usersList.length; i++) {
          var item = usersList[i];
          if (item._id !== user._id) {
            list.push(item);
          }
        }

        setUsersList(list);
        snackStatus(true);
      } else if (response.status === "401") {
        setUsersList([]);
        navigate(`../error401`);
      } else {
        setUsersList([]);
        snackStatus(false);
      }
    }
  };

  return (
    <div>
      <div id="div-style-8">
        <PersonIcon color={"primary"} fontSize="large" id="icon-style-1" />
        <h2>Usuarios</h2>
      </div>
      <Card id="card-style-2">
        <CardContent>
          <Grid container spacing={2} id="grid-style-2">
            <Grid sm={6}>
              <Paper component="form" id="paper-style-2">
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Buscar..."
                  inputProps={{ "aria-label": "search google maps" }}
                />
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <IconButton type="button" id="icon-style-2" aria-label="search">
                  <Search color="primary" />
                </IconButton>
              </Paper>
            </Grid>
            <Grid sm={6} id="grid-style-3">
              <Button
                component="label"
                onClick={() => {
                  handleClickOpen();
                  setIsEditing(false);
                }}
                variant="contained"
                color="secondary"
                id="button-style-3"
                style={{ marginRight: "5px" }}
              >
                <AddIcon /> Agragar
              </Button>
              <Button component="label" variant="outlined" id="button-style-3">
                <FileDownload /> Descargar
              </Button>
            </Grid>
          </Grid>
          <Grid>
            <TableContainer id="table-container-style-1">
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">No.</StyledTableCell>
                    <StyledTableCell>Nombre</StyledTableCell>
                    <StyledTableCell align="center">Cargo</StyledTableCell>
                    <StyledTableCell>Cento de trabajo </StyledTableCell>
                    <StyledTableCell>Rol</StyledTableCell>
                    <StyledTableCell>Cargo</StyledTableCell>
                    <StyledTableCell>Estado</StyledTableCell>
                    <StyledTableCell align="center">Acciones</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {usersList &&
                    usersList
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        return (
                          <StyledTableRow key={row.id}>
                            <StyledTableCell align="center">
                              {row.id}
                            </StyledTableCell>
                            <StyledTableCell>
                              {`${row.name} ${row.lastname}`}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.occupation}
                            </StyledTableCell>
                            <StyledTableCell>
                              {row.branchOffice}
                            </StyledTableCell>
                            <StyledTableCell>{row.rol}</StyledTableCell>
                            <StyledTableCell>{row.email}</StyledTableCell>
                            <StyledTableCell>{row.status}</StyledTableCell>
                            <StyledTableCell align="center">
                              <Tooltip
                                title="Editar usuario"
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                arrow
                              >
                                <IconButton
                                  aria-label="edit"
                                  size="small"
                                  color="secondary"
                                  onClick={() => {
                                    setOnlyShow(true);
                                    setIsEditing(true);
                                    setItemUser(row);
                                    handleClickOpen();
                                  }}
                                >
                                  <EditNote fontSize="medium" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip
                                title="Borrar usuario"
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                arrow
                              >
                                <IconButton
                                  aria-label="view"
                                  size="small"
                                  color="error"
                                  onClick={async () => {
                                    handleDeleteUser(row);
                                  }}
                                >
                                  <DeleteIcon fontSize="medium" />
                                </IconButton>
                              </Tooltip>
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </CardContent>
        <CardActions>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            rowsPerPage={rowsPerPage}
            page={page}
            count={usersList.length}
            component="div"
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={"Resultados por página:"}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count}`
            }
          />
        </CardActions>
      </Card>

      <AddNewUser
        openCanvas={openUserCanvas}
        onClose={onlyClose}
        itemUser={itemUser}
        onCloseSuccess={onCloseSuccess}
        isEditing={isEditing}
      />
      <SnackBarMessage
        severity={snackSeverity}
        message={snackMessage}
        open={snackOpen}
        onClose={() => {
          setSnackOpen(false);
        }}
      />
    </div>
  );
};

export default CommonUsersEvents;
