import { Container } from "@mui/material";
import CommonDiagnosesEvents from "./common/CommonDiagnosesEvents";

const DiagnosesPage = () => {
  return (
    <div style={{ paddingTop: "4rem" }}>
      <Container maxWidth="xxl">
        <CommonDiagnosesEvents />
      </Container>
    </div>
  );
};

export default DiagnosesPage;
