import React from "react";

const ResumeBarChart = ({ widgetTitle, amount }) => {
  return (
    <div id="div-style-20">
      {widgetTitle}
      <div id="div-style-13">{amount}</div>
    </div>
  );
};

export default ResumeBarChart;
