import React, { useEffect, useState } from "react";
import { Paper, Button, Skeleton, Grid } from "@mui/material";
import { BarChart } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import ReactEcharts from "echarts-for-react";
import theme from "../../assets/theme";
import ResumeCardSelectFilter from "./ResumeCardSelectFilter";

const ViewMoreButton = styled(Button)({
  boxShadow: "0px 3px 6px #00000029",
  textTransform: "none",
  fontSize: 18,
  borderStyle: "none",
  fontFamily: "Helvetica",
  fontWeight: "bold",
  color: theme.palette.pink.default,
  backgroundColor: theme.palette.gray.one,
  paddingLeft: "67px",
  paddingRight: "67px",
  borderRadius: "5px",
});

const StatisticsByAge = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData("month");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async (type) => {
    setLoading(false);
  };

  const onSelect = (type) => {
    getData(type);
  };

  return (
    <div id="div-style-16" style={{ paddingBottom: "14px" }}>
      <Paper id="paper-style-3" style={{ height: "100%" }}>
        {loading ? (
          <div
            style={{
              width: "100%",
              textAlign: "center",
              marginBottom: "10px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div id="div-style-25">
                <Skeleton variant="circular" height={"30px"} width={"30px"} />
                <Skeleton
                  variant="rectangular"
                  width={"100px"}
                  id="div-style-17"
                />
              </div>

              <Skeleton variant="rectangular" height={"40px"} width={"80px"} />
            </div>

            <div
              style={{
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "22px",
                marginBottom: "22px",
              }}
            >
              <div id="div-style-25" style={{ marginTop: "80px" }}>
                <Grid sm={12}>
                  <Skeleton variant="rectangular" height={"200px"} />
                </Grid>
              </div>
            </div>

            <div id="div-style-25" style={{ marginTop: "40px" }}>
              <Skeleton variant="rectangular" height={"48px"} width={"200px"} />
            </div>
          </div>
        ) : (
          <div id="div-style-42">
            <div id="div-style-24">
              <div id="div-style-25">
                <BarChart id="icon-style-5" />
                <div id="div-style-17">Estadísticos por edad</div>
              </div>

              <ResumeCardSelectFilter onSelect={onSelect} />
            </div>

            <ReactEcharts
              option={{
                tooltip: {
                  trigger: "item",
                },
                xAxis: {
                  type: "category",
                  data: [
                    "18 - 20",
                    "21 - 30",
                    "31 - 40",
                    "41 - 50",
                    "51 - 60",
                    "60 +",
                  ],
                },
                yAxis: {
                  type: "value",
                  min: 0,
                  interval: 10,
                },
                grid: {
                  left: "5%",
                  top: 20,
                  right: "5%",
                  bottom: 30,
                },
                series: [
                  {
                    data: [8, 17, 15, 48, 37, 63],
                    type: "bar",
                    itemStyle: {
                      color: theme.palette.pink.default,
                    },
                  },
                ],
              }}
            />

            <div style={{ marginTop: "22px" }}>
              <ViewMoreButton>Ver detalle</ViewMoreButton>
            </div>
          </div>
        )}
      </Paper>
    </div>
  );
};

export default StatisticsByAge;
