import React, { useEffect, useState } from "react";
import {
  Paper,
  Button,
  Divider,
  Tooltip,
  Fade,
  IconButton,
} from "@mui/material";
import { BarChart, Notes, Public } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import ReactEcharts from "echarts-for-react";
import theme from "../../assets/theme";
import ResumeCardSelectFilter from "./ResumeCardSelectFilter";
import * as echarts from "echarts";
import estadosJson from "../../assets/json/estadoDeMexicoYDf";
import dataMapJson from "../../assets/json/cincodelegacinesmapdata";

const ViewMoreButton = styled(Button)({
  boxShadow: "0px 3px 6px #00000029",
  textTransform: "none",
  fontSize: 18,
  borderStyle: "none",
  fontFamily: "Helvetica",
  fontWeight: "bold",
  color: theme.palette.pink.default,
  backgroundColor: theme.palette.gray.one,
  paddingLeft: "67px",
  paddingRight: "67px",
  borderRadius: "5px",
});

echarts.registerMap("USA", estadosJson, {});

dataMapJson.sort(function (a, b) {
  return a.value - b.value;
});

const MapToBarsChart = () => {
  const [isMapChartType, setIsMapChartType] = useState(true);

  const mapOption = {
    visualMap: {
      left: "right",
      min: 0,
      max: 1,
      inRange: {
        color: [
          "#313695",
          "#4575b4",
          "#74add1",
          "#abd9e9",
          "#e0f3f8",
          "#ffffbf",
          "#fee090",
          "#fdae61",
          "#f46d43",
          "#d73027",
          "#a50026",
        ],
      },
      text: ["Máx.", "Mín."],
      calculable: true,
    },
    series: [
      {
        id: "population",
        type: "map",
        map: "USA",
        animationDurationUpdate: 1000,
        universalTransition: true,
        data: dataMapJson,
        roam: "move",
        nodeClick: false,
      },
    ],
  };

  const barOption = {
    xAxis: {
      type: "value",
    },
    yAxis: {
      type: "category",
      axisLabel: {
        rotate: 30,
      },
      data: dataMapJson.map(function (item) {
        return item.name;
      }),
    },
    animationDurationUpdate: 1000,
    series: {
      type: "bar",
      id: "population",
      data: dataMapJson.map(function (item) {
        return item.value;
      }),
      universalTransition: true,
      roam: "move",
      nodeClick: false,
    },
  };

  useEffect(() => {
    // getData("month");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelect = () => {};

  return (
    <div id="div-style-16" style={{ paddingBottom: "14px" }}>
      <Paper id="paper-style-3" style={{ height: "100%" }}>
        <div id="div-style-42">
          <div id="div-style-24">
            <div id="div-style-25">
              <BarChart id="icon-style-5" />
              <div id="div-style-17">Estadísticos por cantidad de estudios</div>
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "30px",
                }}
              >
                <Tooltip
                  title="Mapa"
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: 600 }}
                  arrow
                >
                  <IconButton
                    size="small"
                    onClick={() => setIsMapChartType(true)}
                  >
                    <Public id="icon-style-5" />
                  </IconButton>
                </Tooltip>

                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  style={{
                    marginLeft: "4px",
                    marginRight: "4px",
                    height: "20px",
                  }}
                />
                <Tooltip
                  title="Gráfica de barras"
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: 600 }}
                  arrow
                >
                  <IconButton
                    size="small"
                    onClick={() => setIsMapChartType(false)}
                  >
                    <Notes id="icon-style-5" />
                  </IconButton>
                </Tooltip>
              </div>

              <ResumeCardSelectFilter onSelect={onSelect} />
            </div>
          </div>

          <ReactEcharts
            style={{ height: "70vh" }}
            option={isMapChartType ? mapOption : barOption}
          />

          <div style={{ marginTop: "22px" }}>
            <ViewMoreButton>Ver detalle</ViewMoreButton>
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default MapToBarsChart;
