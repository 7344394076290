import React from "react";
import moment from "moment";
import { Grid, Typography, InputLabel, Avatar } from "@mui/material";

const ClinicalSheet = ({ patientItem }) => {
  const upperCaseFirstLetter = (value) => {
    return value.charAt(0).toUpperCase() + value.slice(1);
  };

  const formatDate = (value) => {
    if (value === "") {
      return "No";
    } else {
      return moment(new Date(value)).format("DD/MM/YYYY");
    }
  };

  const formatEmptyValue = (value) => {
    if (value === "") {
      return "No";
    }
    return value;
  };

  return (
    <div style={{ marginBottom: "50px" }}>
      <Grid id="grid-style-4" md={12}>
        <Typography
          variant="subtitle2"
          gutterBottom
          style={{
            color: "#1AA1B9",
            fontSize: "20px",
            fontWeight: "600",
          }}
        >
          Datos personales
        </Typography>
      </Grid>

      <div>
        <div style={{ display: "flex" }}>
          <InputLabel
            style={{
              color: "#4D4D4D",
              marginLeft: "16px",
              marginRight: "5px",
            }}
          >
            Género:
          </InputLabel>
          <InputLabel style={{ color: "black", fontWeight: "700" }}>
            {patientItem.patientData.gender}
          </InputLabel>
        </div>

        <div style={{ display: "flex" }}>
          <InputLabel
            style={{
              color: "#4D4D4D",
              marginLeft: "16px",
              marginRight: "5px",
            }}
          >
            Edad:
          </InputLabel>
          <InputLabel style={{ color: "black", fontWeight: "700" }}>
            {`${patientItem.patientData.age} años`}
          </InputLabel>
        </div>

        <div style={{ display: "flex" }}>
          <InputLabel
            style={{
              color: "#4D4D4D",
              marginLeft: "16px",
              marginRight: "5px",
            }}
          >
            Nacimiento:
          </InputLabel>
          <InputLabel style={{ color: "black", fontWeight: "700" }}>
            {formatDate(patientItem.patientData.birthday)}
          </InputLabel>
        </div>

        <div style={{ display: "flex" }}>
          <InputLabel
            style={{
              color: "#4D4D4D",
              marginLeft: "16px",
              marginRight: "5px",
            }}
          >
            Peso:
          </InputLabel>
          <InputLabel style={{ color: "black", fontWeight: "700" }}>
            {`${patientItem.clinicalSheetData.clinicalHistory.weight} kg`}
          </InputLabel>
        </div>

        <div style={{ display: "flex" }}>
          <InputLabel
            style={{
              color: "#4D4D4D",
              marginLeft: "16px",
              marginRight: "5px",
            }}
          >
            Altura:
          </InputLabel>
          <InputLabel style={{ color: "black", fontWeight: "700" }}>
            {patientItem.clinicalSheetData.clinicalHistory.height}
          </InputLabel>
        </div>

        <div style={{ display: "flex" }}>
          <InputLabel
            style={{
              color: "#4D4D4D",
              marginLeft: "16px",
              marginRight: "5px",
            }}
          >
            IMC:
          </InputLabel>
          <InputLabel style={{ color: "black", fontWeight: "700" }}>
            {patientItem.clinicalSheetData.clinicalHistory.imc}
          </InputLabel>
        </div>

        <div style={{ display: "flex" }}>
          <InputLabel
            style={{
              color: "#4D4D4D",
              marginLeft: "16px",
              marginRight: "5px",
            }}
          >
            Motivo:
          </InputLabel>
          <InputLabel style={{ color: "black", fontWeight: "700" }}>
            {patientItem.receptionData.reason}
          </InputLabel>
        </div>

        <div style={{ display: "flex" }}>
          <InputLabel
            style={{
              color: "#4D4D4D",
              marginLeft: "16px",
              marginRight: "5px",
            }}
          >
            Estudio realizado:
          </InputLabel>
          <InputLabel style={{ color: "black", fontWeight: "700" }}>
            {patientItem.receptionData.studyType}
          </InputLabel>
        </div>

        <div style={{ display: "flex" }}>
          <InputLabel
            style={{
              color: "#4D4D4D",
              marginLeft: "16px",
              marginRight: "5px",
            }}
          >
            Fecha del estudio:
          </InputLabel>
          <InputLabel style={{ color: "black", fontWeight: "700" }}>
            {formatDate(patientItem.receptionData.studyDate)}
          </InputLabel>
        </div>
      </div>

      <Grid id="grid-style-6" item md={12}>
        <Typography
          variant="subtitle2"
          gutterBottom
          style={{
            color: "#1AA1B9",
            fontSize: "20px",
            fontWeight: "600",
          }}
        >
          Antecedentes toxicológicos
        </Typography>
      </Grid>

      <Grid style={{ display: "flex", alignItems: "center" }}>
        <Avatar src={null} id="avatar-dot-pink">
          {""}
        </Avatar>
        <InputLabel
          style={{
            color: "#4D4D4D",
            marginLeft: "5px",
            marginRight: "5px",
          }}
        >
          Consumo de alcohol:
        </InputLabel>
        <InputLabel style={{ color: "black", fontWeight: "700" }}>
          {patientItem.clinicalSheetData.clinicalHistory.alcohol === "no"
            ? "No"
            : patientItem.clinicalSheetData.clinicalHistory.drinkfrequency}
        </InputLabel>
      </Grid>

      <Grid style={{ display: "flex", alignItems: "center" }}>
        <Avatar src={null} id="avatar-dot-pink">
          {""}
        </Avatar>
        <InputLabel
          style={{
            color: "#4D4D4D",
            marginLeft: "5px",
            marginRight: "5px",
          }}
        >
          Consumo de tabaco:
        </InputLabel>
        <InputLabel style={{ color: "black", fontWeight: "700" }}>
          {patientItem.clinicalSheetData.clinicalHistory.tobaco === "no"
            ? "No"
            : patientItem.clinicalSheetData.clinicalHistory.smokefrequency}
        </InputLabel>
      </Grid>

      <div style={{ display: "flex", alignItems: "flex-start" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar src={null} id="avatar-dot-pink">
            {""}
          </Avatar>
          <InputLabel
            style={{
              color: "#4D4D4D",
              marginLeft: "5px",
              marginRight: "5px",
            }}
          >
            Comentarios:
          </InputLabel>
        </div>

        <div style={{ color: "black", fontWeight: "700" }}>
          {patientItem.clinicalSheetData.clinicalHistory.comments ??
            "Sin comentarios"}
        </div>
      </div>

      <Grid id="grid-style-6" item md={12}>
        <Typography
          variant="subtitle2"
          gutterBottom
          style={{
            color: "#1AA1B9",
            fontSize: "20px",
            fontWeight: "600",
          }}
        >
          Enfermedades crónico-degenerativas
        </Typography>
      </Grid>

      <Grid>
        {patientItem.clinicalSheetData.clinicalHistory.chronicDegenerative
          .breastCancer === true ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar src={null} id="avatar-dot-pink">
              {""}
            </Avatar>
            <InputLabel
              style={{
                color: "black",
                fontWeight: "700",
                marginLeft: "5px",
              }}
            >
              Cáncer de mama
            </InputLabel>
          </div>
        ) : null}

        {patientItem.clinicalSheetData.clinicalHistory.chronicDegenerative
          .colonCancer === true ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar src={null} id="avatar-dot-pink">
              {""}
            </Avatar>
            <InputLabel
              style={{
                color: "black",
                fontWeight: "700",
                marginLeft: "5px",
              }}
            >
              Cáncer de colon
            </InputLabel>
          </div>
        ) : null}

        {patientItem.clinicalSheetData.clinicalHistory.chronicDegenerative
          .ovarianCancer === true ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar src={null} id="avatar-dot-pink">
              {""}
            </Avatar>
            <InputLabel
              style={{
                color: "black",
                fontWeight: "700",
                marginLeft: "5px",
              }}
            >
              Cáncer de ovario
            </InputLabel>
          </div>
        ) : null}

        {patientItem.clinicalSheetData.clinicalHistory.chronicDegenerative
          .hypertension === true ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar src={null} id="avatar-dot-pink">
              {""}
            </Avatar>
            <InputLabel
              style={{
                color: "black",
                fontWeight: "700",
                marginLeft: "5px",
              }}
            >
              Hipertensión
            </InputLabel>
          </div>
        ) : null}

        {patientItem.clinicalSheetData.clinicalHistory.chronicDegenerative
          .thyroid === true ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar src={null} id="avatar-dot-pink">
              {""}
            </Avatar>
            <InputLabel
              style={{
                color: "black",
                fontWeight: "700",
                marginLeft: "5px",
              }}
            >
              Tiroides
            </InputLabel>
          </div>
        ) : null}

        {patientItem.clinicalSheetData.clinicalHistory.chronicDegenerative
          .diabetes === true ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar src={null} id="avatar-dot-pink">
              {""}
            </Avatar>
            <InputLabel
              style={{
                color: "black",
                fontWeight: "700",
                marginLeft: "5px",
              }}
            >
              Diabetes
            </InputLabel>
          </div>
        ) : null}

        {patientItem.clinicalSheetData.clinicalHistory.chronicDegenerative
          .lymphopro === true ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar src={null} id="avatar-dot-pink">
              {""}
            </Avatar>
            <InputLabel
              style={{
                color: "black",
                fontWeight: "700",
                marginLeft: "5px",
              }}
            >
              Linfoproliferativas (Linfona/Leucemia)
            </InputLabel>
          </div>
        ) : null}

        {patientItem.clinicalSheetData.clinicalHistory.chronicDegenerative
          .autoimmune === true ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar src={null} id="avatar-dot-pink">
              {""}
            </Avatar>
            <InputLabel
              style={{
                color: "black",
                fontWeight: "700",
                marginLeft: "5px",
              }}
            >
              Enfermedad autoinmune
            </InputLabel>
          </div>
        ) : null}
      </Grid>

      <Grid id="grid-style-6" item md={12}>
        <Typography
          variant="subtitle2"
          gutterBottom
          style={{
            color: "#1AA1B9",
            fontSize: "20px",
            fontWeight: "600",
          }}
        >
          Antecedentes oncológicos heredofamiliares
        </Typography>
      </Grid>

      <Grid style={{ display: "flex", alignItems: "center" }}>
        <Avatar src={null} id="avatar-dot-pink">
          {""}
        </Avatar>
        <InputLabel
          style={{
            color: "#4D4D4D",
            marginLeft: "5px",
            marginRight: "5px",
          }}
        >
          Cáncer de mama:
        </InputLabel>
        <InputLabel style={{ color: "black", fontWeight: "700" }}>
          {
            patientItem.clinicalSheetData.clinicalHistory.breastCancer
              .breastCancerMessage
          }
        </InputLabel>
      </Grid>

      <Grid style={{ display: "flex", alignItems: "center" }}>
        <Avatar src={null} id="avatar-dot-pink">
          {""}
        </Avatar>
        <InputLabel
          style={{
            color: "#4D4D4D",
            marginLeft: "5px",
            marginRight: "5px",
          }}
        >
          Cáncer de ovario:
        </InputLabel>
        <InputLabel style={{ color: "black", fontWeight: "700" }}>
          {
            patientItem.clinicalSheetData.clinicalHistory.ovarianCancer
              .ovarianCancerMessage
          }
        </InputLabel>
      </Grid>

      <Grid style={{ display: "flex", alignItems: "center" }}>
        <Avatar src={null} id="avatar-dot-pink">
          {""}
        </Avatar>
        <InputLabel
          style={{
            color: "#4D4D4D",
            marginLeft: "5px",
            marginRight: "5px",
          }}
        >
          Cáncer de colon:
        </InputLabel>
        <InputLabel style={{ color: "black", fontWeight: "700" }}>
          {
            patientItem.clinicalSheetData.clinicalHistory.colonCancer
              .colonCancerMessage
          }
        </InputLabel>
      </Grid>

      <Grid id="grid-style-6" item md={12}>
        <Typography
          variant="subtitle2"
          gutterBottom
          style={{
            color: "#1AA1B9",
            fontSize: "20px",
            fontWeight: "600",
          }}
        >
          Enfermedades ginecológicas
        </Typography>
      </Grid>

      {patientItem.clinicalSheetData.clinicalHistory.oncologicalHistory
        .uterus === true && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar src={null} id="avatar-dot-pink">
            {""}
          </Avatar>
          <InputLabel
            style={{
              color: "black",
              fontWeight: "700",
              marginLeft: "5px",
            }}
          >
            Útero
          </InputLabel>
        </div>
      )}

      {patientItem.clinicalSheetData.clinicalHistory.oncologicalHistory
        .ovary === true && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar src={null} id="avatar-dot-pink">
            {""}
          </Avatar>
          <InputLabel
            style={{
              color: "black",
              fontWeight: "700",
              marginLeft: "5px",
            }}
          >
            Ovario
          </InputLabel>
        </div>
      )}

      {patientItem.clinicalSheetData.clinicalHistory.oncologicalHistory
        .endometrium === true && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar src={null} id="avatar-dot-pink">
            {""}
          </Avatar>
          <InputLabel
            style={{
              color: "black",
              fontWeight: "700",
              marginLeft: "5px",
            }}
          >
            Endometrio
          </InputLabel>
        </div>
      )}

      {patientItem.clinicalSheetData.clinicalHistory.oncologicalHistory
        .otherCheck === true && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar src={null} id="avatar-dot-pink">
            {""}
          </Avatar>
          <InputLabel
            style={{
              color: "black",
              fontWeight: "700",
              marginLeft: "5px",
            }}
          >
            {
              patientItem.clinicalSheetData.clinicalHistory.oncologicalHistory
                .otherInput
            }
          </InputLabel>
        </div>
      )}

      <Grid id="grid-style-6" item md={12}>
        <Typography
          variant="subtitle2"
          gutterBottom
          style={{
            color: "#1AA1B9",
            fontSize: "20px",
            fontWeight: "600",
          }}
        >
          Antecedentes gineco-obstétricos
        </Typography>
      </Grid>

      <Grid style={{ display: "flex", alignItems: "center" }}>
        <Avatar src={null} id="avatar-dot-pink">
          {""}
        </Avatar>
        <InputLabel
          style={{
            color: "#4D4D4D",
            marginLeft: "5px",
            marginRight: "5px",
          }}
        >
          Primera menstruación:
        </InputLabel>
        <InputLabel style={{ color: "black", fontWeight: "700" }}>
          {patientItem.clinicalSheetData.gynecologicalHistory
            .firstMenstruation === ""
            ? "No"
            : `${patientItem.clinicalSheetData.gynecologicalHistory.firstMenstruation} años`}
        </InputLabel>
      </Grid>

      <Grid style={{ display: "flex", alignItems: "center" }}>
        <Avatar src={null} id="avatar-dot-pink">
          {""}
        </Avatar>
        <InputLabel
          style={{
            color: "#4D4D4D",
            marginLeft: "5px",
            marginRight: "5px",
          }}
        >
          Fecha de inicio de la última menstruación:
        </InputLabel>
        <InputLabel style={{ color: "black", fontWeight: "700" }}>
          {formatDate(
            patientItem.clinicalSheetData.gynecologicalHistory.lastDate
          )}
        </InputLabel>
      </Grid>

      {patientItem.clinicalSheetData.gynecologicalHistory.lastMenstruation !==
        0 && (
        <Grid style={{ display: "flex", alignItems: "center" }}>
          <Avatar src={null} id="avatar-dot-pink">
            {""}
          </Avatar>
          <InputLabel
            style={{
              color: "#4D4D4D",
              marginLeft: "5px",
              marginRight: "5px",
            }}
          >
            Dejó de menstruar:
          </InputLabel>
          <InputLabel style={{ color: "black", fontWeight: "700" }}>
            {
              patientItem.clinicalSheetData.gynecologicalHistory
                .lastMenstruation
            }
          </InputLabel>
        </Grid>
      )}

      <Grid style={{ display: "flex", alignItems: "center" }}>
        <Avatar src={null} id="avatar-dot-pink">
          {""}
        </Avatar>
        <InputLabel
          style={{
            color: "#4D4D4D",
            marginLeft: "5px",
            marginRight: "5px",
          }}
        >
          Anticonceptivos hormonales:
        </InputLabel>
        <InputLabel style={{ color: "black", fontWeight: "700" }}>
          {formatEmptyValue(
            patientItem.clinicalSheetData.gynecologicalHistory.contraceptiveName
          )}
        </InputLabel>
      </Grid>

      <Grid style={{ display: "flex", alignItems: "center" }}>
        <Avatar src={null} id="avatar-dot-pink">
          {""}
        </Avatar>
        <InputLabel
          style={{
            color: "#4D4D4D",
            marginLeft: "5px",
            marginRight: "5px",
          }}
        >
          Tiempo de uso:
        </InputLabel>
        <InputLabel style={{ color: "black", fontWeight: "700" }}>
          {formatEmptyValue(
            patientItem.clinicalSheetData.gynecologicalHistory.usePeriond
          )}
        </InputLabel>
      </Grid>

      <Grid style={{ display: "flex", alignItems: "center" }}>
        <Avatar src={null} id="avatar-dot-pink">
          {""}
        </Avatar>
        <InputLabel
          style={{
            color: "#4D4D4D",
            marginLeft: "5px",
            marginRight: "5px",
          }}
        >
          Periodo:
        </InputLabel>
        <InputLabel style={{ color: "black", fontWeight: "700" }}>
          {patientItem.clinicalSheetData.gynecologicalHistory.period}
        </InputLabel>
      </Grid>

      <div>
        <Grid id="grid-style-6" item md={12}>
          <Typography
            variant="subtitle2"
            gutterBottom
            style={{
              color: "#1AA1B9",
              fontSize: "20px",
              fontWeight: "600",
            }}
          >
            Embarazo
          </Typography>
        </Grid>

        <Grid style={{ display: "flex", alignItems: "center" }}>
          <Avatar src={null} id="avatar-dot-pink">
            {""}
          </Avatar>
          <InputLabel
            style={{
              color: "#4D4D4D",
              marginLeft: "5px",
              marginRight: "5px",
            }}
          >
            Inicio de control prenatal:
          </InputLabel>
          <InputLabel style={{ color: "black", fontWeight: "700" }}>
            {formatDate(patientItem.clinicalSheetData.pregnancyCase.startdate)}
          </InputLabel>
        </Grid>

        <Grid style={{ marginTop: "10px" }} item md={12}>
          <Typography variant="subtitle2" gutterBottom>
            Número de partos
          </Typography>
        </Grid>

        <Grid style={{ display: "flex", alignItems: "center" }}>
          <Avatar src={null} id="avatar-dot-pink">
            {""}
          </Avatar>
          <InputLabel
            style={{
              color: "#4D4D4D",
              marginLeft: "5px",
              marginRight: "5px",
            }}
          >
            Normales:
          </InputLabel>
          <InputLabel style={{ color: "black", fontWeight: "700" }}>
            {patientItem.clinicalSheetData.pregnancyCase.normalBirths}
          </InputLabel>
        </Grid>

        <Grid style={{ display: "flex", alignItems: "center" }}>
          <Avatar src={null} id="avatar-dot-pink">
            {""}
          </Avatar>
          <InputLabel
            style={{
              color: "#4D4D4D",
              marginLeft: "5px",
              marginRight: "5px",
            }}
          >
            Cesáreas:
          </InputLabel>
          <InputLabel style={{ color: "black", fontWeight: "700" }}>
            {patientItem.clinicalSheetData.pregnancyCase.caesareanBirths}
          </InputLabel>
        </Grid>

        <Grid style={{ display: "flex", alignItems: "center" }}>
          <Avatar src={null} id="avatar-dot-pink">
            {""}
          </Avatar>
          <InputLabel
            style={{
              color: "#4D4D4D",
              marginLeft: "5px",
              marginRight: "5px",
            }}
          >
            Abortos:
          </InputLabel>
          <InputLabel style={{ color: "black", fontWeight: "700" }}>
            {patientItem.clinicalSheetData.pregnancyCase.abortions}
          </InputLabel>
        </Grid>

        <div>
          <Grid style={{ marginTop: "10px" }} item md={12}>
            <Typography variant="subtitle2" gutterBottom>
              Lactancia
            </Typography>
          </Grid>

          <Grid style={{ display: "flex", alignItems: "center" }}>
            <Avatar src={null} id="avatar-dot-pink">
              {""}
            </Avatar>
            <InputLabel
              style={{
                color: "#4D4D4D",
                marginLeft: "5px",
                marginRight: "5px",
              }}
            >
              Fecha de inicio:
            </InputLabel>
            <InputLabel style={{ color: "black", fontWeight: "700" }}>
              {formatDate(
                patientItem.clinicalSheetData.pregnancyCase.lactationTime
              )}
            </InputLabel>
          </Grid>
        </div>
      </div>

      <Grid id="grid-style-6" item md={12}>
        <Typography
          variant="subtitle2"
          gutterBottom
          style={{
            color: "#1AA1B9",
            fontSize: "20px",
            fontWeight: "600",
          }}
        >
          Antecedentes en senos
        </Typography>
      </Grid>

      <div>
        <Grid style={{ display: "flex", alignItems: "center" }}>
          <Avatar src={null} id="avatar-dot-pink">
            {""}
          </Avatar>
          <InputLabel
            style={{
              color: "#4D4D4D",
              marginLeft: "5px",
              marginRight: "5px",
            }}
          >
            ¿Se autoexamina los senos?
          </InputLabel>
          <InputLabel style={{ color: "black", fontWeight: "700" }}>
            {upperCaseFirstLetter(
              patientItem.clinicalSheetData.breastHistory.selfExamine
            )}
          </InputLabel>
        </Grid>

        <Grid style={{ display: "flex", alignItems: "center" }}>
          <Avatar src={null} id="avatar-dot-pink">
            {""}
          </Avatar>
          <InputLabel
            style={{
              color: "#4D4D4D",
              marginLeft: "5px",
              marginRight: "5px",
            }}
          >
            Secreción en:
          </InputLabel>
          <InputLabel style={{ color: "black", fontWeight: "700" }}>
            {
              patientItem.clinicalSheetData.breastHistory.nipplesSecretions
                .nipplesSecretionsMessage
            }
          </InputLabel>
        </Grid>

        <div style={{ display: "flex", alignItems: "flex-start" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar src={null} id="avatar-dot-pink">
              {""}
            </Avatar>
            <InputLabel
              style={{
                color: "#4D4D4D",
                marginLeft: "5px",
                marginRight: "5px",
              }}
            >
              Color y comentarios de la secreción:
            </InputLabel>
          </div>

          <div style={{ color: "black", fontWeight: "700" }}>
            {formatEmptyValue(
              patientItem.clinicalSheetData.breastHistory.secretionNotes
            )}
          </div>
        </div>

        <Grid style={{ display: "flex", alignItems: "center" }}>
          <Avatar src={null} id="avatar-dot-pink">
            {""}
          </Avatar>
          <InputLabel
            style={{
              color: "#4D4D4D",
              marginLeft: "5px",
              marginRight: "5px",
            }}
          >
            ¿Tiene prótesis, implantes o moldeantes?
          </InputLabel>
          <InputLabel style={{ color: "black", fontWeight: "700" }}>
            {upperCaseFirstLetter(
              patientItem.clinicalSheetData.breastHistory.prosthesis
            )}
          </InputLabel>
        </Grid>

        <Grid style={{ display: "flex", alignItems: "center" }}>
          <Avatar src={null} id="avatar-dot-pink">
            {""}
          </Avatar>
          <InputLabel
            style={{
              color: "#4D4D4D",
              marginLeft: "5px",
              marginRight: "5px",
            }}
          >
            Fecha de realización del procedimiento:
          </InputLabel>
          <InputLabel style={{ color: "black", fontWeight: "700" }}>
            {formatDate(
              patientItem.clinicalSheetData.breastHistory.prosthesisDate
            )}
          </InputLabel>
        </Grid>

        <div style={{ display: "flex", alignItems: "flex-start" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar src={null} id="avatar-dot-pink">
              {""}
            </Avatar>
            <InputLabel
              style={{
                color: "#4D4D4D",
                marginLeft: "5px",
                marginRight: "5px",
              }}
            >
              Comentario:
            </InputLabel>
          </div>

          <div style={{ color: "black", fontWeight: "700" }}>
            {formatEmptyValue(
              patientItem.clinicalSheetData.breastHistory.prosthesisNotes
            )}
          </div>
        </div>

        <Grid style={{ display: "flex", alignItems: "center" }}>
          <Avatar src={null} id="avatar-dot-pink">
            {""}
          </Avatar>
          <InputLabel
            style={{
              color: "#4D4D4D",
              marginLeft: "5px",
              marginRight: "5px",
            }}
          >
            ¿Se ha realizado alguna biopsia o cirugía en los senos?
          </InputLabel>
          <InputLabel style={{ color: "black", fontWeight: "700" }}>
            {upperCaseFirstLetter(
              patientItem.clinicalSheetData.breastHistory.biopsy
            )}
          </InputLabel>
        </Grid>

        <Grid style={{ display: "flex", alignItems: "center" }}>
          <Avatar src={null} id="avatar-dot-pink">
            {""}
          </Avatar>
          <InputLabel
            style={{
              color: "#4D4D4D",
              marginLeft: "5px",
              marginRight: "5px",
            }}
          >
            Fecha de realización del procedimiento:
          </InputLabel>
          <InputLabel style={{ color: "black", fontWeight: "700" }}>
            {formatDate(patientItem.clinicalSheetData.breastHistory.biopsyDate)}
          </InputLabel>
        </Grid>

        <div style={{ display: "flex", alignItems: "flex-start" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar src={null} id="avatar-dot-pink">
              {""}
            </Avatar>
            <InputLabel
              style={{
                color: "#4D4D4D",
                marginLeft: "5px",
                marginRight: "5px",
              }}
            >
              Comentario:
            </InputLabel>
          </div>

          <div style={{ color: "black", fontWeight: "700" }}>
            {formatEmptyValue(
              patientItem.clinicalSheetData.breastHistory.biopsyNotes
            )}
          </div>
        </div>
      </div>

      <Grid id="grid-style-6" item md={12}>
        <Typography
          variant="subtitle2"
          gutterBottom
          style={{
            color: "#1AA1B9",
            fontSize: "20px",
            fontWeight: "600",
          }}
        >
          Historial de estudio de senos
        </Typography>
      </Grid>

      <div>
        <Grid id="grid-style-6" item md={12}>
          <Typography variant="subtitle2" gutterBottom>
            Mastografía rayos x
          </Typography>
        </Grid>

        <Grid style={{ display: "flex", alignItems: "center" }}>
          <Avatar src={null} id="avatar-dot-pink">
            {""}
          </Avatar>
          <InputLabel
            style={{
              color: "#4D4D4D",
              marginLeft: "5px",
              marginRight: "5px",
            }}
          >
            Fecha de estudio:
          </InputLabel>
          <InputLabel style={{ color: "black", fontWeight: "700" }}>
            {formatDate(
              patientItem.clinicalSheetData.studyBreastHistory.mammogramXDate
            )}
          </InputLabel>
        </Grid>

        <div style={{ display: "flex", alignItems: "flex-start" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar src={null} id="avatar-dot-pink">
              {""}
            </Avatar>
            <InputLabel
              style={{
                color: "#4D4D4D",
                marginLeft: "5px",
                marginRight: "5px",
              }}
            >
              Diagnóstico:
            </InputLabel>
          </div>

          <div style={{ color: "black", fontWeight: "700" }}>
            {formatEmptyValue(
              patientItem.clinicalSheetData.studyBreastHistory
                .mammogramXDiagnosis
            )}
          </div>
        </div>
      </div>

      <div>
        <Grid id="grid-style-6" item md={12}>
          <Typography variant="subtitle2" gutterBottom>
            Ultrasonido
          </Typography>
        </Grid>

        <Grid style={{ display: "flex", alignItems: "center" }}>
          <Avatar src={null} id="avatar-dot-pink">
            {""}
          </Avatar>
          <InputLabel
            style={{
              color: "#4D4D4D",
              marginLeft: "5px",
              marginRight: "5px",
            }}
          >
            Fecha de estudio:
          </InputLabel>
          <InputLabel style={{ color: "black", fontWeight: "700" }}>
            {formatDate(
              patientItem.clinicalSheetData.studyBreastHistory.ultrasoundDate
            )}
          </InputLabel>
        </Grid>

        <div style={{ display: "flex", alignItems: "flex-start" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar src={null} id="avatar-dot-pink">
              {""}
            </Avatar>
            <InputLabel
              style={{
                color: "#4D4D4D",
                marginLeft: "5px",
                marginRight: "5px",
              }}
            >
              Diagnóstico:
            </InputLabel>
          </div>

          <div style={{ color: "black", fontWeight: "700" }}>
            {formatEmptyValue(
              patientItem.clinicalSheetData.studyBreastHistory
                .ultrasoundDiagnosis
            )}
          </div>
        </div>
      </div>

      <div>
        <Grid id="grid-style-6" item md={12}>
          <Typography variant="subtitle2" gutterBottom>
            Mastografía M3M
          </Typography>
        </Grid>

        <Grid style={{ display: "flex", alignItems: "center" }}>
          <Avatar src={null} id="avatar-dot-pink">
            {""}
          </Avatar>
          <InputLabel
            style={{
              color: "#4D4D4D",
              marginLeft: "5px",
              marginRight: "5px",
            }}
          >
            Fecha de estudio:
          </InputLabel>
          <InputLabel style={{ color: "black", fontWeight: "700" }}>
            {formatDate(
              patientItem.clinicalSheetData.studyBreastHistory.mammogramMDate
            )}
          </InputLabel>
        </Grid>

        <div style={{ display: "flex", alignItems: "flex-start" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar src={null} id="avatar-dot-pink">
              {""}
            </Avatar>
            <InputLabel
              style={{
                color: "#4D4D4D",
                marginLeft: "5px",
                marginRight: "5px",
              }}
            >
              Diagnóstico:
            </InputLabel>
          </div>

          <div style={{ color: "black", fontWeight: "700" }}>
            {formatEmptyValue(
              patientItem.clinicalSheetData.studyBreastHistory
                .mammogramMDiagnosis
            )}
          </div>
        </div>
      </div>

      <div>
        <Grid id="grid-style-6" item md={12}>
          <Typography variant="subtitle2" gutterBottom>
            Resonancia Magnetica
          </Typography>
        </Grid>

        <Grid style={{ display: "flex", alignItems: "center" }}>
          <Avatar src={null} id="avatar-dot-pink">
            {""}
          </Avatar>
          <InputLabel
            style={{
              color: "#4D4D4D",
              marginLeft: "5px",
              marginRight: "5px",
            }}
          >
            Fecha de estudio:
          </InputLabel>
          <InputLabel style={{ color: "black", fontWeight: "700" }}>
            {formatDate(
              patientItem.clinicalSheetData.studyBreastHistory.mriDate
            )}
          </InputLabel>
        </Grid>

        <div style={{ display: "flex", alignItems: "flex-start" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar src={null} id="avatar-dot-pink">
              {""}
            </Avatar>
            <InputLabel
              style={{
                color: "#4D4D4D",
                marginLeft: "5px",
                marginRight: "5px",
              }}
            >
              Diagnóstico:
            </InputLabel>
          </div>

          <div style={{ color: "black", fontWeight: "700" }}>
            {formatEmptyValue(
              patientItem.clinicalSheetData.studyBreastHistory.mriDiagnosis
            )}
          </div>
        </div>
      </div>

      <div>
        <Grid id="grid-style-6" item md={12}>
          <Typography variant="subtitle2" gutterBottom>
            Otro
          </Typography>
        </Grid>

        <Grid style={{ display: "flex", alignItems: "center" }}>
          <Avatar src={null} id="avatar-dot-pink">
            {""}
          </Avatar>
          <InputLabel
            style={{
              color: "#4D4D4D",
              marginLeft: "5px",
              marginRight: "5px",
            }}
          >
            Fecha de estudio:
          </InputLabel>
          <InputLabel style={{ color: "black", fontWeight: "700" }}>
            {formatDate(
              patientItem.clinicalSheetData.studyBreastHistory.otherDate
            )}
          </InputLabel>
        </Grid>

        <div style={{ display: "flex", alignItems: "flex-start" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar src={null} id="avatar-dot-pink">
              {""}
            </Avatar>
            <InputLabel
              style={{
                color: "#4D4D4D",
                marginLeft: "5px",
                marginRight: "5px",
              }}
            >
              Diagnóstico:
            </InputLabel>
          </div>

          <div style={{ color: "black", fontWeight: "700" }}>
            {formatEmptyValue(
              patientItem.clinicalSheetData.studyBreastHistory.otherDiagnosis
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClinicalSheet;
