import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import { DataContext } from "../../contexts/DataContext";

const SheetFormTab4 = ({ setNextTab, showOrEdit }) => {
  const [isSecretion, setIsSecretion] = useState(true);
  const [isProthesis, setIsProthesis] = useState(true);
  const [isBiopsy, setIsBiopsy] = useState(true);
  let contextData = useContext(DataContext);
  let itemContext = contextData.clinicalSheet.breastHistory;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      selfExamine: "no",
      secretions: "no",
      leftBreast: false,
      rightBreast: false,
      bothBreast: false,
      secretionNotes: "",
      prosthesis: "no",
      prosthesisDate: "",
      prosthesisNotes: "",
      biopsy: "no",
      biopsyDate: "",
      biopsyNotes: "",
    },
    validationSchema: Yup.object().shape({
      //Aqui tengo que poner la validacion con yup
    }),
    onSubmit: async () => {
      setNextTab("5");
    },
  });

  useEffect(() => {
    const showItems = () => {
      formik.setValues({
        selfExamine: itemContext.selfExamine,
        secretions: itemContext.secretions,
        leftBreast: itemContext.nipplesSecretions.leftBreast,
        rightBreast: itemContext.nipplesSecretions.rightBreast,
        bothBreast: itemContext.nipplesSecretions.bothBreast,
        secretionNotes: itemContext.secretionNotes,
        prosthesis: itemContext.prosthesis,
        prosthesisDate: itemContext.prosthesisDate,
        prosthesisNotes: itemContext.prosthesisNotes,
        biopsy: itemContext.biopsy,
        biopsyDate: itemContext.biopsyDate,
        biopsyNotes: itemContext.biopsyNotes,
      });
    };
    showItems();
    setNextTab("4");
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNextTab]);

  return (
    <div
      id="div-scrolling-style-result"
      style={{
        overflowY: "auto",
        height: "74vh",
        paddingBottom: "100px",
      }}
    >
      <form onSubmit={formik.handleSubmit} style={{}}>
        <Grid container spacing={1}>
          <Grid id="grid-style-10" item={true} md={12}>
            <Typography variant="subtitle2" gutterBottom>
              Antecedentes en senos
            </Typography>
          </Grid>
          <Grid item={true} xs={6}>
            <FormControl>
              <FormGroup row={true}>
                <FormLabel id="formlabel-style-1">
                  <Typography variant="body2">
                    ¿Se autoexamina los senos?
                  </Typography>
                </FormLabel>
                <RadioGroup
                  id="selfExamine"
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  row={true}
                  value={formik.values.selfExamine}
                  onChange={(e) => {
                    formik.setFieldValue("selfExamine", e.target.value);
                    itemContext.selfExamine = e.target.value;
                  }}
                  onBlur={formik.handleBlur}
                >
                  <FormControlLabel
                    id="formcontrollabel-style-1"
                    value="si"
                    disabled={showOrEdit}
                    label={<Typography variant="body2">Si</Typography>}
                    control={<Radio size="small" />}
                  />
                  <FormControlLabel
                    id="formcontrollabel-style-1"
                    value="no"
                    disabled={showOrEdit}
                    label={<Typography variant="body2">No</Typography>}
                    control={<Radio size="small" />}
                  />
                </RadioGroup>
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item={true} xs={12}>
            <Divider />
          </Grid>
          <Grid item={true} xs={6}>
            <FormControl>
              <FormGroup row={true}>
                <FormLabel id="formlabel-style-1">
                  <Typography variant="body2">
                    ¿Tienes secreción en los pezones?
                  </Typography>
                </FormLabel>
                <RadioGroup
                  id="secretions"
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  row={true}
                  value={formik.values.secretions}
                  onChange={(e) => {
                    formik.setFieldValue("secretions", e.target.value);
                    itemContext.secretions = e.target.value;
                    if (e.target.value === "si") {
                      setIsSecretion(false);
                    } else {
                      setIsSecretion(true);
                    }
                  }}
                  onBlur={formik.handleBlur}
                >
                  <FormControlLabel
                    id="formcontrollabel-style-1"
                    value="si"
                    disabled={showOrEdit}
                    label={<Typography variant="body2">Si</Typography>}
                    control={<Radio size="small" />}
                  />
                  <FormControlLabel
                    id="formcontrollabel-style-1"
                    value="no"
                    disabled={showOrEdit}
                    label={<Typography variant="body2">No</Typography>}
                    control={<Radio size="small" />}
                  />
                </RadioGroup>
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid id="grid-style-9" item={true} md={12}>
            <FormControlLabel
              control={
                <Checkbox
                  id="leftBreast"
                  disabled={isSecretion || showOrEdit}
                  checked={formik.values.leftBreast}
                  onChange={(e) => {
                    formik.setFieldValue("leftBreast", e.target.checked);
                    itemContext.nipplesSecretions.leftBreast = e.target.checked;
                  }}
                  onBlur={formik.handleBlur}
                />
              }
              label="Seno izquierdo"
            />
            <FormControlLabel
              control={
                <Checkbox
                  id="rightBreast"
                  disabled={isSecretion || showOrEdit}
                  checked={formik.values.rightBreast}
                  onChange={(e) => {
                    formik.setFieldValue("rightBreast", e.target.checked);
                    itemContext.nipplesSecretions.rightBreast =
                      e.target.checked;
                  }}
                  onBlur={formik.handleBlur}
                />
              }
              label="Seno derecho"
            />
            <FormControlLabel
              control={
                <Checkbox
                  id="bothBreast"
                  disabled={isSecretion || showOrEdit}
                  checked={formik.values.bothBreast}
                  onChange={(e) => {
                    formik.setFieldValue("bothBreast", e.target.checked);
                    itemContext.nipplesSecretions.bothBreast = e.target.checked;
                  }}
                  onBlur={formik.handleBlur}
                />
              }
              label="Ambos senos"
            />
          </Grid>
          <Grid item={true} xs={12}>
            <InputLabel shrink>Color y comentarios de la secreción</InputLabel>
            <TextField
              id="secretionNotes"
              size="small"
              disabled={isSecretion || showOrEdit}
              onChange={(e) => {
                formik.setFieldValue("secretionNotes", e.target.value);
                itemContext.secretionNotes = e.target.value;
              }}
              value={formik.values.secretionNotes}
              onBlur={formik.handleBlur}
            />
          </Grid>
          <Grid item={true} xs={12} sx={{ marginTop: "15px" }}>
            <Divider />
          </Grid>
          <Grid item={true} xs={12}>
            <FormControl>
              <FormGroup row={true}>
                <FormLabel id="formlabel-style-1">
                  <Typography variant="body2">
                    ¿Tiene protesis, implantes o moldeantes?
                  </Typography>
                </FormLabel>
                <RadioGroup
                  id="prosthesis"
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  row={true}
                  value={formik.values.prosthesis}
                  onChange={(e) => {
                    formik.setFieldValue("prosthesis", e.target.value);
                    itemContext.prosthesis = e.target.value;
                    if (e.target.value === "si") {
                      setIsProthesis(false);
                    } else {
                      setIsProthesis(true);
                    }
                  }}
                  onBlur={formik.handleBlur}
                >
                  <FormControlLabel
                    id="formcontrollabel-style-1"
                    value="si"
                    disabled={showOrEdit}
                    label={<Typography variant="body2">Si</Typography>}
                    control={<Radio size="small" />}
                  />
                  <FormControlLabel
                    id="formcontrollabel-style-1"
                    value="no"
                    disabled={showOrEdit}
                    label={<Typography variant="body2">No</Typography>}
                    control={<Radio size="small" />}
                  />
                </RadioGroup>
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item={true} xs={4}>
            <InputLabel shrink>Fecha de intervención</InputLabel>
            <TextField
              type="date"
              id="prosthesisDate"
              disabled={isProthesis || showOrEdit}
              size="small"
              onChange={(e) => {
                formik.setFieldValue("prosthesisDate", e.target.value);
                itemContext.prosthesisDate = e.target.value;
              }}
              value={formik.values.prosthesisDate}
              onBlur={formik.handleBlur}
            />
          </Grid>
          <Grid item={true} xs={8}>
            <InputLabel shrink>Comentario</InputLabel>
            <TextField
              id="prosthesisNotes"
              disabled={isProthesis || showOrEdit}
              size="small"
              onChange={(e) => {
                formik.setFieldValue("prosthesisNotes", e.target.value);
                itemContext.prosthesisNotes = e.target.value;
              }}
              value={formik.values.prosthesisNotes}
              onBlur={formik.handleBlur}
            />
          </Grid>
          <Grid item={true} xs={12} sx={{ marginTop: "15px" }}>
            <Divider />
          </Grid>
          <Grid item={true} xs={12}>
            <FormControl>
              <FormGroup row={true}>
                <FormLabel id="formlabel-style-1">
                  <Typography variant="body2">
                    ¿Se ha realizado alguna biopsia o cirugía en los senos?
                  </Typography>
                </FormLabel>
                <RadioGroup
                  id="biopsy"
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  row={true}
                  value={formik.values.biopsy}
                  onChange={(e) => {
                    formik.setFieldValue("biopsy", e.target.value);
                    itemContext.biopsy = e.target.value;
                    if (e.target.value === "si") {
                      setIsBiopsy(false);
                    } else {
                      setIsBiopsy(true);
                    }
                  }}
                  onBlur={formik.handleBlur}
                >
                  <FormControlLabel
                    id="formcontrollabel-style-1"
                    value="si"
                    disabled={showOrEdit}
                    label={<Typography variant="body2">Si</Typography>}
                    control={<Radio size="small" />}
                  />
                  <FormControlLabel
                    id="formcontrollabel-style-1"
                    value="no"
                    disabled={showOrEdit}
                    label={<Typography variant="body2">No</Typography>}
                    control={<Radio size="small" />}
                  />
                </RadioGroup>
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item={true} xs={4}>
            <InputLabel shrink>Fecha de intervención</InputLabel>
            <TextField
              type="date"
              disabled={isBiopsy || showOrEdit}
              id="biopsyDate"
              size="small"
              onChange={(e) => {
                formik.setFieldValue("biopsyDate", e.target.value);
                itemContext.biopsyDate = e.target.value;
              }}
              value={formik.values.biopsyDate}
              onBlur={formik.handleBlur}
            />
          </Grid>
          <Grid item={true} xs={8}>
            <InputLabel shrink>Comentario</InputLabel>
            <TextField
              id="biopsyNotes"
              disabled={isBiopsy || showOrEdit}
              size="small"
              onChange={(e) => {
                formik.setFieldValue("biopsyNotes", e.target.value);
                itemContext.biopsyNotes = e.target.value;
              }}
              value={formik.values.biopsyNotes}
              onBlur={formik.handleBlur}
            />
          </Grid>
        </Grid>

        <Grid
          container
          sx={{ marginTop: "30px", paddingLeft: "24px", paddingRight: "24px" }}
        >
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={6}
            xs={6}
            style={{
              paddingRight: "10px",
            }}
          >
            <Button
              id="button-none-transform-color-white"
              variant="contained"
              fullWidth
              onClick={() => {
                setNextTab("3");
              }}
            >
              Atrás
            </Button>
          </Grid>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={6}
            xs={6}
            style={{
              paddingLeft: "10px",
            }}
          >
            <Button
              id="button-none-transform-color-white"
              color="secondary"
              type="submit"
              variant="contained"
              disabled={showOrEdit}
              fullWidth
            >
              Siguiente
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default SheetFormTab4;
