import ResumeDashboard from "../components/ResumeDashboard";
import MiddleInfoDashboard from "../components/MiddleInfoDashboard";
import BottomInfoDashboard from "../components/BottomInfoDashboard";

const CommonDashboardEvents = () => {
  return (
    <div>
      <div id="card-style-4" style={{ marginBottom: "36px" }}>
        <ResumeDashboard/>
        <MiddleInfoDashboard/>
        <BottomInfoDashboard/>
      </div>
    </div>
  );
};

export default CommonDashboardEvents;
