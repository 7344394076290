export const login = async (email, password) => {
  const body = {
    email: email,
    password: password,
  };

  const response = await fetch(`${process.env.REACT_APP_API_URL}auth/singin`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  const json = await response.json();

  if (json.message === "Ok") {
    localStorage.setItem("USER_TOKEN", json.content.token);
    localStorage.setItem("USER_ID", json.content.userId);
    localStorage.setItem("USER_FULL_NAME", json.content.userFullName);
    localStorage.setItem("USER_TENANT", json.content.tenant);
    localStorage.setItem("USER_IMAGE", json.content.userImage);

    return {
      status: "Ok",
      message: "Ok",
    };
  } else {
    return {
      status: "Error",
      message: json.content ?? "Error de conexión",
    };
  }
};
