import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress,
} from "@mui/material";
import * as cornerstone from "cornerstone-core";
import * as cornerstoneWADOImageLoader from "cornerstone-wado-image-loader";

cornerstoneWADOImageLoader.external.cornerstone = cornerstone;

class DialogProgressUploadFiles extends PureComponent {
  close = () => {
    this.props.onClose();
  };

  render() {
    return (
      <div>
        <Dialog open={true} aria-labelledby="alert-dialog-title">
          <DialogTitle id="alert-dialog-title">
            {"Cargando archivos..."}
          </DialogTitle>
          <DialogContent>
            <LinearProgress
              variant="determinate"
              value={this.props.progress}
              color="secondary"
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default connect(null)(DialogProgressUploadFiles);
