import { getHeaderSimple } from "./utils";

export const getReceptionDataHeader = async (type) => {
  const body = {
    type: type,
  };

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}reception/receptionDashboardData`,
    {
      method: "POST",
      headers: getHeaderSimple(),
      body: JSON.stringify(body),
    }
  );
  const json = await response.json();

  if (json.message === "Ok") {
    return {
      status: "Ok",
      message: "Ok",
      content: json.data,
    };
  } else {
    return {
      status: "Error",
      message: json.content ?? "Error de conexión",
    };
  }
};

export const getCustomerDataHeader = async (type) => {
  const body = {
    type: type,
  };

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}reception/customerDashboardData`,
    {
      method: "POST",
      headers: getHeaderSimple(),
      body: JSON.stringify(body),
    }
  );
  const json = await response.json();

  if (json.message === "Ok") {
    return {
      status: "Ok",
      message: "Ok",
      content: json.data,
    };
  } else {
    return {
      status: "Error",
      message: json.content ?? "Error de conexión",
    };
  }
};

export const getReferredPatientDataHeader = async (type) => {
  const body = {
    type: type,
  };

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}foundation/referredDashboardData`,
    {
      method: "POST",
      headers: getHeaderSimple(),
      body: JSON.stringify(body),
    }
  );
  const json = await response.json();

  if (json.message === "Ok") {
    return {
      status: "Ok",
      message: "Ok",
      content: json.data,
    };
  } else {
    return {
      status: "Error",
      message: json.content ?? "Error de conexión",
    };
  }
};
