import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { BookOutlined, GroupOutlined } from "@mui/icons-material";
import ResumeCard from "./ResumeCard";
import CircularProgressWithLabel from "../components/CircularProgressWithLabel";
import AvatarIcon from "../atoms/AvatarIcon";
import {
  getCustomerDataHeader,
  getReceptionDataHeader,
} from "../repositories/DashboardRepository";

const ResumeDashboard = () => {
  const [loadingIncomeData, setLoadingIcomeData] = useState(true);
  const [loadingEgressData, setLoadingEgressData] = useState(true);
  const [loadingReceptionData, setLoadingReceptionData] = useState(true);
  const [loadingCustomerData, setLoadingCustomerData] = useState(true);

  const [incomeData, setIncomeData] = useState(null);
  const [egressData, setEgressData] = useState(null);
  const [receptionData, setReceptionData] = useState(null);
  const [customerData, setCustomerData] = useState(null);

  const [incomeError, setIncomeError] = useState(false);
  const [egressError, setEgressError] = useState(false);
  const [receptionError, setReceptionError] = useState(false);
  const [customerError, setCustomerError] = useState(false);

  useEffect(() => {
    getIncomeData("month");
    getEgressData("month");
    getReceptionData("month");
    getCustomerData("month");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getIncomeData = async (type) => {
    setLoadingIcomeData(false);
  };

  const getEgressData = async (type) => {
    setLoadingEgressData(false);
  };

  const getReceptionData = async (type) => {
    const response = await getReceptionDataHeader(type);

    setLoadingReceptionData(false);
    if (response) {
      if (response.message === "Ok") {
        setReceptionData(response.content);
      } else {
        setReceptionError(true);
      }
    }
  };

  const getCustomerData = async (type) => {
    const response = await getCustomerDataHeader(type);

    setLoadingCustomerData(false);
    if (response) {
      if (response.message === "Ok") {
        setCustomerData(response.content);
      } else {
        setCustomerError(true);
      }
    }
  };

  const onSelectIncomeFilter = (type) => {
    getIncomeData(type);
  };

  const onSelectEgressFilter = (type) => {
    getEgressData(type);
  };

  const onSelectReceptionFilter = (type) => {
    getReceptionData(type);
  };

  const onSelectCustomersFilter = (type) => {
    getCustomerData(type);
  };

  return (
    <Grid container id="grid-style-2">
      <Grid xl={3} lg={6} md={6} sm={12} xs={12}>
        <ResumeCard
          widgetLeft={<CircularProgressWithLabel value={100} />}
          title={"Ingreso mensual"}
          subTitle={"$10,000.00"}
          percentAmount={"100%"}
          loading={loadingIncomeData}
          error={incomeError}
          onSelect={onSelectIncomeFilter}
        />
      </Grid>
      <Grid xl={3} lg={6} md={6} sm={12} xs={12}>
        <ResumeCard
          widgetLeft={<CircularProgressWithLabel value={23} />}
          title={"Egreso mensual"}
          subTitle={"$5,300.00"}
          percentAmount={"23%"}
          loading={loadingEgressData}
          error={egressError}
          onSelect={onSelectEgressFilter}
        />
      </Grid>
      <Grid xl={3} lg={6} md={6} sm={12} xs={12}>
        <ResumeCard
          widgetLeft={
            <AvatarIcon
              widgetIcon={<BookOutlined id="icon-style-3" fontSize="large" />}
            />
          }
          title={"Citas agendas"}
          subTitle={receptionData !== null ? receptionData.dateAmount : ""}
          percentAmount={
            receptionData !== null ? receptionData.datePercent + "%" : "0%"
          }
          loading={loadingReceptionData}
          error={receptionError}
          onSelect={onSelectReceptionFilter}
        />
      </Grid>
      <Grid xl={3} lg={6} md={6} sm={12} xs={12}>
        <ResumeCard
          widgetLeft={
            <AvatarIcon
              widgetIcon={<GroupOutlined id="icon-style-3" fontSize="large" />}
            />
          }
          title={"Clientes"}
          subTitle={customerData !== null ? customerData.customerAmount : ""}
          percentAmount={
            customerData !== null ? customerData.customerPercent + "%" : "0%"
          }
          loading={loadingCustomerData}
          error={customerError}
          onSelect={onSelectCustomersFilter}
        />
      </Grid>
    </Grid>
  );
};

export default ResumeDashboard;
