import React, { forwardRef, useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  IconButton,
  Card,
  Box,
  Tab,
  Typography,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { DataContextProvider } from "../../contexts/DataContext";
import SheetTab1 from "../forms/SheetFormTab1";
import SheetTab2 from "../forms/SheetFormTab2";
import SheetTab3 from "../forms/SheetFormTab3";
import SheetTab4 from "../forms/SheetFormTab4";
import SheetTab5 from "../forms/SheetFormTab5";
import { getPatientSheet } from "../repositories/SheetRepository";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const AddNewSheet = ({ openCanvas, onlyClose, itemToEdit,  snackStatus, showItems}) => {
  const [open, setOpen] = useState(false);
  const [nextTab, setNextTab] = useState("1");
  const [contextStatus, setContextStatus] = useState(false);
  const [sheetStatus, setSheetStatus] = useState(false);
  const [sheetData, setSheetData] = useState();
  const [showOrEdit, setShowOrEdit] = useState(false);

  const updateSnackStatus = (value)=>{
    snackStatus(value)
  }

  const searchPatientSheet = async (patientId) => {
    const searchResult = await getPatientSheet(patientId);
    if (searchResult.found) {
      setSheetStatus(true);
      setSheetData(searchResult.content);
    } else {
      setSheetStatus(false);
      setSheetData();
    }
  };
  const handleChange = (event, newValue) => {
    setNextTab(newValue);
  };

  const refresSheetStatus = (isSheet) => {
      setSheetStatus(!isSheet);
  };

  const handleContextStatus = () => {
    setContextStatus(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
    } else {
      setContextStatus(true);
      setOpen(false);
      setNextTab("1");
      onlyClose();
    }
  };

  useEffect(() => {
    if (itemToEdit !== null) {
      searchPatientSheet(itemToEdit._id);
    }
    setShowOrEdit(showItems);
    setOpen(openCanvas);
    setContextStatus(true);
  }, [openCanvas, itemToEdit, showItems]);

  return (
    <DataContextProvider
      value={contextStatus}
      isClinicalSheet={sheetStatus}
      clinicalItem={sheetData}
      refresSheetStatus={refresSheetStatus}
    >
      <Dialog
        open={open}
        TransitionComponent={Transition}
        fullWidth
        maxWidth="lg"
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          sx: {
            backgroundColor: "#EC7BA1",
            borderRadius: "20px 20px 0px 0px",
            position: "fixed",
            top: 10,
            left: "58%",
            width: "40%",
            height: "100%"
          },
        }}
      >
        <DialogTitle id="dialog-title-style-1">
          <Grid container flex>
            <Grid item xs={6}>
              <Typography variant="h6" color={"white"}>
                Registro hoja clinica
              </Typography>
            </Grid>
            <Grid item id="grid-style-8" xs={6}>
              <IconButton onClick={handleClose} sx={{ color: "white" }}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" color={"white"}>{`${
                itemToEdit?.name || ""
              } ${itemToEdit?.lastname || ""}`}</Typography>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent id="dialog-content-style-1">
          <Card id="card-style-3">
            <Box id="box-style-1">
              <TabContext value={nextTab}>
                <Box id="box-style-2">
                  <TabList onChange={handleChange}>
                    <Tab
                      wrapped
                      label="Antecedentes y enfermedades"
                      value="1"
                      sx={{ textTransform: "none", width: "140px" }}
                    />
                    <Tab
                      label="Antecedentes y gineco-obstétricos"
                      value="2"
                      wrapped
                      sx={{ textTransform: "none", width: "140px" }}
                    />
                    <Tab
                      wrapped
                      label="Solo en caso de embarazo"
                      value="3"
                      sx={{ textTransform: "none", width: "140px" }}
                    />
                    <Tab
                      label="Antecedentes en senos"
                      value="4"
                      wrapped
                      sx={{ textTransform: "none", width: "140px" }}
                    />
                    <Tab
                      label="Historial y estudio de senos"
                      value="5"
                      wrapped
                      sx={{ textTransform: "none", width: "140px" }}
                    />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <SheetTab1
                    nextTab={nextTab}
                    setNextTab={setNextTab}
                    handleContextStatus={handleContextStatus}
                    showOrEdit={showOrEdit}
                  />
                </TabPanel>
                <TabPanel value="2">
                  <SheetTab2 nextTab={nextTab} setNextTab={setNextTab} showOrEdit={showOrEdit} />
                </TabPanel>
                <TabPanel value="3">
                  <SheetTab3 nextTab={nextTab} setNextTab={setNextTab} showOrEdit={showOrEdit} />
                </TabPanel>
                <TabPanel value="4">
                  <SheetTab4 nextTab={nextTab} setNextTab={setNextTab} showOrEdit={showOrEdit} />
                </TabPanel>
                <TabPanel value="5">
                  <SheetTab5
                    nextTab={nextTab}
                    setNextTab={setNextTab}
                    handleClose={handleClose}
                    patientData={itemToEdit}
                    updateSnackStatus={updateSnackStatus}
                    showOrEdit={showOrEdit}
                  />
                </TabPanel>
              </TabContext>
            </Box>
          </Card>
        </DialogContent>
      </Dialog>
    </DataContextProvider>
  );
};

export default AddNewSheet;
