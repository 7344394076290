import React from "react";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { NavLink } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";

const NavbarSection = ({ item, key }) => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div style={{
      paddingLeft: '16px',
      paddingRight: '16px'
    }}>
      <List disablePadding>
        <ListItem disablePadding key={key} onClick={handleClick}>
          <ListItemIcon sx={{color: "#fff"}}>{item.icon}</ListItemIcon>
          <ListItemText> {item.menu}</ListItemText>
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      </List>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List>
          {item.subItem.map((subItem, key) => {
            return (
              <React.Fragment key={key}>
                <ListItem disablePadding key={subItem.sublabel}>
                  <ListItemButton component={NavLink} to={subItem.link}>
                    <ListItemIcon sx={{color: "#fff"}}>{subItem.icon}</ListItemIcon>
                    <ListItemText>{subItem.sublabel}</ListItemText>
                  </ListItemButton>
                </ListItem>
              </React.Fragment>
            );
          })}
        </List>
      </Collapse>
    </div>
  );
};

export default NavbarSection;
