import React, { useContext, useEffect } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import Canvas from "../components/Canvas";
import * as Yup from "yup";
import { useFormik } from "formik";
import { DataContext } from "../../contexts/DataContext";
import {
  createNewSheet,
  updatePatientStatus,
  updateCurrentSheet,
} from "../repositories/SheetRepository";

const SheetFormTab5 = ({
  setNextTab,
  handleClose,
  patientData,
  updateSnackStatus,
  showOrEdit,
}) => {
  let contextData = useContext(DataContext);
  let itemContext = contextData.clinicalSheet.studyBreastHistory;

  const actionSubmit = async () => {
    contextData.patientId = patientData._id;
    if (patientData.generalStatus === "Hoja capturada") {
      const updateSheet = await updateCurrentSheet(contextData);
      if (updateSheet.message === "Ok") {
        updateSnackStatus(true);
      } else {
        updateSnackStatus(false);
      }
    } else {
      const updatePatient = await updatePatientStatus(
        patientData._id,
        "Hoja capturada"
      );
      const createSheet = await createNewSheet(contextData);

      if (updatePatient.message === "Ok" && createSheet.message === "Ok") {
        updateSnackStatus(true);
      } else {
        updateSnackStatus(false);
      }
    }
    handleClose();
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      mammogramX: false,
      mammogramXDate: "",
      mammogramXDiagnosis: "",
      ultrasound: false,
      ultrasoundDate: "",
      ultrasoundDiagnosis: "",
      mammogramM: false,
      mammogramMDate: "",
      mammogramMDiagnosis: "",
      mri: false,
      mriDate: "",
      mriDiagnosis: "",
      other: false,
      studyName: "",
      otherDate: "",
      otherDiagnosis: "",
      anomalyPattern: "",
      anomalyNotes: "",
    },
    validationSchema: Yup.object().shape({
      //Aqui tengo que poner la validacion con yup
    }),
    onSubmit: async () => {
      actionSubmit();
    },
  });

  useEffect(() => {
    const showItems = () => {
      formik.setValues({
        mammogramX: itemContext.mammogramX,
        mammogramXDate: itemContext.mammogramXDate,
        mammogramXDiagnosis: itemContext.mammogramXDiagnosis,
        ultrasound: itemContext.ultrasound,
        ultrasoundDate: itemContext.ultrasoundDate,
        ultrasoundDiagnosis: itemContext.ultrasoundDiagnosis,
        mammogramM: itemContext.mammogramM,
        mammogramMDate: itemContext.mammogramMDate,
        mammogramMDiagnosis: itemContext.mammogramMDiagnosis,
        mri: itemContext.mri,
        mriDate: itemContext.mriDate,
        mriDiagnosis: itemContext.mriDiagnosis,
        other: itemContext.other,
        studyName: itemContext.studyName,
        otherDate: itemContext.otherDate,
        otherDiagnosis: itemContext.otherDiagnosis,
        anomalyPattern: itemContext.anomalyPattern.ImageMap,
        anomalyNotes: itemContext.anomalyNotes,
      });
    };
    showItems();
    setNextTab("5");
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNextTab]);

  return (
    <div
      id="div-scrolling-style-result"
      style={{
        overflowY: "auto",
        height: "74vh",
        paddingBottom: "100px",
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={1} sx={{ overflowY: "auto", height: "90%" }}>
          <Grid id="grid-style-10" item md={12}>
            <Typography variant="subtitle2" gutterBottom>
              Historial de estudios de senos
            </Typography>
          </Grid>
          <Grid id="grid-style-9" sx={{ marginTop: "15px" }} item md={3}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    id="mammogramX"
                    checked={formik.values.mammogramX}
                    disabled={showOrEdit}
                    onChange={(e) => {
                      formik.setFieldValue("mammogramX", e.target.checked);
                      itemContext.mammogramX = e.target.checked;
                    }}
                    onBlur={formik.handleBlur}
                  />
                }
                label="Mastografía rayos x"
              />
            </FormControl>
          </Grid>
          <Grid item md={3}>
            <InputLabel shrink>Fecha de estudio</InputLabel>
            <TextField
              type="date"
              id="mammogramXDate"
              disabled={!formik.values.mammogramX || showOrEdit}
              size="small"
              onChange={(e) => {
                formik.setFieldValue("mammogramXDate", e.target.value);
                itemContext.mammogramXDate = e.target.value;
              }}
              value={formik.values.mammogramXDate}
              onBlur={formik.handleBlur}
            />
          </Grid>
          <Grid item md={5}>
            <InputLabel shrink>¿Cual fué el diagnóstico?</InputLabel>
            <TextField
              id="mammogramXDiagnosis"
              disabled={!formik.values.mammogramX || showOrEdit}
              size="small"
              onChange={(e) => {
                formik.setFieldValue("mammogramXDiagnosis", e.target.value);
                itemContext.mammogramXDiagnosis = e.target.value;
              }}
              value={formik.values.mammogramXDiagnosis}
              onBlur={formik.handleBlur}
            />
          </Grid>
          <Grid id="grid-style-9" sx={{ marginTop: "15px" }} item md={3}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    id="ultrasound"
                    checked={formik.values.ultrasound}
                    disabled={showOrEdit}
                    onChange={(e) => {
                      formik.setFieldValue("ultrasound", e.target.checked);
                      itemContext.ultrasound = e.target.checked;
                    }}
                    onBlur={formik.handleBlur}
                  />
                }
                label="Ultrasonido"
              />
            </FormControl>
          </Grid>
          <Grid item md={3}>
            <InputLabel shrink>Fecha de estudio</InputLabel>
            <TextField
              type="date"
              disabled={!formik.values.ultrasound || showOrEdit}
              size="small"
              id="ultrasoundDate"
              onChange={(e) => {
                formik.setFieldValue("ultrasoundDate", e.target.value);
                itemContext.ultrasoundDate = e.target.value;
              }}
              value={formik.values.ultrasoundDate}
              onBlur={formik.handleBlur}
            />
          </Grid>
          <Grid item md={5}>
            <InputLabel shrink>¿Cual fué el diagnóstico?</InputLabel>
            <TextField
              id="ultrasoundDiagnosis"
              disabled={!formik.values.ultrasound || showOrEdit}
              size="small"
              onChange={(e) => {
                formik.setFieldValue("ultrasoundDiagnosis", e.target.value);
                itemContext.ultrasoundDiagnosis = e.target.value;
              }}
              value={formik.values.ultrasoundDiagnosis}
              onBlur={formik.handleBlur}
            />
          </Grid>
          <Grid id="grid-style-9" sx={{ marginTop: "15px" }} item md={3}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    id="mammogramM"
                    checked={formik.values.mammogramM}
                    disabled={showOrEdit}
                    onChange={(e) => {
                      formik.setFieldValue("mammogramM", e.target.checked);
                      itemContext.mammogramM = e.target.checked;
                    }}
                    onBlur={formik.handleBlur}
                  />
                }
                label="Mastografía M3M"
              />
            </FormControl>
          </Grid>
          <Grid item md={3}>
            <InputLabel shrink>Fecha de estudio</InputLabel>
            <TextField
              id="mammogramMDate"
              disabled={!formik.values.mammogramM || showOrEdit}
              type="date"
              size="small"
              onChange={(e) => {
                formik.setFieldValue("mammogramMDate", e.target.value);
                itemContext.mammogramMDate = e.target.value;
              }}
              value={formik.values.mammogramMDate}
              onBlur={formik.handleBlur}
            />
          </Grid>
          <Grid item md={5}>
            <InputLabel shrink>¿Cual fué el diagnóstico?</InputLabel>
            <TextField
              id="mammogramMDiagnosis"
              disabled={!formik.values.mammogramM || showOrEdit}
              size="small"
              onChange={(e) => {
                formik.setFieldValue("mammogramMDiagnosis", e.target.value);
                itemContext.mammogramMDiagnosis = e.target.value;
              }}
              value={formik.values.mammogramMDiagnosis}
              onBlur={formik.handleBlur}
            />
          </Grid>
          <Grid id="grid-style-9" sx={{ marginTop: "15px" }} item md={3}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    id="mri"
                    checked={formik.values.mri}
                    disabled={showOrEdit}
                    onChange={(e) => {
                      formik.setFieldValue("mri", e.target.checked);
                      itemContext.mri = e.target.checked;
                    }}
                    onBlur={formik.handleBlur}
                  />
                }
                label="Resonancia magnética"
              />
            </FormControl>
          </Grid>
          <Grid item md={3}>
            <InputLabel shrink>Fecha de estudio</InputLabel>
            <TextField
              id="mriDate"
              disabled={!formik.values.mri || showOrEdit}
              type="date"
              size="small"
              onChange={(e) => {
                formik.setFieldValue("mriDate", e.target.value);
                itemContext.mriDate = e.target.value;
              }}
              value={formik.values.mriDate}
              onBlur={formik.handleBlur}
            />
          </Grid>
          <Grid item md={5} sx={{ marginBottom: "18px" }}>
            <InputLabel shrink>¿Cual fué el diagnóstico?</InputLabel>
            <TextField
              id="mriDiagnosis"
              disabled={!formik.values.mri || showOrEdit}
              size="small"
              onChange={(e) => {
                formik.setFieldValue("mriDiagnosis", e.target.value);
                itemContext.mriDiagnosis = e.target.value;
              }}
              value={formik.values.mriDiagnosis}
              onBlur={formik.handleBlur}
            />
          </Grid>
          <Grid id="grid-style-9" sx={{ marginTop: "15px" }} item md={1}>
            <FormControlLabel
              control={
                <Checkbox
                  id="other"
                  checked={formik.values.other}
                  disabled={showOrEdit}
                  onChange={(e) => {
                    formik.setFieldValue("other", e.target.checked);
                    itemContext.other = e.target.checked;
                  }}
                  onBlur={formik.handleBlur}
                />
              }
              label="Otro"
            />
          </Grid>
          <Grid item md={3}>
            <InputLabel shrink>Nombre del estudio</InputLabel>
            <TextField
              id="studyName"
              disabled={!formik.values.other || showOrEdit}
              size="small"
              onChange={(e) => {
                formik.setFieldValue("studyName", e.target.value);
                itemContext.studyName = e.target.value;
              }}
              value={formik.values.studyName}
              onBlur={formik.handleBlur}
            />
          </Grid>
          <Grid item md={3}>
            <InputLabel shrink>Fecha de estudio</InputLabel>
            <TextField
              id="otherDate"
              disabled={!formik.values.other || showOrEdit}
              size="small"
              type="date"
              onChange={(e) => {
                formik.setFieldValue("otherDate", e.target.value);
                itemContext.otherDate = e.target.value;
              }}
              value={formik.values.otherDate}
              onBlur={formik.handleBlur}
            />
          </Grid>
          <Grid item md={4}>
            <InputLabel shrink>¿Cual fué el diagnóstico?</InputLabel>
            <TextField
              id="otherDiagnosis"
              disabled={!formik.values.other || showOrEdit}
              size="small"
              onChange={(e) => {
                formik.setFieldValue("otherDiagnosis", e.target.value);
                itemContext.otherDiagnosis = e.target.value;
              }}
              value={formik.values.otherDiagnosis}
              onBlur={formik.handleBlur}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
            <InputLabel shrink>Marque en caso de anomalía</InputLabel>
            <Canvas showOrEdit={showOrEdit} />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
            <InputLabel shrink>Comentarios y observaciones</InputLabel>
            <TextField
              minRows={7}
              maxRows={7}
              multiline
              id="anomalyNotes"
              disabled={showOrEdit}
              InputProps={{ disableUnderline: true }}
              onChange={(e) => {
                formik.setFieldValue("anomalyNotes", e.target.value);
                itemContext.anomalyNotes = e.target.value;
              }}
              value={formik.values.anomalyNotes}
              onBlur={formik.handleBlur}
            />
          </Grid>
        </Grid>
        <Grid
          container
          sx={{ marginTop: "30px", paddingLeft: "24px", paddingRight: "24px" }}
        >
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={6}
            xs={6}
            style={{
              paddingRight: "10px",
            }}
          >
            <Button
              id="button-none-transform-color-white"
              variant="contained"
              fullWidth
              onClick={() => {
                setNextTab("4");
              }}
            >
              Atras
            </Button>
          </Grid>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={6}
            xs={6}
            style={{
              paddingLeft: "10px",
            }}
          >
            <Button
               id="button-none-transform-color-white"
               color="secondary"
               type="submit"
               variant="contained"
               disabled={showOrEdit}
               fullWidth
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default SheetFormTab5;
