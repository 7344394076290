import { Card, CardContent, Grid } from "@mui/material";
import Logo from "../../assets/imagesDefault";
import LoginForm from "../forms/LoginForm";
import { ImageWomanLogin } from "../components/ImageWomanLogin";

const CommonLoginEvents = () => {


  return (
    <div id="div-style-1">
      <Grid
        container
        spacing={0}
        alignItems="center"
        justify="center"
        id="grid-style-1"
      >
        <Grid
          xl={6}
          lg={8}
          md={11}
          sm={11}
          xs={11}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
          }}
        >
          <Card id="card-style-1">
            <CardContent id="card-content-style-1">
              <Grid xl={6} lg={6} md={5} sm={0} xs={0}>
                <ImageWomanLogin />
              </Grid>

              <Grid
                id="div-style-3"
                xl={6}
                lg={6}
                md={7}
                sm={10}
                xs={11}
                style={{ margin: "auto" }}
              >
                <img height="66px" src={Logo.LogoDefault} alt="Logo XMI" />
                <div id="div-style-4">
                  <text id="text-style-1">Mastografía </text>
                  <text id="text-style-2">sin radiación 100% seguro </text>
                  <text id="text-style-1">y sin dolor</text>
                </div>

                <text id="text-style-3">¡Hola!</text>
                <div id="div-style-5">
                  <text id="text-style-4">
                    Ingresa tus datos para continuar
                  </text>
                </div>
                <LoginForm />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default CommonLoginEvents;
