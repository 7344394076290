import React, { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({
  title,
  message,
  openDialog,
  closeDialog,
  executeAction,
}) {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    closeDialog();
  };

  useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);
  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        fullWidth={"sm"}
        maxWidth={"sm"}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color={"secondary"} onClick={handleClose}>
            <CloseIcon />
            Cerrar
          </Button>
          <Button
            color={"error"}
            onClick={() => {
              handleClose();
              executeAction();
            }}
          >
            {" "}
            <DeleteIcon />
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
