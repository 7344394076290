import { getHeaderMultipart, getHeaderSimple } from "./utils";
import axios from "axios";

export const getResults = async () => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}result/patients/data`,
    {
      method: "GET",
      headers: getHeaderSimple(),
    }
  );
  const json = await response.json();

  if (json.message === "Ok") {
    return {
      status: "Ok",
      message: "Ok",
      content: json.data,
    };
  } else {
    return {
      status: "Error",
      message: json.content ?? "Error de conexión",
    };
  }
};

export const createResult = async (patientItem, images) => {
  const formData = new FormData();

  formData.append("patientId", patientItem._id);
  formData.append("clinicalSheetId", patientItem.clinicalSheetData._id,);
  formData.append("receptionId", patientItem.receptionData._id,);

  for (let q = 0; q < images.length; q += 1) {
    const itemQ = images[q];
    formData.append("image", itemQ.file);
  }

  return await axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}result`,
    data: formData,
    headers: getHeaderMultipart(),
  });
};
