import React, { forwardRef, useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  IconButton,
  Card,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import GeneralDataForm from "../forms/GeneralDataForm";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const AddNewGeneralData = ({
  openCanvas,
  onClose,
  receptionItem,
  onCloseSuccess,
}) => {
  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
    } else {
      setOpen(false);
      onClose();
    }
  };

  useEffect(() => {
    setOpen(openCanvas);
  }, [openCanvas]);

  const onCloseSuccessForm = (value) => {
    onCloseSuccess(value);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullWidth
      maxWidth="md"
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        sx: {
          backgroundColor: "#EC7BA1",
          borderRadius: "20px 20px 0px 0px",
          position: "fixed",
          right: 0,
          bottom: 0,
          marginRight: 0,
          marginBottom: 0,
          overflow: "hidden",
        },
      }}
    >
      <DialogTitle id="dialog-title-style-1">
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h6" style={{ color: "white" }}>
              Registro de datos personales
            </Typography>
          </Grid>
          <Grid item id="grid-style-8" xs={6}>
            <IconButton onClick={handleClose} sx={{ color: "white" }}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent id="dialog-content-style-1">
        <Card id="card-style-3">
          <Box id="box-style-2">
            <GeneralDataForm
              receptionItem={receptionItem}
              onCloseSuccess={onCloseSuccessForm}
            />
          </Box>
        </Card>
      </DialogContent>
    </Dialog>
  );
};

export default AddNewGeneralData;
