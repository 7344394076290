import React from "react";

const RowAverageResult = ({
  establishmentName,
  studiesAmount,
  averageHour,
}) => {
  return (
    <div id="div-style-31">
      <div id="div-style-32">
        <div id="div-style-33">{establishmentName}</div>
        <div id="div-style-8">
          <div id="div-style-34">Estudios realizados</div>
          <div id="div-style-35">{studiesAmount}</div>
        </div>
      </div>
      <div id="div-style-36">{averageHour + " Hrs"}</div>
    </div>
  );
};

export default RowAverageResult;
