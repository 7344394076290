import React, { forwardRef, useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  IconButton,
  Card,
  Box,
  Grid,
  Typography,
  Tabs,
  Tab,
  Chip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { TabContext, TabPanel } from "@mui/lab";
import ClinicalSheet from "../components/ClinicalSheet";
import StudyImages from "../components/StudyImages";
import { Person } from "@mui/icons-material";
import DiagnoseObservations from "../components/DiagnoseObservations";
import store from "../../store";
import { Provider } from "react-redux";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const AddNewDiagnose = ({
  openCanvas,
  onlyClose,
  patientItem,
  closeSuccess,
}) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
    } else {
      setOpen(false);
      onlyClose();
    }
  };

  useEffect(() => {
    setOpen(openCanvas);
  }, [openCanvas, patientItem]);

  const onCloseSuccessForm = (value) => {
    closeSuccess(value);
    handleClose();
  };

  return (
    <>
      {openCanvas ? (
        <Provider store={store}>
          <Dialog
            fullScreen
            open={open}
            TransitionComponent={Transition}
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            PaperProps={{
              sx: {
                backgroundColor: "#EC7BA1",
                borderRadius: "20px 20px 0px 0px",
                marginLeft: "5%",
              },
            }}
          >
            <DialogTitle id="dialog-title-style-1">
              <Grid container flex>
                <Grid
                  item
                  xs={8}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Person style={{ marginRight: "6px", color: "white" }} />
                  <Typography variant="h6" style={{ color: "white" }}>
                    {`${patientItem.patientData.name} ${patientItem.patientData.lastname}`}
                  </Typography>
                </Grid>
                <Grid
                  item
                  id="grid-style-8"
                  xs={4}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Chip
                    label={patientItem.receptionData.studyType}
                    color="secondary"
                    style={{ marginRight: "24px", borderRadius: "3px" }}
                  />
                  <Grid item id="grid-style-8">
                    <IconButton onClick={handleClose} sx={{ color: "white" }}>
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </DialogTitle>

            <DialogContent
              id="div-scrolling-style-result"
              style={{ padding: "0" }}
            >
              <Card id="card-style-5">
                <Typography
                  gutterBottom
                  style={{
                    fontSize: "26px",
                    fontWeight: "600",
                    marginLeft: "22px",
                    marginTop: "34px",
                    marginBottom: "22px",
                  }}
                >
                  Registro de diagnóstico
                </Typography>
                <Box id="box-style-2">
                  <Grid
                    container
                    spacing={1}
                    style={{
                      marginTop: "16px",
                      paddingLeft: "18px",
                      paddingRight: "12px",
                    }}
                  >
                    <Grid item md={7} /* id="card-style-5" */ style={{}}>
                      <Card
                        id="div-scrolling-style-result"
                        style={{
                          height: "90vh",
                          overflowY: "auto",
                          borderRadius: "10px",
                          marginBottom: "60px",
                        }}
                      >
                        <TabContext value={value}>
                          <Box>
                            <Tabs
                              value={value}
                              onChange={handleChange}
                              variant="fullWidth"
                            >
                              <Tab
                                wrapped
                                label="Hoja clínica"
                                value="1"
                                sx={{ textTransform: "none" }}
                              />
                              <Tab
                                label="Resultados de estudios"
                                value="2"
                                wrapped
                                sx={{ textTransform: "none" }}
                              />
                            </Tabs>
                          </Box>
                          <TabPanel value="1">
                            <ClinicalSheet patientItem={patientItem} />
                          </TabPanel>
                          <TabPanel value="2">
                            <StudyImages patientItem={patientItem} />
                          </TabPanel>
                        </TabContext>
                      </Card>
                    </Grid>

                    <Grid item md={5} style={{}}>
                      <DiagnoseObservations
                        patientItem={patientItem}
                        closeSuccess={onCloseSuccessForm}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            </DialogContent>
          </Dialog>
        </Provider>
      ) : null}
    </>
  );
};

export default AddNewDiagnose;
