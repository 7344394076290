import React from "react";

const RowStatisticsOfResults = ({ title, percent }) => {
  return (
    <div
      style={{
        display: "flex",
        paddingTop: "7px",
        paddingBottom: "7px",
      }}
    >
      <div
        style={{
          fontSize: "18px",
          color: "#5B5B5C",
        }}
      >
        {title}
      </div>
      <div
        style={{
          fontSize: "18px",
          fontWeight: "700",
          marginLeft: "10px",
        }}
      >
        {percent}
      </div>
    </div>
  );
};

export default RowStatisticsOfResults;
