import { createContext } from "react";

let data = [];
let structure = [];
export const DataContext = createContext();

export function DataContextProvider(props) {
  const cleanContext = props.value;
  const isClinicalSheet = props.isClinicalSheet;
  const clinicalItem = props.clinicalItem;
  const refresSheetStatus = props.refresSheetStatus;

  if (isClinicalSheet) {
    const sheetItem = clinicalItem[0];
    structure = {
      _id: sheetItem._id,
      patientId: sheetItem.patientId,
      clinicalSheet: {
        //TAB 1
        clinicalHistory: {
          weight: sheetItem.clinicalHistory.weight,
          height: sheetItem.clinicalHistory.height,
          imc: sheetItem.clinicalHistory.imc,
          imcColor: sheetItem.clinicalHistory.imcColor,
          imcStatus: sheetItem.clinicalHistory.imcStatus,
          alcohol: sheetItem.clinicalHistory.alcohol,
          drinkfrequency: sheetItem.clinicalHistory.drinkfrequency,
          tobaco: sheetItem.clinicalHistory.tobaco,
          smokefrequency: sheetItem.clinicalHistory.smokefrequency,
          chronicDegenerative: {
            breastCancer:
              sheetItem.clinicalHistory.chronicDegenerative.breastCancer,
            colonCancer:
              sheetItem.clinicalHistory.chronicDegenerative.colonCancer,
            ovarianCancer:
              sheetItem.clinicalHistory.chronicDegenerative.ovarianCancer,
            hypertension:
              sheetItem.clinicalHistory.chronicDegenerative.hypertension,
            thyroid: sheetItem.clinicalHistory.chronicDegenerative.thyroid,
            diabetes: sheetItem.clinicalHistory.chronicDegenerative.diabetes,
            lymphopro: sheetItem.clinicalHistory.chronicDegenerative.lymphopro,
            autoimmune:
              sheetItem.clinicalHistory.chronicDegenerative.autoimmune,
          },
          breastCancer: {
            breastMother: sheetItem.clinicalHistory.breastCancer.breastMother,
            breastFather: sheetItem.clinicalHistory.breastCancer.breastFather,
            breastSiblings:
              sheetItem.clinicalHistory.breastCancer.breastSiblings,
            breastChildren:
              sheetItem.clinicalHistory.breastCancer.breastChildren,
          },
          colonCancer: {
            colonMother: sheetItem.clinicalHistory.colonCancer.colonMother,
            colonFather: sheetItem.clinicalHistory.colonCancer.colonFather,
            colonSiblings: sheetItem.clinicalHistory.colonCancer.colonSiblings,
            colonChildren: sheetItem.clinicalHistory.colonCancer.colonChildren,
          },
          ovarianCancer: {
            ovarianMother:
              sheetItem.clinicalHistory.ovarianCancer.ovarianMother,
            ovarianFather:
              sheetItem.clinicalHistory.ovarianCancer.ovarianFather,
            ovarianSiblings:
              sheetItem.clinicalHistory.ovarianCancer.ovarianSiblings,
            ovarianChildren:
              sheetItem.clinicalHistory.ovarianCancer.ovarianChildren,
          },
          oncologicalHistory: {
            uterus: sheetItem.clinicalHistory.oncologicalHistory.uterus,
            ovary: sheetItem.clinicalHistory.oncologicalHistory.ovary,
            endometrium:
              sheetItem.clinicalHistory.oncologicalHistory.endometrium,
            otherCheck: sheetItem.clinicalHistory.oncologicalHistory.otherCheck,
            otherInput: sheetItem.clinicalHistory.oncologicalHistory.otherInput,
          },
        },
        //TAB 2
        gynecologicalHistory: {
          firstMenstruation: sheetItem.gynecologicalHistory.firstMenstruation,
          lastDate: sheetItem.gynecologicalHistory.lastDate,
          lastMenstruation: sheetItem.gynecologicalHistory.lastMenstruation,
          period: sheetItem.gynecologicalHistory.period,
          periodsNote: sheetItem.gynecologicalHistory.periodsNote,
          contraceptives: sheetItem.gynecologicalHistory.contraceptives,
          contraceptiveName: sheetItem.gynecologicalHistory.contraceptiveName,
          usePeriond: sheetItem.gynecologicalHistory.usePeriond,
          notes: sheetItem.gynecologicalHistory.notes,
        },
        //TAB 3
        pregnancyCase: {
          pregnant: sheetItem.pregnancyCase.pregnant,
          prenatalConntrol: sheetItem.pregnancyCase.prenatalConntrol,
          startdate: sheetItem.pregnancyCase.startdate,
          normalBirths: sheetItem.pregnancyCase.normalBirths,
          caesareanBirths: sheetItem.pregnancyCase.caesareanBirths,
          abortions: sheetItem.pregnancyCase.abortions,
          lactation: sheetItem.pregnancyCase.lactation,
          lactationTime: sheetItem.pregnancyCase.lactationTime,
        },
        //TAB 4
        breastHistory: {
          selfExamine: sheetItem.breastHistory.selfExamine,
          secretions: sheetItem.breastHistory.secretions,
          nipplesSecretions: {
            leftBreast: sheetItem.breastHistory.nipplesSecretions.leftBreast,
            rightBreast: sheetItem.breastHistory.nipplesSecretions.rightBreast,
            bothBreast: sheetItem.breastHistory.nipplesSecretions.bothBreast,
          },
          secretionNotes: sheetItem.breastHistory.secretionNotes,
          prosthesis: sheetItem.breastHistory.prosthesis,
          prosthesisDate: sheetItem.breastHistory.prosthesisDate,
          prosthesisNotes: sheetItem.breastHistory.prosthesisNotes,
          biopsy: sheetItem.breastHistory.biopsy,
          biopsyDate: sheetItem.breastHistory.biopsyDate,
          biopsyNotes: sheetItem.breastHistory.biopsyNotes,
        },
        //TAB 5
        studyBreastHistory: {
          mammogramX: sheetItem.studyBreastHistory.mammogramX,
          mammogramXDate: sheetItem.studyBreastHistory.mammogramXDate,
          mammogramXDiagnosis: sheetItem.studyBreastHistory.mammogramXDiagnosis,
          ultrasound: sheetItem.studyBreastHistory.ultrasound,
          ultrasoundDate: sheetItem.studyBreastHistory.ultrasoundDate,
          ultrasoundDiagnosis: sheetItem.studyBreastHistory.ultrasoundDiagnosis,
          mammogramM: sheetItem.studyBreastHistory.mammogramM,
          mammogramMDate: sheetItem.studyBreastHistory.mammogramMDate,
          mammogramMDiagnosis: sheetItem.studyBreastHistory.mammogramMDiagnosis,
          mri: sheetItem.studyBreastHistory.mri,
          mriDate: sheetItem.studyBreastHistory.mriDate,
          mriDiagnosis: sheetItem.studyBreastHistory.mriDiagnosis,
          otherStudy: sheetItem.studyBreastHistory.otherStudy,
          studyName: sheetItem.studyBreastHistory.studyName,
          otherDate: sheetItem.studyBreastHistory.otherDate,
          otherDiagnosis: sheetItem.studyBreastHistory.otherDiagnosis,
          anomalyPattern: {
            ImageMap: sheetItem.studyBreastHistory.anomalyPattern.ImageMap,
          },
          anomalyNotes: sheetItem.studyBreastHistory.anomalyNotes,
        },
      },
    };
    refresSheetStatus(isClinicalSheet);
  } else {
    if (cleanContext === true) {
      structure = {
        patientId: "",
        clinicalSheet: {
          //TAB 1
          capturaStatus: false,
          clinicalHistory: {
            weight: "",
            height: "",
            imc: "",
            imcColor: "#1D0BD7",
            imcStatus: "Sin medida",
            alcohol: "no",
            drinkfrequency: "",
            tobaco: "no",
            smokefrequency: "",
            chronicDegenerative: {
              breastCancer: false,
              colonCancer: false,
              ovarianCancer: false,
              hypertension: false,
              thyroid: false,
              diabetes: false,
              lymphopro: false,
              autoimmune: false,
            },
            breastCancer: {
              breastMother: false,
              breastFather: false,
              breastSiblings: false,
              breastChildren: false,
            },
            colonCancer: {
              colonMother: false,
              colonFather: false,
              colonSiblings: false,
              colonChildren: false,
            },
            ovarianCancer: {
              ovarianMother: false,
              ovarianFather: false,
              ovarianSiblings: false,
              ovarianChildren: false,
            },
            oncologicalHistory: {
              uterus: false,
              ovary: false,
              endometrium: false,
              otherCheck: false,
              otherInput: "",
            },
          },
          //TAB 2
          gynecologicalHistory: {
            firstMenstruation: "",
            lastDate: "",
            lastMenstruation: 0,
            period: "regular",
            periodsNote: "",
            contraceptives: "no",
            contraceptiveName: "",
            usePeriond: 0,
            notes: "",
          },
          //TAB 3
          pregnancyCase: {
            pregnant: "no",
            prenatalConntrol: "no",
            startdate: "",
            normalBirths: 0,
            caesareanBirths: 0,
            abortions: 0,
            lactation: "no",
            lactationTime: 0,
          },
          //TAB 4
          breastHistory: {
            selfExamine: "no",
            secretions: "no",
            nipplesSecretions: {
              leftBreast: false,
              rightBreast: false,
              bothBreast: false,
            },
            secretionNotes: "",
            prosthesis: "no",
            prosthesisDate: "",
            prosthesisNotes: "",
            biopsy: "no",
            biopsyDate: "",
            biopsyNotes: "",
          },
          //TAB 5
          studyBreastHistory: {
            mammogramX: false,
            mammogramXDate: "",
            mammogramXDiagnosis: "",
            ultrasound: false,
            ultrasoundDate: "",
            ultrasoundDiagnosis: "",
            mammogramM: false,
            mammogramMDate: "",
            mammogramMDiagnosis: "",
            mri: false,
            mriDate: "",
            mriDiagnosis: "",
            otherStudy: false,
            studyName: "",
            otherDate: "",
            otherDiagnosis: "",
            anomalyPattern: {
              ImageMap: "",
            },
            anomalyNotes: "",
          },
        },
      };
    }
  }

  data = structure;

  return (
    <DataContext.Provider value={data}>{props.children}</DataContext.Provider>
  );
}
