import LoginPage from "../src/pages/LoginPage";
import { Route, Routes } from "react-router-dom";
import Wrapper from "./wrapper/Wrapper";
import CompareStudiesPage from "./pages/CompareStudiesPage";

// Import css
import "./assets/scss/app.scss";

export default function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/compare" element={<CompareStudiesPage />} />
      </Routes>
      <Wrapper />
    </div>
  );
}
