import { getHeaderSimple } from "./utils";

export const getSheets = async () => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}clinicalsheet/general/data`,
    {
      method: "GET",
      headers: getHeaderSimple(),
    }
  );
  const json = await response.json();

  if (json.message === "Ok") {
    return {
      status: "Ok",
      message: "Ok",
      content: json.data,
    };
  } else {
    return {
      status: "Error",
      message: json.content ?? "Error de conexión",
    };
  }
};

export const getPatientSheet = async (patientId) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}clinicalsheet/${patientId}`,
    {
      method: "GET",
      headers: getHeaderSimple(),
    }
  );
  const json = await response.json();

  if (json.message === "Ok") {
    if (json.data.length === 0) {
      return {
        status: "Ok",
        found: false,
        message: "Ok",
      };
    } else {
      return {
        status: "Ok",
        found: true,
        message: "Ok",
        content: json.data,
      };
    }
  } else {
    return {
      status: "Error",
      message: json.content ?? "Error de conexión",
    };
  }
};
export const createNewSheet = async (patientObject) => {
  const patientItem = patientObject;

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}clinicalsheet/`,
    {
      method: "POST",
      headers: getHeaderSimple(),
      body: JSON.stringify(patientItem),
    }
  );
  const json = await response.json();

  if (json.message === "Ok") {
    return {
      status: "Ok",
      message: "Ok",
      content: json.data,
    };
  } else {
    return {
      status: "Error",
      message: json.data ?? "Error de conexión",
    };
  }
};

export const updatePatientStatus = async (patientId, patientObject) => {
  const body = {
    generalStatus: patientObject,
  };

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}patient/updatestatus/${patientId}`,
    {
      method: "PUT",
      headers: getHeaderSimple(),
      body: JSON.stringify(body),
    }
  );

  const json = await response.json();

  if (json.message === "Ok") {
    return {
      status: "Ok",
      message: "Ok",
      content: json.content,
    };
  } else {
    return {
      status: "Error",
      message: json.content ?? "Error de conexión",
    };
  }
};

export const updateCurrentSheet = async (sheetObject) => {
  const sheetId = sheetObject._id;
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}clinicalsheet/updatesheet/${sheetId}`,
    {
      method: "PUT",
      body: JSON.stringify(sheetObject),
      headers: getHeaderSimple(),
    }
  );

  const json = await response.json();

  if (json.message === "Ok") {
    
    return {
      status: "Ok",
      message: "Ok",
      content: json.data,
    };
  } else {
    return {
      status: "Error",
      message: json.content ?? "Error de conexión",
    };
  }
};
