import React, { useEffect, useState, Children } from "react";
import moment from "moment";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled } from "@mui/material/styles";
import {
  Card,
  Grid,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  tableCellClasses,
  IconButton,
  CardContent,
  Tooltip,
  Fade,
  Button,
  Paper,
  InputBase,
  Divider,
  Menu,
  MenuItem,
} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import {
  CalendarMonth,
  EditNote,
  Assignment,
  Search,
  FileDownload,
  QueuePlayNext,
} from "@mui/icons-material";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import SnackBarMessage from "../../components/SnackBarMessage";
import AddNewReception from "../offcanvas/AddNewReception";
import {
  getFoundationsData,
  getReceptionData,
  searchReception,
  updateStatus,
} from "../repositories/ReceptionRepository";
import { debounce } from "lodash";
import RECEPTIONSTATUS, {
  getStatusDataWithName,
} from "../../assets/helpers/dummyReceptionStatus";
import theme from "../../assets/theme";
import { useNavigate } from "react-router-dom";
import AddNewGeneralData from "../offcanvas/AddNewGeneralData";
require("moment/locale/es.js");

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#EC7BA1",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  " &:first-child tr, &:first-child": {
    borderTopLeftRadius: "50px",
  },
  " &:first-child tr, &:last-child": {
    borderTopRightRadius: "50px",
  },
  " &:last-child tr, &:first-child": {
    borderBottomLeftRadius: "50px",
  },
  " &:last-child tr, &:last-child": {
    borderBottomRightRadius: "50px",
  },
}));

const localizer = momentLocalizer(moment);

const MyEvent = (data) => {
  const { event } = data;
  const colorEvent = getStatusDataWithName(event.receptionData.status).color;

  return (
    <div
      style={{
        display: "flex",
        borderRadius: "5px",
        backgroundColor: colorEvent,
      }}
    >
      <div
        style={{
          textAlign: "center",
          borderRadius: "5px",
          paddingLeft: "4px",
          paddingRight: "4px",
        }}
      >
        <div
          style={{ fontSize: "12px", fontWeight: "400", marginBottom: "0px" }}
        >
          {event.receptionData.patientData.name +
            " " +
            event.receptionData.patientData.lastname}
        </div>
      </div>
    </div>
  );
};

const CommonReceprtionEvents = () => {
  const [dummyRows, setDummyRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [snackOpen, setSnackOpen] = useState(false);
  const [date, setDate] = useState(new Date());
  const [eventsOnCalendar, setEventsOnCalendar] = useState([]);
  const now = new Date();
  const [slotSelected, setSlotSelected] = useState(null);
  const [openPatientCanvas, setOpenPatientCanvas] = useState(false);
  const currentDateToStyle = new Date();
  currentDateToStyle.setHours(0, 0, 0, 0);
  const [viewExpedient, setViewExpedient] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [snackSeverity, setSnackSeverity] = useState("success");
  const [snackMessage, setSnackMessage] = useState("");
  const [isLgBreakpoint, setIsLgBreakpoint] = useState(false);
  const [openGeneralDataPanel, setOpenGeneralDataPanel] = useState(false);
  const [receptionSelected, setReceptionSelected] = useState(null);
  const [foundationDataList, setFoundationDataList] = useState([]);
  const navigate = useNavigate();

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dummyRows.length) : 0;
  const calendarViews = [Views.MONTH, Views.WEEK, Views.DAY];

  const ColoredDateCellWrapper = ({ children, value }) =>
    React.cloneElement(Children.only(children), {
      style: {
        ...children.style,
        backgroundColor: value < currentDateToStyle ? "#E6E6E666" : "",
      },
    });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getData(date);
    getFoundations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async (selectedDate) => {
    const monthOne = selectedDate.getMonth() + 1;
    const yearOne = selectedDate.getFullYear();

    const response = await getReceptionData(
      monthOne < 10 ? `0${monthOne}` : monthOne.toString(),
      yearOne.toString()
    );

    if(response){
      if(response.status === "401"){
        navigate(`../error401`);
      }
    }
    const calendarList = [];

    for (let q = 0; q < response.content.length; q += 1) {
      const itemQ = response.content[q];

      calendarList.push({
        id: itemQ.id,
        start: moment(
          new Date(
            Number(itemQ.receptionData.studyDateSeparate.year),
            Number(itemQ.receptionData.studyDateSeparate.month),
            Number(itemQ.receptionData.studyDateSeparate.day),
            Number(itemQ.receptionData.studyDateSeparate.hour),
            Number(itemQ.receptionData.studyDateSeparate.minutes)
          )
        )._d,
        end: moment(
          new Date(
            Number(itemQ.receptionData.studyDateSeparate.year),
            Number(itemQ.receptionData.studyDateSeparate.month),
            Number(itemQ.receptionData.studyDateSeparate.day),
            Number(itemQ.receptionData.studyDateSeparate.hour),
            Number(itemQ.receptionData.studyDateSeparate.minutes)
          )
        )._d,
        receptionData: itemQ.receptionData,
      });
    }

    setEventsOnCalendar(calendarList);
    setDummyRows(calendarList);
  };

  // Get fundaciones
  const getFoundations = async () => {
    const response = await getFoundationsData();

    if (response) {
      if (response.status === "Ok") {
        setFoundationDataList(response.content);
      } else {
        setSnackSeverity("warning");
        setSnackMessage(response.message);
        setSnackOpen(true);
      }
    }
  };

  // Nueva cita
  const handleSelect = ({ start }) => {
    setViewExpedient(false);
    const isPastEvent = start < currentDateToStyle;

    if (!isPastEvent) {
      setSlotSelected({ start });
      handleClickOpen();
    } else {
    }
  };

  // Cita seleccionada
  const onSelectEvent = (event) => {
    setSlotSelected(event);
    handleClickOpen();
  };

  const handleViewMode = (e) => {
    setDate(moment(e)._d);
  };

  const dayStyleGetter = (event) => {
    const isPastEvent = event < now;

    var style = {
      backgroundColor: isPastEvent ? "#0000000d" : "",
      border: "0px",
    };

    return {
      style: style,
    };
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    var style = {
      backgroundColor: "transparent",
      color: "white",
      border: "0px",
      borderRadius: "5px",
    };

    return {
      style: style,
    };
  };

  const handleClickOpen = () => {
    setOpenPatientCanvas(true);
  };

  const onlyClose = () => {
    setOpenPatientCanvas(false);
  };

  const closeGeneralDataPanel = () => {
    setOpenGeneralDataPanel(false);
  };

  const returnNewDate = (value) => {
    const list = [];
    let isEdit = false;

    for (let i = 0; i < eventsOnCalendar.length; i += 1) {
      const item = eventsOnCalendar[i];

      if (item.receptionData._id !== value.receptionData._id) {
        list.push(item);
      } else {
        const formatUpdatedEvent = {
          id: value.id,
          start: moment(
            new Date(
              Number(value.receptionData.studyDateSeparate.year),
              Number(value.receptionData.studyDateSeparate.month),
              Number(value.receptionData.studyDateSeparate.day),
              Number(value.receptionData.studyDateSeparate.hour),
              Number(value.receptionData.studyDateSeparate.minutes)
            )
          )._d,
          end: moment(
            new Date(
              Number(value.receptionData.studyDateSeparate.year),
              Number(value.receptionData.studyDateSeparate.month),
              Number(value.receptionData.studyDateSeparate.day),
              Number(value.receptionData.studyDateSeparate.hour),
              Number(value.receptionData.studyDateSeparate.minutes)
            )
          )._d,
          receptionData: value.receptionData,
        };

        list.push(formatUpdatedEvent);
        isEdit = true;
      }
    }

    if (!isEdit) {
      const formatNewEvent = {
        id: eventsOnCalendar.length + 1,
        start: moment(
          new Date(
            Number(value.receptionData.studyDateSeparate.year),
            Number(value.receptionData.studyDateSeparate.month),
            Number(value.receptionData.studyDateSeparate.day),
            Number(value.receptionData.studyDateSeparate.hour),
            Number(value.receptionData.studyDateSeparate.minutes)
          )
        )._d,
        end: moment(
          new Date(
            Number(value.receptionData.studyDateSeparate.year),
            Number(value.receptionData.studyDateSeparate.month),
            Number(value.receptionData.studyDateSeparate.day),
            Number(value.receptionData.studyDateSeparate.hour),
            Number(value.receptionData.studyDateSeparate.minutes)
          )
        )._d,
        receptionData: value.receptionData,
      };

      var lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

      if (formatNewEvent.start < lastDayOfMonth) {
        list.push(formatNewEvent);
      }
    }

    setEventsOnCalendar(list);
    setDummyRows(list);

    setSnackSeverity("success");
    setSnackMessage("Se guardó correctamente");
    setSnackOpen(true);
  };

  const updateData = (value) => {
    setDate(value);
    if (!isSearch) {
      getData(value);
    }
  };

  const updateOnlyStatus = async (value) => {
    handleClose();
    const response = await updateStatus(value.status, value.receptionId);

    if (response) {
      if (response.status === "Ok") {
        response.content.id = value.id;
        updateListFromOnlyStatus(response.content);
      } else {
        setSnackSeverity("warning");
        setSnackMessage(response.message);
        setSnackOpen(true);
      }
    }
  };

  const updateListFromOnlyStatus = (value) => {
    for (let i = 0; i < eventsOnCalendar.length; i += 1) {
      const item = eventsOnCalendar[i];

      if (item.receptionData._id === value._id) {
        item.receptionData.status = value.status;

        eventsOnCalendar[i] = item;
        setEventsOnCalendar(eventsOnCalendar);
        setDummyRows(eventsOnCalendar);
        i = eventsOnCalendar.length;

        setSnackSeverity("success");
        setSnackMessage("Se guardó correctamente");
        setSnackOpen(true);
      }
    }
  };

  const formatDate = (value) => {
    const day = value.day < 10 ? "0" + value.day : value.day;
    const minutes = value.minutes === 0 ? "0" + value.minutes : value.minutes;
    let month = Number(value.month + 1);

    if (month < 10) {
      month = "0" + month;
    }

    return day + "-" + month + " / " + value.hour + ":" + minutes;
  };

  const delayedHandleSearch = debounce(async (search) => {
    if (search !== "") {
      setIsSearch(true);
      const response = await searchReception(search);
      const calendarList = [];

      for (let q = 0; q < response.content.length; q += 1) {
        const itemQ = response.content[q];

        calendarList.push({
          id: itemQ.id,
          start: moment(
            new Date(
              Number(itemQ.receptionData.studyDateSeparate.year),
              Number(itemQ.receptionData.studyDateSeparate.month),
              Number(itemQ.receptionData.studyDateSeparate.day),
              Number(itemQ.receptionData.studyDateSeparate.hour),
              Number(itemQ.receptionData.studyDateSeparate.minutes)
            )
          )._d,
          end: moment(
            new Date(
              Number(itemQ.receptionData.studyDateSeparate.year),
              Number(itemQ.receptionData.studyDateSeparate.month),
              Number(itemQ.receptionData.studyDateSeparate.day),
              Number(itemQ.receptionData.studyDateSeparate.hour),
              Number(itemQ.receptionData.studyDateSeparate.minutes)
            )
          )._d,
          receptionData: itemQ.receptionData,
        });
      }

      setEventsOnCalendar(calendarList);
      setDummyRows(calendarList);
    } else {
      setIsSearch(false);
      getData(date);
    }
  }, 800);

  const [anchorEl, setAnchorEl] = useState(null);
  const [rowId, setRowId] = useState();
  const open = Boolean(anchorEl);

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setRowId(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setRowId(null);
  };

  function GetLgBreakpoint() {
    const theme = useTheme();
    setIsLgBreakpoint(useMediaQuery(theme.breakpoints.up("xl")));

    return null;
  }

  const onCloseSuccess = (value) => {
    for (let i = 0; i < eventsOnCalendar.length; i += 1) {
      const item = eventsOnCalendar[i];

      if (item.receptionData.patientData._id === value._id) {
        item.receptionData.status = "Llegó";
        item.receptionData.patientData = value;
        eventsOnCalendar[i] = item;
        setEventsOnCalendar(eventsOnCalendar);
        setDummyRows(eventsOnCalendar);
        i = eventsOnCalendar.length;
      }
    }

    setSnackSeverity("success");
    setSnackMessage("Se guardó correctamente");
    setSnackOpen(true);
  };

  return (
    <div>
      <GetLgBreakpoint />
      <div id="div-style-8">
        <CalendarMonth color={"primary"} fontSize="large" id="icon-style-1" />

        <h2>Recepción</h2>
      </div>
      <Card id="card-style-2">
        <CardContent>
          <Grid container spacing={2} id="grid-style-2">
            <Grid xl={6} lg={8} md={10} sm={12} xs={12}>
              <Paper component="form" id="paper-style-2">
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Buscar..."
                  inputProps={{ "aria-label": "search google maps" }}
                  onChange={(event) => {
                    delayedHandleSearch(event.target.value.toLowerCase());
                  }}
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                />
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <IconButton type="button" id="icon-style-2" aria-label="search">
                  <Search color="primary" />
                </IconButton>
              </Paper>
            </Grid>
            <Grid xl={6} lg={4} md={2} sm={12} xs={12} id="grid-style-3">
              <Button component="label" variant="outlined" id="button-style-3">
                <FileDownload /> Descargar
              </Button>
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{ marginTop: "5px" }}>
            <Grid
              xl={7}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{ minHeight: "65vh", height: "65vh" }}
            >
              <Calendar
                selectable
                localizer={localizer}
                events={eventsOnCalendar}
                views={calendarViews}
                defaultView={Views.MONTH}
                startAccessor="start"
                endAccessor="end"
                onNavigate={(_date) => {
                  setPage(0);
                  updateData(_date);
                }}
                scrollToTime={new Date(2023, 1, 1)}
                defaultDate={new Date()}
                popup={false}
                onSelectEvent={(_event) => {
                  onSelectEvent(_event);
                  const isPastEvent = _event.start < currentDateToStyle;

                  if (isPastEvent) {
                    setViewExpedient(true);
                  } else {
                    setViewExpedient(false);
                  }
                }}
                onSelectSlot={handleSelect}
                onView={handleViewMode}
                onDrillDown={handleViewMode}
                components={{
                  event: MyEvent,
                  dateCellWrapper: ColoredDateCellWrapper,
                }}
                dayPropGetter={dayStyleGetter}
                eventPropGetter={eventStyleGetter}
                style={{ marginLeft: "15px", marginRight: "15px" }}
                messages={{
                  next: "Sig.",
                  previous: "Ant.",
                  today: "Hoy",
                  month: "Mes",
                  week: "Semana",
                  day: "Día",
                  more: "Más",
                  showMore: function showMore(total) {
                    return "+" + total + " más";
                  },
                }}
              />
            </Grid>

            <Grid
              xl={5}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{
                marginTop: "15px",
                marginLeft: isLgBreakpoint ? null : "15px",
              }}
            >
              <TableContainer id="table-container-style-1">
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">No.</StyledTableCell>
                      <StyledTableCell>Nombre</StyledTableCell>
                      <StyledTableCell align="center">
                        Fecha y hora
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Estatus de cita
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Estatus del paciente
                      </StyledTableCell>
                      <StyledTableCell align="center">Acciones</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dummyRows &&
                      dummyRows
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((itemReception) => {
                          return (
                            <StyledTableRow key={itemReception.id}>
                              <StyledTableCell align="center">
                                {itemReception.id}
                              </StyledTableCell>
                              <StyledTableCell>
                                {`${itemReception.receptionData.patientData.name} ${itemReception.receptionData.patientData.lastname}`}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatDate(
                                  itemReception.receptionData.studyDateSeparate
                                )}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <div>
                                  <Button
                                    id="basic-button"
                                    style={{
                                      textTransform: "none",
                                      color: getStatusDataWithName(
                                        itemReception.receptionData.status
                                      ).color,
                                    }}
                                    aria-controls={
                                      open ? "basic-menu" : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={open ? "true" : undefined}
                                    onClick={(event) => {
                                      handleClick(event, itemReception.id);
                                    }}
                                  >
                                    {itemReception.receptionData.status}
                                  </Button>
                                  <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open && rowId === itemReception.id}
                                    onClose={handleClose}
                                    MenuListProps={{
                                      "aria-labelledby": "basic-button",
                                    }}
                                  >
                                    {Object.keys(RECEPTIONSTATUS).map((s) => (
                                      <MenuItem
                                        value={RECEPTIONSTATUS[s].name}
                                        style={{
                                          fontSize: "14px",
                                        }}
                                        onClick={() => {
                                          updateOnlyStatus({
                                            status: RECEPTIONSTATUS[s].name,
                                            receptionId:
                                              itemReception.receptionData._id,
                                            id: itemReception.id,
                                          });
                                        }}
                                      >
                                        {RECEPTIONSTATUS[s].name}
                                      </MenuItem>
                                    ))}
                                  </Menu>
                                </div>
                              </StyledTableCell>
                              <StyledTableCell>
                                <div
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  {itemReception.receptionData.patientData
                                    .birthday
                                    ? "Registrada"
                                    : "Sin registrar"}
                                </div>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Tooltip
                                  title="Ver expediente"
                                  TransitionComponent={Fade}
                                  TransitionProps={{ timeout: 600 }}
                                  arrow
                                >
                                  <IconButton
                                    aria-label="view"
                                    size="small"
                                    color="primary"
                                    onClick={() => {
                                      onSelectEvent(itemReception);
                                      setViewExpedient(true);
                                    }}
                                  >
                                    <Assignment fontSize="medium" />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip
                                  title="Editar expediente"
                                  TransitionComponent={Fade}
                                  TransitionProps={{ timeout: 600 }}
                                  arrow
                                >
                                  <IconButton
                                    aria-label="edit"
                                    size="small"
                                    color="secondary"
                                    onClick={() => {
                                      onSelectEvent(itemReception);
                                      const isPastEvent =
                                        itemReception.start < currentDateToStyle;

                                      if (isPastEvent) {
                                        setViewExpedient(true);
                                      } else {
                                        setViewExpedient(false);
                                      }
                                    }}
                                  >
                                    <EditNote fontSize="medium" />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip
                                  title="Llenar registro"
                                  TransitionComponent={Fade}
                                  TransitionProps={{ timeout: 600 }}
                                  arrow
                                >
                                  <IconButton
                                    aria-label="edit"
                                    size="small"
                                    onClick={() => {
                                      setReceptionSelected(
                                        itemReception.receptionData
                                      );
                                      setOpenGeneralDataPanel(true);
                                    }}
                                    style={{ color: theme.palette.purple.one }}
                                  >
                                    <QueuePlayNext fontSize="medium" />
                                  </IconButton>
                                </Tooltip>
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                rowsPerPage={rowsPerPage}
                page={page}
                count={dummyRows.length}
                component="div"
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={"Resultados por página:"}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} de ${count}`
                }
                id="table-pagination-style-9"
              />

              <SnackBarMessage
                severity={snackSeverity}
                message={snackMessage}
                open={snackOpen}
                onClose={() => {
                  setSnackOpen(false);
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <AddNewReception
        openCanvas={openPatientCanvas}
        onlyClose={onlyClose}
        itemDate={slotSelected}
        returnNewDate={returnNewDate}
        viewExpedient={viewExpedient}
        foundationList={foundationDataList}
      />

      <AddNewGeneralData
        openCanvas={openGeneralDataPanel}
        onClose={closeGeneralDataPanel}
        receptionItem={receptionSelected}
        onCloseSuccess={onCloseSuccess}
      />
    </div>
  );
};

export default CommonReceprtionEvents;
