import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  TextField,
  MenuItem,
  Avatar,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import SnackBarMessage from "../../components/SnackBarMessage";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PersonIcon from "@mui/icons-material/Person";
import ImagesDefault from "../../assets/imagesDefault";
import {
  genderData,
  branchOffice,
  occupation,
} from "../../assets/helpers/selectData";
import { getPermissions } from "../repositories/PermissionRepository";
import { createUser, updateUser } from "../../pages/repositories/UsersRepository";

const UserForm = ({ handleClose, isEditing, itemUser, onCloseSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [permissionsList, setPermissionsList] = useState([]);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackSeverity, setSnackSeverity] = useState("success");
  const [snackMessage, setSnackMessage] = useState("");
  const [buttonTitle, setButtonTitle] = useState("Guardar");
  const [avatarImage, setAvatarImage] = useState(null);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      lastname: "",
      phone: "",
      email: "",
      birthday: "",
      gender: "",
      branchOffice: "",
      occupation: "",
      rol: "",
      rolId: "",
      password: "",
      image: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Campo obligatorio"),
      lastname: Yup.string().required("Campo obligatorio"),
      email: Yup.string()
        .email("Formato incorrecto")
        .required("Campo obligatorio"),
      phone: Yup.string()
        .required("Campo obligatorio")
        .min(10, "10 caracteres")
        .max(10, "10 caracteres"),
    }),
    onSubmit: async () => {
      setLoading(true);
      let actionResponse = null;

      if (isEditing === true) {
        actionResponse = await updateNewUser();
      } else {
        actionResponse = await createNewUser();
      }

      if (actionResponse.data.message === "Ok") {
        onCloseSuccess();
      } else {
        setSnackSeverity("warning");
        setSnackMessage(actionResponse.data.message);
        setSnackOpen(true);
      }
      setLoading(false);
      handleClose();
    },
  });

  const getRolList = async () => {
    const getPermissionslist = await getPermissions();
    setPermissionsList(getPermissionslist.content);
  };

  const createNewUser = async () => {
    let userObject = {
      name: formik.values.name,
      lastname: formik.values.lastname,
      phone: formik.values.phone,
      email: formik.values.email,
      birthday: formik.values.birthday,
      gender: formik.values.gender,
      branchOffice: formik.values.branchOffice,
      occupation: formik.values.occupation,
      rol: formik.values.rol,
      rolId: formik.values.rolId,
      password: formik.values.password,
      image: formik.values.image,
    };

    const userResponse = await createUser(userObject);

    return userResponse;
  };

  const updateNewUser = async () => {
    let userObject = {
      name: formik.values.name,
      lastname: formik.values.lastname,
      phone: formik.values.phone,
      email: formik.values.email,
      birthday: formik.values.birthday,
      gender: formik.values.gender,
      branchOffice: formik.values.branchOffice,
      occupation: formik.values.occupation,
      rol: formik.values.rol,
      rolId: formik.values.rolId,
      password: formik.values.password ?? "",
      image: formik.values.image,
    };

    const userResponse = await updateUser(userObject, itemUser._id);

    return userResponse;
  };

  useEffect(() => {
    getRolList();
    formik.setValues({});
    formik.resetForm();
    setLoading(false);
    setAvatarImage(null);

    if (isEditing === true) {
      formik.setValues({
        name: itemUser.name,
        lastname: itemUser.lastname,
        phone: itemUser.phone,
        email: itemUser.email,
        birthday: itemUser.birthday,
        gender: itemUser.gender,
        branchOffice: itemUser.branchOffice,
        occupation: itemUser.occupation,
        rol: itemUser.rol,
        rolId: itemUser.rolId,
        image: itemUser.image.url,
      });
      setAvatarImage(itemUser.image.url);
      setButtonTitle("Actualizar");
    } else {
      setButtonTitle("Guardar");
    }

    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemUser]);

  return (
    <div
      id="div-scrolling-style-result"
      style={{
        overflowY: "auto",
        height: "74vh",
        paddingBottom: "100px",
        marginLeft: "30px",
        marginRight: "30px",
        marginTop: "27px",
        paddingRight: "8px",
      }}
    >
      <div style={{ display: "flex" }}>
        <PersonIcon color="primary" id="icon-style-user-subtitle" />
        <h4 id="h4-style-users-subtitle">Nuevo usuario</h4>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={1} style={{ marginTop: "15px" }}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <div
              style={{
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
                marginBottom: "15px",
              }}
            >
              <Avatar
                alt="Remy Sharp"
                src={
                  avatarImage !== null ? avatarImage : ImagesDefault.DogAvatar
                }
                sx={{ width: 120, height: 120 }}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
                marginBottom: "15px",
              }}
            >
              <input type="file" id="inputImage" style={{ display: "none" }} />

              <Button
                id="button-style-image-user-form"
                sise="small"
                variant="contained"
                fullWidth
                onClick={() => {
                  document.getElementById("inputImage").click();
                  document.getElementById("inputImage").onchange = (e) => {
                    formik.setFieldValue(
                      "image",
                      document.getElementById("inputImage").files[0]
                    );
                    setAvatarImage(
                      URL.createObjectURL(
                        document.getElementById("inputImage").files[0]
                      )
                    );
                  };
                }}
              >
                <CloudUploadIcon
                  style={{ marginRight: "5px", paddingBottom: "3px" }}
                />
                Cargar imagen
              </Button>
            </div>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <InputLabel shrink>Nombre</InputLabel>
            <TextField
              id="name"
              size="small"
              onChange={formik.handleChange}
              value={formik.values.name}
              onBlur={formik.handleBlur}
              autoComplete="off"
            />
            {Boolean(formik.errors.name && formik.touched.name) && (
              <div id="div-style-input-error">{formik.errors.name}</div>
            )}
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <InputLabel shrink>Apellido</InputLabel>
            <TextField
              id="lastname"
              size="small"
              onChange={(e) => {
                formik.setFieldValue("lastname", e.target.value);
                formik.values.lastname = e.target.value;
              }}
              value={formik.values.lastname}
              onBlur={formik.handleBlur}
              autoComplete="off"
            />
            {Boolean(formik.errors.lastname && formik.touched.lastname) && (
              <div id="div-style-input-error">{formik.errors.lastname}</div>
            )}
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <InputLabel shrink>Teléfono</InputLabel>
            <TextField
              id="phone"
              size="small"
              onChange={(e) => {
                formik.setFieldValue("phone", e.target.value);
                formik.values.phone = e.target.value;
              }}
              value={formik.values.phone}
              onBlur={formik.handleBlur}
              autoComplete="off"
            />
            {Boolean(formik.errors.phone && formik.touched.phone) && (
              <div id="div-style-input-error">{formik.errors.phone}</div>
            )}
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <InputLabel shrink>Correo electrónico</InputLabel>
            <TextField
              id="email"
              size="small"
              value={formik.values.email}
              onChange={(e) => {
                formik.setFieldValue("email", e.target.value);
                formik.values.email = e.target.value;
              }}
              onBlur={formik.handleBlur}
              autoComplete="off"
            />
            {Boolean(formik.errors.email && formik.touched.email) && (
              <div id="div-style-input-error">{formik.errors.email}</div>
            )}
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <InputLabel shrink>Fecha de nacimiento</InputLabel>
            <TextField
              id="birthday"
              size="small"
              type="date"
              onChange={(e) => {
                formik.setFieldValue("birthday", e.target.value);
                formik.values.birthday = e.target.value;
              }}
              value={formik.values.birthday}
              onBlur={formik.handleBlur}
            />
            {Boolean(formik.errors.birthday && formik.touched.birthday) && (
              <div id="div-style-input-error">{formik.errors.birthday}</div>
            )}
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <InputLabel shrink>Género</InputLabel>
            <TextField
              id="gender"
              size="small"
              select
              value={formik.values.gender}
              onBlur={formik.handleBlur}
              onChange={(e) => {
                formik.setFieldValue("gender", e.target.value);
                formik.values.gender = e.target.value;
              }}
            >
              {genderData.map((genders) => (
                <MenuItem key={genders.id} value={genders.gender}>
                  {genders.gender}
                </MenuItem>
              ))}
            </TextField>
            {Boolean(formik.errors.gender && formik.touched.gender) && (
              <div id="div-style-input-error">{formik.errors.gender}</div>
            )}
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <InputLabel shrink>Centro de trabajo</InputLabel>
            <TextField
              id="branchOffice"
              size="small"
              select
              value={formik.values.branchOffice}
              onBlur={formik.handleBlur}
              onChange={(e) => {
                formik.setFieldValue("branchOffice", e.target.value);
                formik.values.branchOffice = e.target.value;
              }}
            >
              {branchOffice.map((office) => (
                <MenuItem key={office.id} value={office.officename}>
                  {office.officename}
                </MenuItem>
              ))}
            </TextField>
            {Boolean(
              formik.errors.branchOffice && formik.touched.branchOffice
            ) && (
              <div id="div-style-input-error">{formik.errors.branchOffice}</div>
            )}
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <InputLabel shrink>Cargo</InputLabel>
            <TextField
              id="occupation"
              size="small"
              select
              value={formik.values.occupation}
              onBlur={formik.handleBlur}
              onChange={(e) => {
                formik.setFieldValue("occupation", e.target.value);
                formik.values.occupation = e.target.value;
              }}
            >
              {occupation.map((job) => (
                <MenuItem key={job.id} value={job.officename}>
                  {job.officename}
                </MenuItem>
              ))}
            </TextField>
            {Boolean(formik.errors.occupation && formik.touched.occupation) && (
              <div id="div-style-input-error">{formik.errors.occupation}</div>
            )}
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <InputLabel shrink>Rol de usuario:</InputLabel>
            <TextField
              select
              id="rol"
              size="small"
              onChange={(e) => {
                const value = e.target.value;
                for (let i = 0; i < permissionsList.length; i += 1) {
                  const rolItem = permissionsList[i];

                  if (rolItem.rolname === value) {
                    formik.setFieldValue("rolId", rolItem._id);
                    formik.setFieldValue("rol", value);

                    i = permissionsList.length;
                  }
                }
              }}
              value={formik.values.rol}
              onBlur={formik.handleBlur}
            >
              {permissionsList.map((rol) => (
                <MenuItem key={rol.id} value={rol.rolname}>
                  {rol.rolname}
                </MenuItem>
              ))}
            </TextField>
            {Boolean(formik.errors.rol && formik.touched.rol) && (
              <div id="div-style-input-error">{formik.errors.rol}</div>
            )}
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <InputLabel shrink>Contraseña</InputLabel>
            <TextField
              id="password"
              size="small"
              onChange={(e) => {
                formik.setFieldValue("password", e.target.value);
                formik.values.password = e.target.value;
              }}
              value={formik.values.password}
              onBlur={formik.handleBlur}
              autoComplete="off"
            />
            {Boolean(formik.errors.password && formik.touched.password) && (
              <div id="div-style-input-error">{formik.errors.password}</div>
            )}
          </Grid>
        </Grid>
        <div
          style={{
            marginTop: "20px",
            marginBottom: "24px",
            textAlign: "center",
          }}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <div
              style={{
                paddingLeft: "30%",
                paddingRight: "30%",
              }}
            >
              <Button
                id="button-style-save-reception-form"
                sise="small"
                type="submit"
                variant="contained"
                fullWidth
              >
                {buttonTitle}
              </Button>
            </div>
          )}
        </div>

        <SnackBarMessage
          severity={snackSeverity}
          message={snackMessage}
          open={snackOpen}
          onClose={() => {
            setSnackOpen(false);
          }}
        />
      </form>
    </div>
  );
};

export default UserForm;
