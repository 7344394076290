import theme from "../theme";

const active = {
  name: "Activo",
  color: theme.palette.orange.default,
};
const notYet = {
  name: "No llegó",
  color: theme.palette.black.one,
};
const doing = {
  name: "Llegó",
  color: theme.palette.secondary.main,
};
const canceled = {
  name: "Cancelado",
  color: theme.palette.red.default,
};

const RECEPTIONSTATUS = {
  ACTIVE: active,
  NOTYET: notYet,
  DOING: doing,
  CANCELED: canceled,
};

export function getStatusDataWithName(statusName) {
  return RECEPTIONSTATUS[
    Object.keys(RECEPTIONSTATUS).filter(
      (f) => RECEPTIONSTATUS[f].name.toString() === statusName
    )
  ];
}

export default RECEPTIONSTATUS;
